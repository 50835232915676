import {
  LocationPostRequestOwnerResourceTypeEnum,
  ProjectPostRequestActivityCreationStrategyEnum
} from "../../.generated/api";
import {
  OfferDetails,
  SalesOpportunity,
  SalesOpportunityApi,
  SalesOpportunityPostRequest,
  SalesOpportunityPriorityEnum,
  SalesOpportunityPutRequest,
  SalesOpportunityStatusEnum
} from "../../.generated/customerModule";
import environment from "../../environment";
import { createLocation } from "../api/LocationApi";
import { addProject } from "../api/ProjectApi";
import { customerInstance } from "./CustomerHttpBase";
import { createCustomerRelation } from "./CustomerRelationApiV3";
import { createQuery } from "react-query-kit";
import { updateProjectEconomy } from "../api/ProjectEconomyApi";

export type QueryOptions = {
  enabled?: boolean;
  keepPreviousData?: boolean;
  refetchOnWindowFocus?: boolean;
};

export type SalesOpportunitySortFields =
  | "Title"
  | "Status"
  | "ExpectedCloseDate"
  | "EstimatedValue"
  | "Priority"
  | "Owner"
  | "Customer"
  | "Price"
  | "DeliveryType";

const salesOpportunityApi = new SalesOpportunityApi(
  undefined,
  environment.customerBaseUrl,
  customerInstance
);

export type SortDirection = "ASC" | "DESC";
export type SalesStatusFilter =
  | "IdentifiedSale"
  | "InProgress"
  | "OfferSent"
  | "Won"
  | "Lost";

export const useGetSalesOpportunitiesByCompanyId = createQuery({
  queryKey: ["getSalesOpportunitiesByCompanyId"],
  fetcher: async (variables: {
    companyId: string;
    page: number;
    sortDirection: SortDirection;
    sortField: SalesOpportunitySortFields;
    archived: boolean;
    query?: string;
    statusFilter?: SalesStatusFilter[];
  }) => {
    const response =
      await salesOpportunityApi.getPaginatedSalesOpportunityByCompanyId(
        variables.companyId,
        variables.sortField,
        variables.page,
        variables.sortDirection,
        variables.query,
        variables.archived,
        variables.statusFilter
      );
    return response.data;
  }
});

export async function getSalesOpportunitiesByCompanyId(companyId: string) {
  const { data } =
    await salesOpportunityApi.getSalesOpportunityByCompanyId(companyId);

  return data;
}

export async function getPaginatedSalesOpportunities(
  companyId: string,
  page: number,
  sortDirection: "ASC" | "DESC",
  sortField: SalesOpportunitySortFields,
  query?: string,
  archived?: boolean,
  statusFilter?: (
    | "IdentifiedSale"
    | "InProgress"
    | "OfferSent"
    | "Won"
    | "Lost"
  )[]
) {
  const { data } =
    await salesOpportunityApi.getPaginatedSalesOpportunityByCompanyId(
      companyId,
      sortField,
      page,
      sortDirection,
      query,
      archived,
      statusFilter
    );

  return data;
}

export const useGetSalesOpportunityById = createQuery({
  queryKey: ["getSalesOpportunityById"],
  fetcher: async (variables: {
    companyId: string;
    salesOpportunityId: string;
  }) => {
    const response =
      await salesOpportunityApi.getSalesOpportunityByCompanyIdAndId(
        variables.companyId,
        variables.salesOpportunityId
      );

    return response.data;
  }
});

export const useGetSalesOpportunityByCustomerId = createQuery({
  queryKey: ["getSalesOpportunityByCustomerId"],
  fetcher: async (variables: { companyId: string; customerId: string }) => {
    const { data } = await salesOpportunityApi.getSalesOpportunityByCustomerId(
      variables.companyId,
      variables.customerId
    );

    return data;
  }
});

export async function getSalesOpportunityByCompanyIdAndId(
  companyId: string,
  id: string
) {
  const { data } =
    await salesOpportunityApi.getSalesOpportunityByCompanyIdAndId(
      companyId,
      id
    );

  return data;
}

export async function createSalesOpportunity(
  companyId: string,
  data: SalesOpportunityPostRequest
) {
  await salesOpportunityApi.createSalesOpportunity(companyId, data);
}

export async function updateSalesOpportunity(
  companyId: string,
  id: string,
  data: SalesOpportunityPutRequest
) {
  await salesOpportunityApi.updateSalesOpportunity(companyId, id, data);
}

export async function convertSalesOpportunityToProject(
  companyId: string,
  salesOpportunity: SalesOpportunity,
  projectSeriesId?: string,
  offer?: OfferDetails
) {
  const { title, description, startDate, endDate, address, customer, id } =
    salesOpportunity;

  const projectId = await addProject({
    title,
    salesOpportunityId: id,
    activityCreationStrategy:
      ProjectPostRequestActivityCreationStrategyEnum.Hours,
    companyId,
    description,
    toDateTime: endDate,
    fromDateTime: startDate,
    contractValue: undefined,
    estimatedHours: undefined,
    projectSeriesId: projectSeriesId
  });

  if (offer) {
    await updateProjectEconomy(projectId, {
      estimatedRevenue: offer.totalPrice,
      estimatedSubcontractorCost: offer.totalSubcontractorCost,
      estimatedHours: offer.estimatedOfferHours,
      isHourly: offer.priceType === "hourly",
      estimatedHourCost: offer.customerHourlyRate,
      estimatedMaterialCost: offer.totalMaterialCost,
      materialSurcharge: offer.materialsSurcharge,
      subcontractorSurcharge: offer.subcontractorSurcharge,
      sendHourNotification: false
    });
  } else {
    await updateProjectEconomy(projectId, {
      estimatedRevenue: salesOpportunity.price,
      estimatedHours: salesOpportunity.estimatedHours,
      isHourly: salesOpportunity.priceType === "hourly",
      sendHourNotification: false
    });
  }

  if (address)
    await createLocation({
      streetAddress: address,
      ownerResourceId: projectId,
      ownerResourceType: LocationPostRequestOwnerResourceTypeEnum.Project
    });

  await createCustomerRelation(companyId, customer.id, projectId, "Project");

  await salesOpportunityApi.archiveSalesOpportunity(companyId, id);

  return projectId;
}

export async function archiveSalesOppertunity(
  companyId: string,
  id: string,
  markAsWon?: boolean
) {
  await salesOpportunityApi.archiveSalesOpportunity(companyId, id, markAsWon);
}

export function convertStatus(status?: SalesOpportunityStatusEnum) {
  switch (status) {
    case "InProgress":
      return "inProgress";
    case "IdentifiedSale":
      return "identifiedSale";
    case "OfferSent":
      return "offerSent";
    case "Lost":
      return "lost";
    case "Won":
      return "won";
    default:
      return "-";
  }
}

export function convertPriority(priority?: SalesOpportunityPriorityEnum) {
  switch (priority) {
    case "High":
      return "high";
    case "Low":
      return "low";
    case "Medium":
      return "medium";
    default:
      return "-";
  }
}
