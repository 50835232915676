import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { internalRoutinesAndEhsTabs } from "../../../../router/Routes";

const ehsSchema = z.object({
  tab: internalRoutinesAndEhsTabs.optional().catch("internalRoutines")
});

export const Route = createFileRoute("/_protected/dashboard/ehs/")({
  validateSearch: ehsSchema
});
