import {
  ProjectEconomyApi,
  ProjectEconomyPutRequest
} from "../../.generated/api";
import environment from "../../environment";
import { apiInstance } from "./ApiHttpBase";
import { createQuery } from "react-query-kit";

const projectEconomyApi = new ProjectEconomyApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export const useGetProjectEconomy = createQuery({
  queryKey: ["getProjectEconomy"],
  fetcher: async (variables: { projectId: string }) => {
    const response = await projectEconomyApi.getProjectEconomy(
      variables.projectId
    );
    return response.data;
  }
});

export const useGetProjectEconomyOverview = createQuery({
  queryKey: ["getProjectEconomyOverview"],
  fetcher: async (variables: { projectId: string }) => {
    const response = await projectEconomyApi.getProjectEconomyOverview(
      variables.projectId
    );
    return response.data;
  }
});

export async function updateProjectEconomy(
  projectId: string,
  data: ProjectEconomyPutRequest
) {
  const response = await projectEconomyApi.updateProjectEconomy(
    projectId,
    data
  );
  return response.data;
}
