import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { recurringOrderTabs } from "../../../../../../router/Routes";
import { useGetOrderTemplateById } from "../../../../../../service/api/RecurringOrderApi";

const orderTemplateSchema = z.object({
  showDialog: z.boolean().optional().default(false),
  customerId: z.string().optional(),
  tab: recurringOrderTabs.optional().catch("overview")
});

export const Route = createFileRoute(
  "/_protected/dashboard/orders/template/$templateId/"
)({
  validateSearch: orderTemplateSchema,
  loader: ({ context: { queryClient, companyId }, params: { templateId } }) => {
    return queryClient.fetchQuery({
      ...useGetOrderTemplateById.getOptions({ companyId, templateId })
    });
  }
});
