import {
  EquipmentApi,
  EquipmentCategoryPostRequest,
  EquipmentCategoryPutRequest,
  EquipmentExternalCheckoutRequest,
  EquipmentPostRequest,
  EquipmentPutRequest,
  EquipmentStatusEnum
} from "../../.generated/api";
import environment from "../../environment";
import { apiInstance } from "./ApiHttpBase";
import { createQuery } from "react-query-kit";

const equipmentApi = new EquipmentApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export const useGetEquipmentById = createQuery({
  queryKey: ["getEquipmentById"],
  fetcher: async (variables: { companyId: string; equipmentId: string }) => {
    const response = await equipmentApi.getEquipmentById(
      variables.companyId,
      variables.equipmentId
    );
    return response.data;
  }
});

export const useGetEquipmentByCompanyId = createQuery({
  queryKey: ["getEquipmentByCompanyId"],
  fetcher: async (variables: { companyId: string }) => {
    const response = await equipmentApi.getCompanyEquipmentList(
      variables.companyId
    );
    return response.data;
  }
});

export const useGetEquipmentCategoriesByCompanyId = createQuery({
  queryKey: ["getEquipmentCategoriesByCompanyId"],
  fetcher: async (variables: { companyId: string }) => {
    const response = await equipmentApi.getAllEquipmentCategories(
      variables.companyId
    );
    return response.data;
  }
});

export const useGetEquipmentHistory = createQuery({
  queryKey: ["getEquipmentHistory"],
  fetcher: async (variables: { companyId: string; equipmentId: string }) => {
    const response = await equipmentApi.getEquipmentHistoryByEquipmentId(
      variables.companyId,
      variables.equipmentId
    );

    return response.data;
  }
});

export const useGetSearchEquipmentByCompanyId = createQuery({
  queryKey: ["getSearchEquipmentByCompanyId"],
  fetcher: async (variables: {
    companyId: string;
    page?: number;
    sortDirection?: "ASC" | "DESC";
    sortField?: "Name" | "Category" | "Status" | "Location";
    query?: string;
    status?: EquipmentStatusEnum;
    categoryId?: string;
  }) => {
    const response = await equipmentApi.searchCompanyEquipment(
      variables.companyId,
      variables.page,
      variables.sortDirection,
      variables.sortField,
      variables.query,
      variables.status,
      variables.categoryId
    );
    return response.data;
  }
});

export async function getEquipmentHistoryByEquipmentId(
  companyId: string,
  equipmentId: string
) {
  const { data } = await equipmentApi.getEquipmentHistoryByEquipmentId(
    companyId,
    equipmentId
  );

  return data;
}

export async function getEquipmentById(companyId: string, equipmentId: string) {
  const { data } = await equipmentApi.getEquipmentById(companyId, equipmentId);
  return data;
}

export async function createEquipment(
  companyId: string,
  request: EquipmentPostRequest
) {
  const { data } = await equipmentApi.createEquipment(companyId, request);
  return data;
}

export async function updateEquipment(
  companyId: string,
  equipmentId: string,
  request: EquipmentPutRequest
) {
  const { data } = await equipmentApi.updateEquipment(
    companyId,
    equipmentId,
    request
  );
  return data;
}

export async function deleteEquipment(companyId: string, equipmentId: string) {
  const { data } = await equipmentApi.deleteEquipment(companyId, equipmentId);
  return data;
}

export async function createEquipmentCategory(
  companyId: string,
  request: EquipmentCategoryPostRequest
) {
  const { data } = await equipmentApi.createEquipmentCategory(
    companyId,
    request
  );

  return data;
}

export async function updateEquipmentCategory(
  companyId: string,
  equipmentCategoryId: string,
  request: EquipmentCategoryPutRequest
) {
  const { data } = await equipmentApi.updateEquipmentCategory(
    companyId,
    equipmentCategoryId,
    request
  );

  return data;
}

export async function deleteEquipmentCategory(
  companyId: string,
  equipmentCategoryId: string
) {
  const { data } = await equipmentApi.deleteEquipmentCategory(
    companyId,
    equipmentCategoryId
  );

  return data;
}

export async function setEquipmentCategoryChecklistTemplates(
  companyId: string,
  equipmentCategoryId: string,
  checkoutChecklistTemplateId: string,
  checkinChecklistTemplateId: string
) {
  const { data } = await equipmentApi.setEquipmentCategoryChecklistTemplates(
    companyId,
    equipmentCategoryId,
    checkoutChecklistTemplateId,
    checkinChecklistTemplateId
  );

  return data;
}

export async function updateEquipmentStatus(
  companyId: string,
  equipmentId: string,
  status: EquipmentStatusEnum
) {
  const { data } = await equipmentApi.updateEquipmentStatus(
    companyId,
    equipmentId,
    status
  );

  return data;
}

export async function checkoutEquipmentByAdmin(
  companyId: string,
  userId: string,
  equipmentId: string,
  externalCheckout: boolean,
  expectedCheckinDateTime?: string,
  authorization?: string,
  externalCheckoutRequest?: EquipmentExternalCheckoutRequest
) {
  const { data } = await equipmentApi.checkoutEquipmentByAdmin(
    companyId,
    userId,
    equipmentId,
    externalCheckout,
    expectedCheckinDateTime,
    authorization,
    externalCheckoutRequest
  );

  return data;
}

export async function checkinEquipmentByAdmin(
  companyId: string,
  equipmentId: string
) {
  const { data } = await equipmentApi.checkinEquipmentByAdmin(
    companyId,
    equipmentId
  );

  return data;
}
