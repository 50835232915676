import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { ehsResourceType } from "../../../../../router/Routes";
import { useGetEhsById } from "../../../../../service/api/EhsApi";

const upsertEhsSchema = z.object({
  resourceType: ehsResourceType,
  resourceId: z.string()
});

export const Route = createFileRoute("/_protected/dashboard/ehs/$ehsId/update")(
  {
    validateSearch: upsertEhsSchema,
    preloadGcTime: 0,
    preloadStaleTime: 0,
    loader: async ({
      context: { queryClient, companyId },
      params: { ehsId }
    }) => {
      return await queryClient.fetchQuery({
        ...useGetEhsById.getOptions({ companyId, ehsId })
      });
    }
  }
);
