import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { salesTabs } from "../../../../router/Routes";

const salesTabsSchema = z.object({
  tab: salesTabs.catch("salesOpportunities").optional()
});

export const Route = createFileRoute("/_protected/dashboard/sales/")({
  validateSearch: salesTabsSchema
});
