import { createFileRoute, redirect } from "@tanstack/react-router";
import { getCurrentUser } from "../firebase";

export const Route = createFileRoute("/_protected")({
  beforeLoad: async () => {
    const user = await getCurrentUser();
    if (user === null) {
      throw redirect({
        search: {},
        to: "/login"
      });
    }
  }
});
