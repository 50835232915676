import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const createInvoiceSchema = z.object({
  projectId: z.string().optional(),
  orderId: z.string().optional(),
  offerId: z.string().optional(),
  invoiceId: z.string().optional(),
  fromDate: z.string().optional(),
  toDate: z.string().optional()
});

export const Route = createFileRoute("/_protected/dashboard/invoice/new/")({
  validateSearch: createInvoiceSchema
});
