/* tslint:disable */
/* eslint-disable */
/**
 * SkyworkerProducts API
 * API Documentation
 *
 * The version of the OpenAPI document: 1.0
 * Contact: bartegjengen@aboveit.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BundleRequest } from '../model';
// @ts-ignore
import { CreateBundle200Response } from '../model';
// @ts-ignore
import { DeleteInventory200Response } from '../model';
// @ts-ignore
import { GetAllCompanyBundles200Response } from '../model';
// @ts-ignore
import { ResponseDTO } from '../model';
/**
 * BundlesApi - axios parameter creator
 * @export
 */
export const BundlesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new bundle.
         * @summary Create bundle
         * @param {string} companyId Company ID
         * @param {BundleRequest} reqBody json format
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBundle: async (companyId: string, reqBody: BundleRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createBundle', 'companyId', companyId)
            // verify required parameter 'reqBody' is not null or undefined
            assertParamExists('createBundle', 'reqBody', reqBody)
            const localVarPath = `/company/{company_id}/products/bundles`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a bundle based on bundle_id. (Delete means setting archived to true)
         * @summary Delete/Archive bundle
         * @param {string} companyId Company ID
         * @param {string} bundleId Bundle ID
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBundle: async (companyId: string, bundleId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteBundle', 'companyId', companyId)
            // verify required parameter 'bundleId' is not null or undefined
            assertParamExists('deleteBundle', 'bundleId', bundleId)
            const localVarPath = `/company/{company_id}/products/bundles/{bundle_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"bundle_id"}}`, encodeURIComponent(String(bundleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all of a company\'s product bundles
         * @summary Get all bundles
         * @param {string} companyId Company ID
         * @param {string} [search] Search: search_string
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyBundles: async (companyId: string, search?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllCompanyBundles', 'companyId', companyId)
            const localVarPath = `/company/{company_id}/products/bundles`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single bundle by ID
         * @summary Get a single bundle by ID
         * @param {string} companyId Company ID
         * @param {string} bundleId Bundle ID
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBundleByID: async (companyId: string, bundleId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getBundleByID', 'companyId', companyId)
            // verify required parameter 'bundleId' is not null or undefined
            assertParamExists('getBundleByID', 'bundleId', bundleId)
            const localVarPath = `/company/{company_id}/products/bundles/{bundle_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"bundle_id"}}`, encodeURIComponent(String(bundleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a bundle based on bundle_id. BundleName is mandatory
         * @summary Update a bundle
         * @param {string} companyId Company ID
         * @param {string} bundleId Bundle ID
         * @param {BundleRequest} reqBody json format
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBundle: async (companyId: string, bundleId: string, reqBody: BundleRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateBundle', 'companyId', companyId)
            // verify required parameter 'bundleId' is not null or undefined
            assertParamExists('updateBundle', 'bundleId', bundleId)
            // verify required parameter 'reqBody' is not null or undefined
            assertParamExists('updateBundle', 'reqBody', reqBody)
            const localVarPath = `/company/{company_id}/products/bundles/{bundle_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"bundle_id"}}`, encodeURIComponent(String(bundleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BundlesApi - functional programming interface
 * @export
 */
export const BundlesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BundlesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new bundle.
         * @summary Create bundle
         * @param {string} companyId Company ID
         * @param {BundleRequest} reqBody json format
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBundle(companyId: string, reqBody: BundleRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateBundle200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBundle(companyId, reqBody, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a bundle based on bundle_id. (Delete means setting archived to true)
         * @summary Delete/Archive bundle
         * @param {string} companyId Company ID
         * @param {string} bundleId Bundle ID
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBundle(companyId: string, bundleId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteInventory200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBundle(companyId, bundleId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all of a company\'s product bundles
         * @summary Get all bundles
         * @param {string} companyId Company ID
         * @param {string} [search] Search: search_string
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCompanyBundles(companyId: string, search?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllCompanyBundles200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCompanyBundles(companyId, search, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a single bundle by ID
         * @summary Get a single bundle by ID
         * @param {string} companyId Company ID
         * @param {string} bundleId Bundle ID
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBundleByID(companyId: string, bundleId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateBundle200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBundleByID(companyId, bundleId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a bundle based on bundle_id. BundleName is mandatory
         * @summary Update a bundle
         * @param {string} companyId Company ID
         * @param {string} bundleId Bundle ID
         * @param {BundleRequest} reqBody json format
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBundle(companyId: string, bundleId: string, reqBody: BundleRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteInventory200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBundle(companyId, bundleId, reqBody, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BundlesApi - factory interface
 * @export
 */
export const BundlesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BundlesApiFp(configuration)
    return {
        /**
         * Create a new bundle.
         * @summary Create bundle
         * @param {string} companyId Company ID
         * @param {BundleRequest} reqBody json format
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBundle(companyId: string, reqBody: BundleRequest, authorization?: string, options?: any): AxiosPromise<CreateBundle200Response> {
            return localVarFp.createBundle(companyId, reqBody, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a bundle based on bundle_id. (Delete means setting archived to true)
         * @summary Delete/Archive bundle
         * @param {string} companyId Company ID
         * @param {string} bundleId Bundle ID
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBundle(companyId: string, bundleId: string, authorization?: string, options?: any): AxiosPromise<DeleteInventory200Response> {
            return localVarFp.deleteBundle(companyId, bundleId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all of a company\'s product bundles
         * @summary Get all bundles
         * @param {string} companyId Company ID
         * @param {string} [search] Search: search_string
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyBundles(companyId: string, search?: string, authorization?: string, options?: any): AxiosPromise<GetAllCompanyBundles200Response> {
            return localVarFp.getAllCompanyBundles(companyId, search, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single bundle by ID
         * @summary Get a single bundle by ID
         * @param {string} companyId Company ID
         * @param {string} bundleId Bundle ID
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBundleByID(companyId: string, bundleId: string, authorization?: string, options?: any): AxiosPromise<CreateBundle200Response> {
            return localVarFp.getBundleByID(companyId, bundleId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a bundle based on bundle_id. BundleName is mandatory
         * @summary Update a bundle
         * @param {string} companyId Company ID
         * @param {string} bundleId Bundle ID
         * @param {BundleRequest} reqBody json format
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBundle(companyId: string, bundleId: string, reqBody: BundleRequest, authorization?: string, options?: any): AxiosPromise<DeleteInventory200Response> {
            return localVarFp.updateBundle(companyId, bundleId, reqBody, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BundlesApi - object-oriented interface
 * @export
 * @class BundlesApi
 * @extends {BaseAPI}
 */
export class BundlesApi extends BaseAPI {
    /**
     * Create a new bundle.
     * @summary Create bundle
     * @param {string} companyId Company ID
     * @param {BundleRequest} reqBody json format
     * @param {string} [authorization] Bearer token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundlesApi
     */
    public createBundle(companyId: string, reqBody: BundleRequest, authorization?: string, options?: AxiosRequestConfig) {
        return BundlesApiFp(this.configuration).createBundle(companyId, reqBody, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a bundle based on bundle_id. (Delete means setting archived to true)
     * @summary Delete/Archive bundle
     * @param {string} companyId Company ID
     * @param {string} bundleId Bundle ID
     * @param {string} [authorization] Bearer token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundlesApi
     */
    public deleteBundle(companyId: string, bundleId: string, authorization?: string, options?: AxiosRequestConfig) {
        return BundlesApiFp(this.configuration).deleteBundle(companyId, bundleId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all of a company\'s product bundles
     * @summary Get all bundles
     * @param {string} companyId Company ID
     * @param {string} [search] Search: search_string
     * @param {string} [authorization] Bearer token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundlesApi
     */
    public getAllCompanyBundles(companyId: string, search?: string, authorization?: string, options?: AxiosRequestConfig) {
        return BundlesApiFp(this.configuration).getAllCompanyBundles(companyId, search, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single bundle by ID
     * @summary Get a single bundle by ID
     * @param {string} companyId Company ID
     * @param {string} bundleId Bundle ID
     * @param {string} [authorization] Bearer token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundlesApi
     */
    public getBundleByID(companyId: string, bundleId: string, authorization?: string, options?: AxiosRequestConfig) {
        return BundlesApiFp(this.configuration).getBundleByID(companyId, bundleId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a bundle based on bundle_id. BundleName is mandatory
     * @summary Update a bundle
     * @param {string} companyId Company ID
     * @param {string} bundleId Bundle ID
     * @param {BundleRequest} reqBody json format
     * @param {string} [authorization] Bearer token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundlesApi
     */
    public updateBundle(companyId: string, bundleId: string, reqBody: BundleRequest, authorization?: string, options?: AxiosRequestConfig) {
        return BundlesApiFp(this.configuration).updateBundle(companyId, bundleId, reqBody, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
