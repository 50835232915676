import { createFileRoute } from "@tanstack/react-router";
import { useGetOfferById } from "../../../../../../../service/customerModule/OfferApi";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/$salesOpportunityId/offer/$offerId/update"
)({
  preloadGcTime: 0,
  preloadStaleTime: 0,
  loader: ({ context: { queryClient, companyId }, params: { offerId } }) => {
    return queryClient.fetchQuery({
      ...useGetOfferById.getOptions({ offerId, companyId })
    });
  }
});
