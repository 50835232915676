/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CopyProjectRequest } from '../model';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { CustomerPortalPutRequest } from '../model';
// @ts-ignore
import { Project } from '../model';
// @ts-ignore
import { ProjectDetails } from '../model';
// @ts-ignore
import { ProjectPostRequest } from '../model';
// @ts-ignore
import { ProjectPostResponse } from '../model';
// @ts-ignore
import { ProjectPutRequest } from '../model';
// @ts-ignore
import { ProjectStatusPatchRequest } from '../model';
// @ts-ignore
import { ProjectStatusUpdatePutRequest } from '../model';
/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Copy project
         * @param {string} projectId ID of project to copy)
         * @param {CopyProjectRequest} copyProjectRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        copyProject: async (projectId: string, copyProjectRequest: CopyProjectRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('copyProject', 'projectId', projectId)
            // verify required parameter 'copyProjectRequest' is not null or undefined
            assertParamExists('copyProject', 'copyProjectRequest', copyProjectRequest)
            const localVarPath = `/v1/project/{projectId}/copy`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete project
         * @param {string} projectId UUID of project to delete
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteProject: async (projectId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteProject', 'projectId', projectId)
            const localVarPath = `/v1/project/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all company projects
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] Active status
         * @param {'Project' | 'SalesOpportunity' | 'All'} [projectType] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAllCompanyProjects: async (companyId: string, authorization?: string, status?: 'active' | 'deleted', projectType?: 'Project' | 'SalesOpportunity' | 'All', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllCompanyProjects', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/project/all`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (projectType !== undefined) {
                localVarQueryParameter['projectType'] = projectType;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all user projects
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] Active status
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAllUserProjects: async (authorization?: string, status?: 'active' | 'deleted', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/project/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company root projects
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] Active status
         * @param {'ALL' | 'USER'} [roleFilter] Filter on user role
         * @param {'Project' | 'SalesOpportunity' | 'All'} [projectType] Filter on project type
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCompanyRootProjects: async (companyId: string, authorization?: string, status?: 'active' | 'deleted', roleFilter?: 'ALL' | 'USER', projectType?: 'Project' | 'SalesOpportunity' | 'All', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyRootProjects', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/project`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (roleFilter !== undefined) {
                localVarQueryParameter['roleFilter'] = roleFilter;
            }

            if (projectType !== undefined) {
                localVarQueryParameter['projectType'] = projectType;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company root projects
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] Active status
         * @param {'Project' | 'SalesOpportunity' | 'All'} [projectType] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCompanyRootProjectsDepr: async (companyId: string, authorization?: string, status?: 'active' | 'deleted', projectType?: 'Project' | 'SalesOpportunity' | 'All', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyRootProjectsDepr', 'companyId', companyId)
            const localVarPath = `/v1/company/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (projectType !== undefined) {
                localVarQueryParameter['projectType'] = projectType;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project details
         * @param {string} projectId ID of project to retrieve (UUID format)
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [childStatus] 
         * @param {'active' | 'deleted'} [taskStatus] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProjectDetails: async (projectId: string, authorization?: string, childStatus?: 'active' | 'deleted', taskStatus?: 'active' | 'deleted', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectDetails', 'projectId', projectId)
            const localVarPath = `/v1/project/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (childStatus !== undefined) {
                localVarQueryParameter['childStatus'] = childStatus;
            }

            if (taskStatus !== undefined) {
                localVarQueryParameter['taskStatus'] = taskStatus;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get projects by IDs
         * @param {Array<string>} projectIds IDs of projects to fetch
         * @param {string} [authorization] 
         * @param {'Project' | 'SalesOpportunity' | 'All'} [projectType] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProjectsByIds1: async (projectIds: Array<string>, authorization?: string, projectType?: 'Project' | 'SalesOpportunity' | 'All', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectIds' is not null or undefined
            assertParamExists('getProjectsByIds1', 'projectIds', projectIds)
            const localVarPath = `/v1/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectIds) {
                localVarQueryParameter['projectIds'] = projectIds;
            }

            if (projectType !== undefined) {
                localVarQueryParameter['projectType'] = projectType;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all user\'s root projects
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] Active status
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserRootProjects1: async (authorization?: string, status?: 'active' | 'deleted', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new project
         * @param {ProjectPostRequest} projectPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postProject: async (projectPostRequest: ProjectPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectPostRequest' is not null or undefined
            assertParamExists('postProject', 'projectPostRequest', projectPostRequest)
            const localVarPath = `/v1/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a project
         * @param {string} projectId UUID of the project to update
         * @param {ProjectPutRequest} projectPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        putProject: async (projectId: string, projectPutRequest: ProjectPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('putProject', 'projectId', projectId)
            // verify required parameter 'projectPutRequest' is not null or undefined
            assertParamExists('putProject', 'projectPutRequest', projectPutRequest)
            const localVarPath = `/v1/project/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update project status
         * @param {string} projectId UUID of the project to update
         * @param {ProjectStatusUpdatePutRequest} projectStatusUpdatePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        putProjectStatus: async (projectId: string, projectStatusUpdatePutRequest: ProjectStatusUpdatePutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('putProjectStatus', 'projectId', projectId)
            // verify required parameter 'projectStatusUpdatePutRequest' is not null or undefined
            assertParamExists('putProjectStatus', 'projectStatusUpdatePutRequest', projectStatusUpdatePutRequest)
            const localVarPath = `/v1/project/{projectId}/status`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectStatusUpdatePutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restore project
         * @param {string} projectId UUID of project to restore
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        restoreProject: async (projectId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('restoreProject', 'projectId', projectId)
            const localVarPath = `/v1/project/restore/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update whether the project should be available through the customer portal
         * @param {string} projectId projectId UUID
         * @param {CustomerPortalPutRequest} customerPortalPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        toggleCustomerPortal: async (projectId: string, customerPortalPutRequest: CustomerPortalPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('toggleCustomerPortal', 'projectId', projectId)
            // verify required parameter 'customerPortalPutRequest' is not null or undefined
            assertParamExists('toggleCustomerPortal', 'customerPortalPutRequest', customerPortalPutRequest)
            const localVarPath = `/v1/project/{projectId}/customerPortal`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerPortalPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update project progress status
         * @param {string} projectId 
         * @param {ProjectStatusPatchRequest} projectStatusPatchRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateProgressStatus: async (projectId: string, projectStatusPatchRequest: ProjectStatusPatchRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateProgressStatus', 'projectId', projectId)
            // verify required parameter 'projectStatusPatchRequest' is not null or undefined
            assertParamExists('updateProgressStatus', 'projectStatusPatchRequest', projectStatusPatchRequest)
            const localVarPath = `/v1/project/{projectId}/progressStatus`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectStatusPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Copy project
         * @param {string} projectId ID of project to copy)
         * @param {CopyProjectRequest} copyProjectRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async copyProject(projectId: string, copyProjectRequest: CopyProjectRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyProject(projectId, copyProjectRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete project
         * @param {string} projectId UUID of project to delete
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deleteProject(projectId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProject(projectId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all company projects
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] Active status
         * @param {'Project' | 'SalesOpportunity' | 'All'} [projectType] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAllCompanyProjects(companyId: string, authorization?: string, status?: 'active' | 'deleted', projectType?: 'Project' | 'SalesOpportunity' | 'All', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCompanyProjects(companyId, authorization, status, projectType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all user projects
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] Active status
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAllUserProjects(authorization?: string, status?: 'active' | 'deleted', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUserProjects(authorization, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company root projects
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] Active status
         * @param {'ALL' | 'USER'} [roleFilter] Filter on user role
         * @param {'Project' | 'SalesOpportunity' | 'All'} [projectType] Filter on project type
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getCompanyRootProjects(companyId: string, authorization?: string, status?: 'active' | 'deleted', roleFilter?: 'ALL' | 'USER', projectType?: 'Project' | 'SalesOpportunity' | 'All', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyRootProjects(companyId, authorization, status, roleFilter, projectType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company root projects
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] Active status
         * @param {'Project' | 'SalesOpportunity' | 'All'} [projectType] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getCompanyRootProjectsDepr(companyId: string, authorization?: string, status?: 'active' | 'deleted', projectType?: 'Project' | 'SalesOpportunity' | 'All', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyRootProjectsDepr(companyId, authorization, status, projectType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get project details
         * @param {string} projectId ID of project to retrieve (UUID format)
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [childStatus] 
         * @param {'active' | 'deleted'} [taskStatus] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getProjectDetails(projectId: string, authorization?: string, childStatus?: 'active' | 'deleted', taskStatus?: 'active' | 'deleted', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectDetails(projectId, authorization, childStatus, taskStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get projects by IDs
         * @param {Array<string>} projectIds IDs of projects to fetch
         * @param {string} [authorization] 
         * @param {'Project' | 'SalesOpportunity' | 'All'} [projectType] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getProjectsByIds1(projectIds: Array<string>, authorization?: string, projectType?: 'Project' | 'SalesOpportunity' | 'All', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsByIds1(projectIds, authorization, projectType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all user\'s root projects
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] Active status
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getUserRootProjects1(authorization?: string, status?: 'active' | 'deleted', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRootProjects1(authorization, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new project
         * @param {ProjectPostRequest} projectPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async postProject(projectPostRequest: ProjectPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectPostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProject(projectPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a project
         * @param {string} projectId UUID of the project to update
         * @param {ProjectPutRequest} projectPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async putProject(projectId: string, projectPutRequest: ProjectPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putProject(projectId, projectPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update project status
         * @param {string} projectId UUID of the project to update
         * @param {ProjectStatusUpdatePutRequest} projectStatusUpdatePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async putProjectStatus(projectId: string, projectStatusUpdatePutRequest: ProjectStatusUpdatePutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putProjectStatus(projectId, projectStatusUpdatePutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Restore project
         * @param {string} projectId UUID of project to restore
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async restoreProject(projectId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreProject(projectId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update whether the project should be available through the customer portal
         * @param {string} projectId projectId UUID
         * @param {CustomerPortalPutRequest} customerPortalPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async toggleCustomerPortal(projectId: string, customerPortalPutRequest: CustomerPortalPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.toggleCustomerPortal(projectId, customerPortalPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update project progress status
         * @param {string} projectId 
         * @param {ProjectStatusPatchRequest} projectStatusPatchRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateProgressStatus(projectId: string, projectStatusPatchRequest: ProjectStatusPatchRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProgressStatus(projectId, projectStatusPatchRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * 
         * @summary Copy project
         * @param {string} projectId ID of project to copy)
         * @param {CopyProjectRequest} copyProjectRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        copyProject(projectId: string, copyProjectRequest: CopyProjectRequest, authorization?: string, options?: any): AxiosPromise<ProjectDetails> {
            return localVarFp.copyProject(projectId, copyProjectRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete project
         * @param {string} projectId UUID of project to delete
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteProject(projectId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteProject(projectId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all company projects
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] Active status
         * @param {'Project' | 'SalesOpportunity' | 'All'} [projectType] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAllCompanyProjects(companyId: string, authorization?: string, status?: 'active' | 'deleted', projectType?: 'Project' | 'SalesOpportunity' | 'All', options?: any): AxiosPromise<Array<Project>> {
            return localVarFp.getAllCompanyProjects(companyId, authorization, status, projectType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all user projects
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] Active status
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAllUserProjects(authorization?: string, status?: 'active' | 'deleted', options?: any): AxiosPromise<Array<Project>> {
            return localVarFp.getAllUserProjects(authorization, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company root projects
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] Active status
         * @param {'ALL' | 'USER'} [roleFilter] Filter on user role
         * @param {'Project' | 'SalesOpportunity' | 'All'} [projectType] Filter on project type
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCompanyRootProjects(companyId: string, authorization?: string, status?: 'active' | 'deleted', roleFilter?: 'ALL' | 'USER', projectType?: 'Project' | 'SalesOpportunity' | 'All', options?: any): AxiosPromise<Array<Project>> {
            return localVarFp.getCompanyRootProjects(companyId, authorization, status, roleFilter, projectType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company root projects
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] Active status
         * @param {'Project' | 'SalesOpportunity' | 'All'} [projectType] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCompanyRootProjectsDepr(companyId: string, authorization?: string, status?: 'active' | 'deleted', projectType?: 'Project' | 'SalesOpportunity' | 'All', options?: any): AxiosPromise<Array<Project>> {
            return localVarFp.getCompanyRootProjectsDepr(companyId, authorization, status, projectType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project details
         * @param {string} projectId ID of project to retrieve (UUID format)
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [childStatus] 
         * @param {'active' | 'deleted'} [taskStatus] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProjectDetails(projectId: string, authorization?: string, childStatus?: 'active' | 'deleted', taskStatus?: 'active' | 'deleted', options?: any): AxiosPromise<ProjectDetails> {
            return localVarFp.getProjectDetails(projectId, authorization, childStatus, taskStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get projects by IDs
         * @param {Array<string>} projectIds IDs of projects to fetch
         * @param {string} [authorization] 
         * @param {'Project' | 'SalesOpportunity' | 'All'} [projectType] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProjectsByIds1(projectIds: Array<string>, authorization?: string, projectType?: 'Project' | 'SalesOpportunity' | 'All', options?: any): AxiosPromise<Array<Project>> {
            return localVarFp.getProjectsByIds1(projectIds, authorization, projectType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all user\'s root projects
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] Active status
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserRootProjects1(authorization?: string, status?: 'active' | 'deleted', options?: any): AxiosPromise<Array<Project>> {
            return localVarFp.getUserRootProjects1(authorization, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new project
         * @param {ProjectPostRequest} projectPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postProject(projectPostRequest: ProjectPostRequest, authorization?: string, options?: any): AxiosPromise<ProjectPostResponse> {
            return localVarFp.postProject(projectPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a project
         * @param {string} projectId UUID of the project to update
         * @param {ProjectPutRequest} projectPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        putProject(projectId: string, projectPutRequest: ProjectPutRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.putProject(projectId, projectPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update project status
         * @param {string} projectId UUID of the project to update
         * @param {ProjectStatusUpdatePutRequest} projectStatusUpdatePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        putProjectStatus(projectId: string, projectStatusUpdatePutRequest: ProjectStatusUpdatePutRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.putProjectStatus(projectId, projectStatusUpdatePutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restore project
         * @param {string} projectId UUID of project to restore
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        restoreProject(projectId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.restoreProject(projectId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update whether the project should be available through the customer portal
         * @param {string} projectId projectId UUID
         * @param {CustomerPortalPutRequest} customerPortalPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        toggleCustomerPortal(projectId: string, customerPortalPutRequest: CustomerPortalPutRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.toggleCustomerPortal(projectId, customerPortalPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update project progress status
         * @param {string} projectId 
         * @param {ProjectStatusPatchRequest} projectStatusPatchRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateProgressStatus(projectId: string, projectStatusPatchRequest: ProjectStatusPatchRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateProgressStatus(projectId, projectStatusPatchRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * 
     * @summary Copy project
     * @param {string} projectId ID of project to copy)
     * @param {CopyProjectRequest} copyProjectRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public copyProject(projectId: string, copyProjectRequest: CopyProjectRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).copyProject(projectId, copyProjectRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete project
     * @param {string} projectId UUID of project to delete
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public deleteProject(projectId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).deleteProject(projectId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all company projects
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {'active' | 'deleted'} [status] Active status
     * @param {'Project' | 'SalesOpportunity' | 'All'} [projectType] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getAllCompanyProjects(companyId: string, authorization?: string, status?: 'active' | 'deleted', projectType?: 'Project' | 'SalesOpportunity' | 'All', options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getAllCompanyProjects(companyId, authorization, status, projectType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all user projects
     * @param {string} [authorization] 
     * @param {'active' | 'deleted'} [status] Active status
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getAllUserProjects(authorization?: string, status?: 'active' | 'deleted', options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getAllUserProjects(authorization, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company root projects
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {'active' | 'deleted'} [status] Active status
     * @param {'ALL' | 'USER'} [roleFilter] Filter on user role
     * @param {'Project' | 'SalesOpportunity' | 'All'} [projectType] Filter on project type
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getCompanyRootProjects(companyId: string, authorization?: string, status?: 'active' | 'deleted', roleFilter?: 'ALL' | 'USER', projectType?: 'Project' | 'SalesOpportunity' | 'All', options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getCompanyRootProjects(companyId, authorization, status, roleFilter, projectType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company root projects
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {'active' | 'deleted'} [status] Active status
     * @param {'Project' | 'SalesOpportunity' | 'All'} [projectType] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getCompanyRootProjectsDepr(companyId: string, authorization?: string, status?: 'active' | 'deleted', projectType?: 'Project' | 'SalesOpportunity' | 'All', options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getCompanyRootProjectsDepr(companyId, authorization, status, projectType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project details
     * @param {string} projectId ID of project to retrieve (UUID format)
     * @param {string} [authorization] 
     * @param {'active' | 'deleted'} [childStatus] 
     * @param {'active' | 'deleted'} [taskStatus] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjectDetails(projectId: string, authorization?: string, childStatus?: 'active' | 'deleted', taskStatus?: 'active' | 'deleted', options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProjectDetails(projectId, authorization, childStatus, taskStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get projects by IDs
     * @param {Array<string>} projectIds IDs of projects to fetch
     * @param {string} [authorization] 
     * @param {'Project' | 'SalesOpportunity' | 'All'} [projectType] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjectsByIds1(projectIds: Array<string>, authorization?: string, projectType?: 'Project' | 'SalesOpportunity' | 'All', options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProjectsByIds1(projectIds, authorization, projectType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all user\'s root projects
     * @param {string} [authorization] 
     * @param {'active' | 'deleted'} [status] Active status
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getUserRootProjects1(authorization?: string, status?: 'active' | 'deleted', options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getUserRootProjects1(authorization, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new project
     * @param {ProjectPostRequest} projectPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public postProject(projectPostRequest: ProjectPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).postProject(projectPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a project
     * @param {string} projectId UUID of the project to update
     * @param {ProjectPutRequest} projectPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public putProject(projectId: string, projectPutRequest: ProjectPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).putProject(projectId, projectPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update project status
     * @param {string} projectId UUID of the project to update
     * @param {ProjectStatusUpdatePutRequest} projectStatusUpdatePutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public putProjectStatus(projectId: string, projectStatusUpdatePutRequest: ProjectStatusUpdatePutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).putProjectStatus(projectId, projectStatusUpdatePutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restore project
     * @param {string} projectId UUID of project to restore
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public restoreProject(projectId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).restoreProject(projectId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update whether the project should be available through the customer portal
     * @param {string} projectId projectId UUID
     * @param {CustomerPortalPutRequest} customerPortalPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public toggleCustomerPortal(projectId: string, customerPortalPutRequest: CustomerPortalPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).toggleCustomerPortal(projectId, customerPortalPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update project progress status
     * @param {string} projectId 
     * @param {ProjectStatusPatchRequest} projectStatusPatchRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public updateProgressStatus(projectId: string, projectStatusPatchRequest: ProjectStatusPatchRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).updateProgressStatus(projectId, projectStatusPatchRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
