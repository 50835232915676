import { createFileRoute } from "@tanstack/react-router";
import { useGetPlannedInvoicesByProjectId } from "../../../../../../../service/api/PlannedInvoiceApi";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/"
)({
  loader: async ({ context: { queryClient }, params: { projectId } }) => {
    return await queryClient.fetchQuery(
      useGetPlannedInvoicesByProjectId.getOptions({ projectId })
    );
  }
});
