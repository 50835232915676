import React, { ReactNode } from "react";
import { PageTitleSize, pageTitleSizes, tabBarSizes } from "./ds/sizes";
import { TabNav as RadixTabNav } from "@radix-ui/themes";

type TabNavProps = {
  offset?: PageTitleSize;
  children: ReactNode;
};

export default function TabNav({ children, offset = "regular" }: TabNavProps) {
  return (
    <>
      <div style={{ height: tabBarSizes.regular }} />
      <RadixTabNav.Root
        style={{ top: pageTitleSizes[offset], position: "fixed", zIndex: 1 }}
        className={"px-6 bg-radix-panel-solid fixed right-0 left-[72px] z-[1]"}
      >
        {children}
      </RadixTabNav.Root>
    </>
  );
}
