import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { taskDetailsTabs } from "../../../../../../router/Routes";
import { useGetTaskById } from "../../../../../../service/api/TaskApiV2";

const taskDetailsSchema = z.object({
  isFromProject: z.boolean(),
  tab: taskDetailsTabs.catch("overview").optional(),
  selectedDeviationId: z.string().optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/task/$taskId"
)({
  validateSearch: taskDetailsSchema,
  loader: async ({ context: { queryClient }, params: { taskId } }) => {
    return queryClient.fetchQuery({
      ...useGetTaskById.getOptions({ taskId })
    });
  }
});
