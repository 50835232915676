import {
  createFileRoute,
  Outlet,
  useLoaderData,
  useParams
} from "@tanstack/react-router";
import NewPageTitle from "../../../../../components/common/NewPageTitle";
import { useGetOrderById } from "../../../../../service/api/OrderApi";
import TabNav from "../../../../../components/TabNav";
import { TabLink } from "../../../../../components/TabLink";
import { useTranslation } from "react-i18next";

/*
const orderDetailsTabsSchema = z.object({
  tab: orderDetailsTabs.catch("overview").optional(),
  selectedDeviationId: z.string().optional()
});*/

export const Route = createFileRoute(
  "/_protected/dashboard/orders/$orderId/_layout"
)({
  component: OrderDetailsLayout,
  //validateSearch: orderDetailsTabsSchema,
  loader: async ({
    context: { queryClient, companyId },
    params: { orderId }
  }) => {
    return queryClient.fetchQuery(
      useGetOrderById.getOptions({ orderId, companyId })
    );
  }
});

function OrderDetailsLayout() {
  const { t } = useTranslation();
  const { orderId } = useParams({
    from: "/_protected/dashboard/orders/$orderId/_layout"
  });
  const order = useLoaderData({
    from: "/_protected/dashboard/orders/$orderId/_layout"
  });

  return (
    <>
      <NewPageTitle withBackButton title={order.title} />
      <TabNav>
        <TabLink
          replace
          to={"/dashboard/orders/$orderId/overview"}
          params={{ orderId }}
        >
          {t("overview")}
        </TabLink>
        <TabLink
          replace
          to={"/dashboard/orders/$orderId/checklist"}
          params={{ orderId }}
        >
          {t("checklist")}
        </TabLink>
        <TabLink
          replace
          to={"/dashboard/orders/$orderId/product"}
          params={{ orderId }}
        >
          {t("products")}
        </TabLink>
        <TabLink
          replace
          to={"/dashboard/orders/$orderId/documentation"}
          params={{ orderId }}
        >
          {t("documentation")}
        </TabLink>
        <TabLink
          replace
          to={"/dashboard/orders/$orderId/deviation"}
          params={{ orderId }}
        >
          {t("deviations")}
        </TabLink>
        <TabLink
          replace
          to={"/dashboard/orders/$orderId/time"}
          params={{ orderId }}
        >
          {t("hours")}
        </TabLink>
      </TabNav>
      <Outlet />
    </>
  );
}
