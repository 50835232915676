import { createFileRoute } from "@tanstack/react-router";
import { useGetActivitiesByCompanyId } from "../../../../../service/timekeepingModule/ActivitiesApi";

export const Route = createFileRoute(
  "/_protected/dashboard/activities/$activityId/update"
)({
  preloadGcTime: 0,
  preloadStaleTime: 0,
  loader: ({ context: { queryClient, companyId } }) => {
    return queryClient.fetchQuery({
      ...useGetActivitiesByCompanyId.getOptions({ companyId })
    });
  }
});
