import axios from "axios";

import environment from "../../environment";

import {
  errorInterceptor,
  requestInterceptor,
  requestFormDataInterceptor
} from "../Interceptors";

export const productsInstance = axios.create({
  baseURL: environment.productModuleBaseUrl
});

productsInstance.interceptors.request.use(requestInterceptor);
productsInstance.interceptors.response.use((res) => res, errorInterceptor);

export const productsImportInstance = axios.create({
  baseURL: environment.productModuleBaseUrl
});

productsImportInstance.interceptors.request.use(requestFormDataInterceptor);
productsImportInstance.interceptors.response.use(
  (res) => res,
  errorInterceptor
);
