import { Table } from "@radix-ui/themes";
import React from "react";
import { RegisterHeaderProps } from "../../../hooks/useSort";
import RadixIcon from "../RadixIcon";
import classNames from "classnames";
import classnames from "classnames";

type TableHeaderCellProps = Table.ColumnHeaderCellProps & {
  sortFn?: RegisterHeaderProps;
};

export default function TableHeaderCell({
  sortFn,
  ...props
}: TableHeaderCellProps) {
  return (
    <Table.ColumnHeaderCell
      {...props}
      onClick={sortFn ? sortFn.onChange : props.onClick}
    >
      {sortFn && (
        <div
          className={classnames("flex items-center gap-2", {
            "justify-end": props.justify === "end"
          })}
        >
          {props.children}
          <RadixIcon
            className={classNames({
              "text-radix-gray-12": sortFn.isSelected,
              "text-radix-gray-9": !sortFn.isSelected
            })}
            icon={
              sortFn.isAscending && sortFn.isSelected
                ? "keyboard_arrow_up"
                : "keyboard_arrow_down"
            }
          />
        </div>
      )}
      {!sortFn && props.children}
    </Table.ColumnHeaderCell>
  );
}
