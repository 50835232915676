import { createFileRoute } from "@tanstack/react-router";
import { useGetLocationByResourceId } from "../../../../../service/api/LocationApi";

export const Route = createFileRoute(
  "/_protected/dashboard/location/$resourceId/update"
)({
  preloadGcTime: 0,
  preloadStaleTime: 0,
  loader: async ({ context: { queryClient }, params: { resourceId } }) => {
    return await queryClient.fetchQuery({
      ...useGetLocationByResourceId.getOptions({ resourceId })
    });
  }
});
