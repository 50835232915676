import React from "react";
import { Spinner } from "@radix-ui/themes";

export const SuspenseFallback = () => {
  return (
    <div className={"flex items-center justify-center h-screen"}>
      <div className={"bg-radix-gray-3 p-6 rounded-2"}>
        <Spinner size={"3"} />
      </div>
    </div>
  );
};
