import { useState } from "react";

const useDialog = (initial = false) => {
  const [isOpen, setIsOpen] = useState<boolean>(initial);

  return {
    isOpen,
    onOpen: () => setIsOpen(true),
    onClose: () => setIsOpen(false),
    onToggle: () => setIsOpen((previous) => !previous)
  };
};

export default useDialog;
