import { productsInstance } from "./ProductsModuleHttpBase";
import { BundleRequest, BundlesApi } from "../../.generated/productModule";
import environment from "../../environment";
import { createQuery } from "react-query-kit";

const bundlesApi = new BundlesApi(
  undefined,
  environment.productModuleBaseUrl,
  productsInstance
);

export const useGetProductBundlesByCompanyId = createQuery({
  queryKey: ["getProductBundlesByCompanyId"],
  fetcher: async (variables: { companyId: string }) => {
    const response = await bundlesApi.getAllCompanyBundles(variables.companyId);
    return response.data.body!;
  }
});

export const useGetProductBundleById = createQuery({
  queryKey: ["getProductBundleById"],
  fetcher: async (variables: { bundleId: string; companyId: string }) => {
    const response = await bundlesApi.getBundleByID(
      variables.companyId,
      variables.bundleId
    );
    return response.data.body;
  }
});

export async function createBundle(companyId: string, reqBody: BundleRequest) {
  return bundlesApi.createBundle(companyId, reqBody);
}

export async function deleteBundle(companyId: string, bundleId: string) {
  await bundlesApi.deleteBundle(companyId, bundleId);
}

export async function updateBundle(
  companyId: string,
  bundleId: string,
  reqBody: BundleRequest
) {
  return await bundlesApi.updateBundle(companyId, bundleId, reqBody);
}
