import {
  FileRequest,
  OfferApi,
  OfferPostRequest,
  OfferPutRequest,
  OfferTemplatePostRequest
} from "../../.generated/customerModule";
import environment from "../../environment";
import { customerInstance } from "./CustomerHttpBase";
import axios from "axios";
import { createQuery } from "react-query-kit";

const defaultInstance = axios.create();
const offerApi = new OfferApi(
  undefined,
  environment.customerBaseUrl,
  customerInstance
);

export const useGetOfferById = createQuery({
  queryKey: ["getOfferById"],
  fetcher: async (variables: { companyId: string; offerId: string }) => {
    const response = await offerApi.getOfferById(
      variables.companyId,
      variables.offerId
    );
    return response.data;
  }
});

export const useGetOffersByProjectId = createQuery({
  queryKey: ["getOffersByProjectId"],
  fetcher: async (variables: { companyId: string; projectId: string }) => {
    const response = await offerApi.getOffersByProjectId(
      variables.companyId,
      variables.projectId
    );
    return response.data;
  }
});

export const useGetOffersBySalesOpportunityId = createQuery({
  queryKey: ["getOffersBySalesOpportunityId"],
  fetcher: async (variables: {
    companyId: string;
    salesOpportunityId: string;
  }) => {
    const response = await offerApi.getOffersBySalesOpportunityId(
      variables.companyId,
      variables.salesOpportunityId
    );
    return response.data;
  }
});

export const useGetOfferNotifications = createQuery({
  queryKey: ["getOfferNotifications"],
  fetcher: async (variables: { companyId: string }) => {
    const response = await offerApi.getOfferNotifications(variables.companyId);
    return response.data;
  }
});

export async function markOfferAsRead(companyId: string, offerId: string) {
  return offerApi.markOfferAsRead(companyId, offerId);
}

export async function getOffersByCompanyId(companyId: string) {
  const response = await offerApi.getOffersByCompanyId(companyId);
  return response.data;
}

export async function getOfferById(companyId: string, id: string) {
  const response = await offerApi.getOfferById(companyId, id);
  return response.data;
}

export async function getOfferTemplatesByCompanyId(
  companyId: string,
  language: string
) {
  const response = await offerApi.getOfferTemplatesByCompanyId(
    companyId,
    undefined,
    language
  );
  return response.data;
}

export async function getOfferTemplateById(companyId: string, id: string) {
  const response = await offerApi.getOfferTemplateById(companyId, id);
  return response.data;
}

export async function deleteOfferTemplate(
  companyId: string,
  templateId: string
) {
  await offerApi.deleteOfferTemplate(companyId, templateId);
}

export async function createOrReplaceTemplate(
  companyId: string,
  request: OfferTemplatePostRequest
) {
  const response = await offerApi.createOrReplaceTemplate(companyId, request);
  return response.data;
}

export async function getOffersByCustomerId(
  companyId: string,
  customerId: string
) {
  const response = await offerApi.getOffersByCustomerId(companyId, customerId);
  return response.data;
}

export async function createOffer(
  companyId: string,
  request: OfferPostRequest
) {
  const response = await offerApi.createOffer(companyId, request);
  return response.data;
}

export async function updateOffer(
  companyId: string,
  offerId: string,
  request: OfferPutRequest
) {
  const response = await offerApi.updateOffer(companyId, offerId, request);
  return response.data;
}

export async function getOfferFileUploadUrl(
  companyId: string,
  contentType = "application/pdf"
) {
  const response = await offerApi.getOfferUploadUrl(companyId, {
    contentType //: "application/pdf"
  });
  return response.data;
}

export async function archiveOffer(companyId: string, offerId: string) {
  await offerApi.archiveOffer(companyId, offerId);
}

export async function sendOfferSms(
  companyId: string,
  offerId: string,
  companyName: string
) {
  await offerApi.sendOfferSMS(companyId, offerId, {
    companyName: companyName
  });
}

export async function sendOfferEmail(
  companyId: string,
  offerId: string,
  companyName: string
) {
  await offerApi.sendOfferEmail(companyId, offerId, {
    companyName: companyName
  });
}

export async function sendOffer(
  companyId: string,
  offerId: string,
  companyName: string,
  message: string,
  expirationDate: string,
  sendEmail: boolean,
  sendSms: boolean
) {
  await offerApi.sendOffer(companyId, offerId, {
    companyName,
    message,
    expirationDate,
    sendEmail,
    sendSms
  });
}

export async function uploadOfferFile(
  companyId: string,
  file: File
): Promise<FileRequest> {
  const [{ fileId, uploadUrl }, body] = await Promise.all([
    getOfferFileUploadUrl(companyId, file.type),
    file.arrayBuffer()
  ]);

  const formattedFileName = file.name.replace(/[^\x00-\x7F]/g, "");
  const headers = {
    "Content-Disposition": `filename=${formattedFileName}`,
    "Content-Type": file.type
  };
  await defaultInstance.put(uploadUrl, body, {
    headers: headers
  });
  return {
    fileId,
    fileType: file.type,
    name: file.name
  };
}
