import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { projectDetailsGuestTabs } from "../../../router/Routes";
import { useGetPublicProjectById } from "../../../service/api/ProjectApiV2";

const projectDetailsGuestTabSchema = z.object({
  tab: projectDetailsGuestTabs.default("progressPlan").optional(),
  checklistId: z.string().optional(),
  deviationId: z.string().optional()
});

export const Route = createFileRoute("/guest/project/$projectId")({
  validateSearch: projectDetailsGuestTabSchema,
  loader: ({ context: { queryClient }, params: { projectId } }) => {
    return queryClient.fetchQuery({
      ...useGetPublicProjectById.getOptions({ projectId })
    });
  }
});
