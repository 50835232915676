import { useEffect } from "react";
import { Theme, useAppStore } from "../state/app_store";

export default function useTheme() {
  const theme = useAppStore((s) => s.theme);
  const setStoredTheme = useAppStore((s) => s.setTheme);

  useEffect(() => {
    const body = document.getElementById("body");
    const radixTheme = document.getElementById("radixThemeElement");
    if (!body || !radixTheme) return;

    if (theme === "dark") {
      body.classList.remove("light");
      body.classList.add("dark");
      radixTheme.classList.remove("light");
      radixTheme.classList.add("dark");
    } else {
      body.classList.remove("dark");
      body.classList.add("light");
      radixTheme.classList.remove("dark");
      radixTheme.classList.add("light");
    }
  }, [theme]);

  function setTheme(theme: Theme) {
    setStoredTheme(theme);
  }

  return {
    theme,
    setTheme
  };
}
