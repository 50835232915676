import {
  RecurringOrderApi,
  RecurringOrderTemplatePostRequest,
  RecurringOrderTemplatePutRequest
} from "../../.generated/api";
import environment from "../../environment";
import { apiInstance } from "./ApiHttpBase";
import { createQuery } from "react-query-kit";

const recurringOrderApi = new RecurringOrderApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export const useGetOrderTemplateById = createQuery({
  queryKey: ["getOrderTemplateById"],
  fetcher: async (variables: { companyId: string; templateId: string }) => {
    const response = await recurringOrderApi.getRecurringOrderTemplate(
      variables.companyId,
      variables.templateId
    );
    return response.data;
  }
});

export const useGetOrderTemplatesByCompanyId = createQuery({
  queryKey: ["getOrderTemplateByCompanyId"],
  fetcher: async (variables: {
    companyId: string;
    page?: number;
    query?: string;
    customerId?: string;
  }) => {
    const response =
      await recurringOrderApi.getRecurringOrderTemplatesForCompany(
        variables.companyId,
        variables.page,
        variables.query,
        variables.customerId
      );
    return response.data;
  }
});

export async function createRecurringOrderTemplate(
  companyId: string,
  request: RecurringOrderTemplatePostRequest
) {
  const response = await recurringOrderApi.createRecurringOrderTemplate(
    companyId,
    request
  );
  return response.data;
}

export function addUserToTemplate(
  companyId: string,
  templateId: string,
  userId: string
) {
  return recurringOrderApi.addUserToTemplate(companyId, templateId, userId);
}

export function removeUserFromTemplate(
  companyId: string,
  templateId: string,
  userId: string
) {
  return recurringOrderApi.removeUserFromTemplate(
    companyId,
    templateId,
    userId
  );
}

export async function updateRecurringOrder(
  request: RecurringOrderTemplatePutRequest
) {
  const response =
    await recurringOrderApi.updateRecurringOrderTemplate(request);
  return response.data;
}

export async function archiveOrderTemplate(
  companyId: string,
  templateId: string
) {
  await recurringOrderApi.archiveRecurringOrderTemplate(companyId, templateId);
}
