import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const projectDeviationPageSchema = z.object({
  selectedDeviationId: z.string().optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/deviation"
)({
  validateSearch: projectDeviationPageSchema
});
