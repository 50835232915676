/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { Invoice } from '../model';
// @ts-ignore
import { PlannedInvoice } from '../model';
// @ts-ignore
import { PlannedInvoicePostRequest } from '../model';
/**
 * PlannedInvoiceApi - axios parameter creator
 * @export
 */
export const PlannedInvoiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Convert planned invoices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertPlannedInvoices: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/planned-invoice/convert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new planned invoice on project
         * @param {string} projectId 
         * @param {PlannedInvoicePostRequest} plannedInvoicePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlannedInvoice: async (projectId: string, plannedInvoicePostRequest: PlannedInvoicePostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createPlannedInvoice', 'projectId', projectId)
            // verify required parameter 'plannedInvoicePostRequest' is not null or undefined
            assertParamExists('createPlannedInvoice', 'plannedInvoicePostRequest', plannedInvoicePostRequest)
            const localVarPath = `/v1/project/{projectId}/planned-invoice`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(plannedInvoicePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete planned invoice on project
         * @param {string} projectId 
         * @param {string} invoiceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlannedInvoice: async (projectId: string, invoiceId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deletePlannedInvoice', 'projectId', projectId)
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('deletePlannedInvoice', 'invoiceId', invoiceId)
            const localVarPath = `/v1/project/{projectId}/planned-invoice/{invoiceId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get planned invoices on project
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlannedInvoicesOnProject: async (projectId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getPlannedInvoicesOnProject', 'projectId', projectId)
            const localVarPath = `/v1/project/{projectId}/planned-invoice`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlannedInvoiceApi - functional programming interface
 * @export
 */
export const PlannedInvoiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlannedInvoiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Convert planned invoices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convertPlannedInvoices(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.convertPlannedInvoices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new planned invoice on project
         * @param {string} projectId 
         * @param {PlannedInvoicePostRequest} plannedInvoicePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPlannedInvoice(projectId: string, plannedInvoicePostRequest: PlannedInvoicePostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlannedInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPlannedInvoice(projectId, plannedInvoicePostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete planned invoice on project
         * @param {string} projectId 
         * @param {string} invoiceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePlannedInvoice(projectId: string, invoiceId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePlannedInvoice(projectId, invoiceId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get planned invoices on project
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlannedInvoicesOnProject(projectId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlannedInvoice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlannedInvoicesOnProject(projectId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlannedInvoiceApi - factory interface
 * @export
 */
export const PlannedInvoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlannedInvoiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Convert planned invoices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertPlannedInvoices(options?: any): AxiosPromise<Invoice> {
            return localVarFp.convertPlannedInvoices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new planned invoice on project
         * @param {string} projectId 
         * @param {PlannedInvoicePostRequest} plannedInvoicePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlannedInvoice(projectId: string, plannedInvoicePostRequest: PlannedInvoicePostRequest, authorization?: string, options?: any): AxiosPromise<PlannedInvoice> {
            return localVarFp.createPlannedInvoice(projectId, plannedInvoicePostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete planned invoice on project
         * @param {string} projectId 
         * @param {string} invoiceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlannedInvoice(projectId: string, invoiceId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deletePlannedInvoice(projectId, invoiceId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get planned invoices on project
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlannedInvoicesOnProject(projectId: string, authorization?: string, options?: any): AxiosPromise<Array<PlannedInvoice>> {
            return localVarFp.getPlannedInvoicesOnProject(projectId, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlannedInvoiceApi - object-oriented interface
 * @export
 * @class PlannedInvoiceApi
 * @extends {BaseAPI}
 */
export class PlannedInvoiceApi extends BaseAPI {
    /**
     * 
     * @summary Convert planned invoices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlannedInvoiceApi
     */
    public convertPlannedInvoices(options?: AxiosRequestConfig) {
        return PlannedInvoiceApiFp(this.configuration).convertPlannedInvoices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new planned invoice on project
     * @param {string} projectId 
     * @param {PlannedInvoicePostRequest} plannedInvoicePostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlannedInvoiceApi
     */
    public createPlannedInvoice(projectId: string, plannedInvoicePostRequest: PlannedInvoicePostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return PlannedInvoiceApiFp(this.configuration).createPlannedInvoice(projectId, plannedInvoicePostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete planned invoice on project
     * @param {string} projectId 
     * @param {string} invoiceId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlannedInvoiceApi
     */
    public deletePlannedInvoice(projectId: string, invoiceId: string, authorization?: string, options?: AxiosRequestConfig) {
        return PlannedInvoiceApiFp(this.configuration).deletePlannedInvoice(projectId, invoiceId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get planned invoices on project
     * @param {string} projectId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlannedInvoiceApi
     */
    public getPlannedInvoicesOnProject(projectId: string, authorization?: string, options?: AxiosRequestConfig) {
        return PlannedInvoiceApiFp(this.configuration).getPlannedInvoicesOnProject(projectId, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
