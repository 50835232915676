import React, { forwardRef } from "react";
import { Table } from "@radix-ui/themes";
import classnames from "classnames";

// export default function TableRow({ ...props }: Table.RowProps) {
//   return (
//     <Table.Row
//       className={classnames("group/tr", props.className)}
//       {...props}
//     />
//   );
// }

const TableRow = forwardRef<HTMLTableRowElement, Table.RowProps>(
  ({ ...props }, ref) => {
    return (
      <Table.Row
        className={classnames("group/tr", props.className)}
        {...props}
        ref={ref}
      />
    );
  }
);
export default TableRow;
