/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CompanyUser } from '../model';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { EmergencyContactPostRequest } from '../model';
// @ts-ignore
import { UserCompanyPutRequest } from '../model';
// @ts-ignore
import { UserV2 } from '../model';
/**
 * UserV2Api - axios parameter creator
 * @export
 */
export const UserV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add user emergency contact
         * @param {string} companyId 
         * @param {string} userId 
         * @param {EmergencyContactPostRequest} emergencyContactPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserEmergencyContact: async (companyId: string, userId: string, emergencyContactPostRequest: EmergencyContactPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('addUserEmergencyContact', 'companyId', companyId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addUserEmergencyContact', 'userId', userId)
            // verify required parameter 'emergencyContactPostRequest' is not null or undefined
            assertParamExists('addUserEmergencyContact', 'emergencyContactPostRequest', emergencyContactPostRequest)
            const localVarPath = `/v2/company/{companyId}/user/{userId}/emergencyContact`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emergencyContactPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user emergency contact
         * @param {string} companyId 
         * @param {string} userId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserEmergencyContact: async (companyId: string, userId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteUserEmergencyContact', 'companyId', companyId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserEmergencyContact', 'userId', userId)
            const localVarPath = `/v2/company/{companyId}/user/{userId}/emergencyContact`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get information about currently logged in user
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoggedInUser: async (authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all users in company
         * @param {string} companyId 
         * @param {boolean} [onlyActive] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersInCompany: async (companyId: string, onlyActive?: boolean, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getUsersInCompany', 'companyId', companyId)
            const localVarPath = `/v2/company/{companyId}/user`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (onlyActive !== undefined) {
                localVarQueryParameter['onlyActive'] = onlyActive;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user company info
         * @param {string} companyId 
         * @param {string} userId 
         * @param {UserCompanyPutRequest} userCompanyPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserCompanyInfo: async (companyId: string, userId: string, userCompanyPutRequest: UserCompanyPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateUserCompanyInfo', 'companyId', companyId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserCompanyInfo', 'userId', userId)
            // verify required parameter 'userCompanyPutRequest' is not null or undefined
            assertParamExists('updateUserCompanyInfo', 'userCompanyPutRequest', userCompanyPutRequest)
            const localVarPath = `/v2/company/{companyId}/user/{userId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCompanyPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserV2Api - functional programming interface
 * @export
 */
export const UserV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add user emergency contact
         * @param {string} companyId 
         * @param {string} userId 
         * @param {EmergencyContactPostRequest} emergencyContactPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserEmergencyContact(companyId: string, userId: string, emergencyContactPostRequest: EmergencyContactPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserEmergencyContact(companyId, userId, emergencyContactPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete user emergency contact
         * @param {string} companyId 
         * @param {string} userId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserEmergencyContact(companyId: string, userId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserEmergencyContact(companyId, userId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get information about currently logged in user
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoggedInUser(authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoggedInUser(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all users in company
         * @param {string} companyId 
         * @param {boolean} [onlyActive] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersInCompany(companyId: string, onlyActive?: boolean, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersInCompany(companyId, onlyActive, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user company info
         * @param {string} companyId 
         * @param {string} userId 
         * @param {UserCompanyPutRequest} userCompanyPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserCompanyInfo(companyId: string, userId: string, userCompanyPutRequest: UserCompanyPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserCompanyInfo(companyId, userId, userCompanyPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserV2Api - factory interface
 * @export
 */
export const UserV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserV2ApiFp(configuration)
    return {
        /**
         * 
         * @summary Add user emergency contact
         * @param {string} companyId 
         * @param {string} userId 
         * @param {EmergencyContactPostRequest} emergencyContactPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserEmergencyContact(companyId: string, userId: string, emergencyContactPostRequest: EmergencyContactPostRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.addUserEmergencyContact(companyId, userId, emergencyContactPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete user emergency contact
         * @param {string} companyId 
         * @param {string} userId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserEmergencyContact(companyId: string, userId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteUserEmergencyContact(companyId, userId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get information about currently logged in user
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoggedInUser(authorization?: string, options?: any): AxiosPromise<UserV2> {
            return localVarFp.getLoggedInUser(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all users in company
         * @param {string} companyId 
         * @param {boolean} [onlyActive] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersInCompany(companyId: string, onlyActive?: boolean, authorization?: string, options?: any): AxiosPromise<Array<CompanyUser>> {
            return localVarFp.getUsersInCompany(companyId, onlyActive, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user company info
         * @param {string} companyId 
         * @param {string} userId 
         * @param {UserCompanyPutRequest} userCompanyPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserCompanyInfo(companyId: string, userId: string, userCompanyPutRequest: UserCompanyPutRequest, authorization?: string, options?: any): AxiosPromise<CompanyUser> {
            return localVarFp.updateUserCompanyInfo(companyId, userId, userCompanyPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserV2Api - object-oriented interface
 * @export
 * @class UserV2Api
 * @extends {BaseAPI}
 */
export class UserV2Api extends BaseAPI {
    /**
     * 
     * @summary Add user emergency contact
     * @param {string} companyId 
     * @param {string} userId 
     * @param {EmergencyContactPostRequest} emergencyContactPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserV2Api
     */
    public addUserEmergencyContact(companyId: string, userId: string, emergencyContactPostRequest: EmergencyContactPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return UserV2ApiFp(this.configuration).addUserEmergencyContact(companyId, userId, emergencyContactPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete user emergency contact
     * @param {string} companyId 
     * @param {string} userId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserV2Api
     */
    public deleteUserEmergencyContact(companyId: string, userId: string, authorization?: string, options?: AxiosRequestConfig) {
        return UserV2ApiFp(this.configuration).deleteUserEmergencyContact(companyId, userId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get information about currently logged in user
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserV2Api
     */
    public getLoggedInUser(authorization?: string, options?: AxiosRequestConfig) {
        return UserV2ApiFp(this.configuration).getLoggedInUser(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all users in company
     * @param {string} companyId 
     * @param {boolean} [onlyActive] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserV2Api
     */
    public getUsersInCompany(companyId: string, onlyActive?: boolean, authorization?: string, options?: AxiosRequestConfig) {
        return UserV2ApiFp(this.configuration).getUsersInCompany(companyId, onlyActive, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user company info
     * @param {string} companyId 
     * @param {string} userId 
     * @param {UserCompanyPutRequest} userCompanyPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserV2Api
     */
    public updateUserCompanyInfo(companyId: string, userId: string, userCompanyPutRequest: UserCompanyPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return UserV2ApiFp(this.configuration).updateUserCompanyInfo(companyId, userId, userCompanyPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
