import { createFileRoute } from "@tanstack/react-router";
import { useGetInvoicesByProjectId } from "../../../../../../../service/api/InvoiceApi";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/invoice"
)({
  loader: async ({
    context: { queryClient, companyId },
    params: { projectId }
  }) => {
    return await queryClient.fetchQuery(
      useGetInvoicesByProjectId.getOptions({ companyId, projectId })
    );
  }
});
