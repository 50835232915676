import React, { ReactNode } from "react";
import "./NewTextField.css";
import { Text } from "@radix-ui/themes";
import Badge from "./Badge";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

type LabelProps =
  | { id?: string; label?: string }
  | { label?: never; id?: never };

export type InputWrapperProps = Omit<BaseInputWrapperProps, "children">;

type BaseInputWrapperProps = LabelProps & {
  errorMessage?: string;
  description?: string;
  children: ((className: string) => ReactNode) | ReactNode;
  required?: boolean;
  noBottomPadding?: boolean;
  fullWidth?: boolean;
  wrapperClassName?: string;
};

export default function InputWrapperNew({
  id,
  label,
  description,
  errorMessage,
  children,
  required,
  fullWidth,
  wrapperClassName,
  noBottomPadding = false
}: BaseInputWrapperProps) {
  const { t } = useTranslation();
  const noPadding = errorMessage || noBottomPadding;
  const styles = [errorMessage ? "inputError" : "", fullWidth ? "w-full" : ""]
    .filter(Boolean)
    .join(" ");

  return (
    <div
      className={classnames(wrapperClassName, {
        "w-full": fullWidth,
        "mb-4": !noPadding
      })}
    >
      {label && (
        <div
          className={classnames("flex gap-2 items-center", {
            "mb-2": !description,
            "mb-1": description
          })}
        >
          <Text size={"2"} weight={"medium"} as={"label"} htmlFor={id}>
            {label}
          </Text>
          {required && (
            <Badge color={"yellow"} size={"1"}>
              {t("fieldRequiredLabel")}
            </Badge>
          )}
        </div>
      )}
      {description && (
        <Text size={"2"} color={"gray"} as={"p"} mb={"2"}>
          {description}
        </Text>
      )}
      {typeof children === "function" ? children(styles) : children}
      {errorMessage && (
        <Text
          color={"red"}
          size={"2"}
          as={"p"}
          mt={"2"}
          mb={noBottomPadding ? "0" : "4"}
        >
          {errorMessage}
        </Text>
      )}
    </div>
  );
}
