import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { useGetFacilityById } from "../../../../../service/api/FacilityApi";

const updateFacilitySchema = z.object({
  customerId: z.string()
});

export const Route = createFileRoute(
  "/_protected/dashboard/facility/$facilityId/update"
)({
  validateSearch: updateFacilitySchema,
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: async ({
    context: { queryClient, companyId },
    params: { facilityId }
  }) => {
    return queryClient.fetchQuery({
      ...useGetFacilityById.getOptions({ facilityId, companyId })
    });
  }
});
