import { getAuth, User } from "firebase/auth";

export async function getCurrentUser(): Promise<User | null> {
  const auth = getAuth();

  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;
    if (currentUser) resolve(currentUser);
    else {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        unsubscribe();
        resolve(user);
      }, reject);
    }
  });
}
