import { productsInstance } from "./ProductsModuleHttpBase";

import {
  ProductPriceRequest,
  ProductRequest,
  ProductsApi
} from "../../.generated/productModule";
import environment from "../../environment";
import { createQuery } from "react-query-kit";

const productsApi = new ProductsApi(
  undefined,
  environment.productModuleBaseUrl,
  productsInstance
);

export const useGetProductsByCompanyId = createQuery({
  queryKey: ["getProductsByCompanyId"],
  fetcher: async (variables: {
    companyId: string;
    page?: number;
    sort?: string;
    query?: string;
    archived?: boolean;
    categoryIds?: string;
  }) => {
    const response = await productsApi.getAllCompanyProducts(
      variables.companyId,
      variables.page,
      10,
      variables.sort,
      variables.query,
      variables.archived,
      variables.categoryIds
    );

    return {
      products: response.data.body?.products ?? [],
      pagination: response.data.body!.pagination!
    };
  }
});

export const useGetProductById = createQuery({
  queryKey: ["getProductById"],
  fetcher: async (variables: { companyId: string; productId: string }) => {
    const response = await productsApi.getProductByID(
      variables.companyId,
      variables.productId
    );
    return response.data.body!;
  }
});

export async function createProduct(
  companyId: string,
  products: ProductRequest
) {
  return await productsApi.createProduct(companyId, products);
}

export async function deleteProduct(companyId: string, request: string) {
  await productsApi.deleteProduct(companyId, request);
}

export async function updateProduct(
  companyId: string,
  productId: string,
  product: ProductRequest
) {
  await productsApi.updateProduct(companyId, productId, product);
}

export async function updateProductPrices(
  companyId: string,
  productPrice: ProductPriceRequest
) {
  await productsApi.updateProductPrices(companyId, productPrice);
}
