/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChecklistItemPutRequest } from '../model';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { EhsItem } from '../model';
// @ts-ignore
import { EhsItemPostRequest } from '../model';
// @ts-ignore
import { EhsItemPutRequest } from '../model';
// @ts-ignore
import { EhsRequireSigning } from '../model';
// @ts-ignore
import { EhsTemplateDTO } from '../model';
// @ts-ignore
import { EhsTemplatePostRequest } from '../model';
// @ts-ignore
import { EhsTemplatePutRequest } from '../model';
// @ts-ignore
import { PaginatedResponseEhsItem } from '../model';
/**
 * EhsV2Api - axios parameter creator
 * @export
 */
export const EhsV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new EHS item
         * @param {string} companyId 
         * @param {EhsItemPostRequest} ehsItemPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEhsItem: async (companyId: string, ehsItemPostRequest: EhsItemPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createEhsItem', 'companyId', companyId)
            // verify required parameter 'ehsItemPostRequest' is not null or undefined
            assertParamExists('createEhsItem', 'ehsItemPostRequest', ehsItemPostRequest)
            const localVarPath = `/v2/company/{companyId}/ehs-item`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ehsItemPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create EHS template
         * @param {string} companyId 
         * @param {EhsTemplatePostRequest} ehsTemplatePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEhsTemplate: async (companyId: string, ehsTemplatePostRequest: EhsTemplatePostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createEhsTemplate', 'companyId', companyId)
            // verify required parameter 'ehsTemplatePostRequest' is not null or undefined
            assertParamExists('createEhsTemplate', 'ehsTemplatePostRequest', ehsTemplatePostRequest)
            const localVarPath = `/v2/company/{companyId}/ehs-item/template`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ehsTemplatePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete EHS item
         * @param {string} companyId 
         * @param {string} ehsItemId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEhsItem: async (companyId: string, ehsItemId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteEhsItem', 'companyId', companyId)
            // verify required parameter 'ehsItemId' is not null or undefined
            assertParamExists('deleteEhsItem', 'ehsItemId', ehsItemId)
            const localVarPath = `/v2/company/{companyId}/ehs-item/{ehsItemId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"ehsItemId"}}`, encodeURIComponent(String(ehsItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete EHS template
         * @param {string} companyId 
         * @param {string} ehsTemplateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEhsTemplate: async (companyId: string, ehsTemplateId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteEhsTemplate', 'companyId', companyId)
            // verify required parameter 'ehsTemplateId' is not null or undefined
            assertParamExists('deleteEhsTemplate', 'ehsTemplateId', ehsTemplateId)
            const localVarPath = `/v2/company/{companyId}/ehs-item/template/{ehsTemplateId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"ehsTemplateId"}}`, encodeURIComponent(String(ehsTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all EHS items
         * @param {string} companyId 
         * @param {string} resourceId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title'} [sortField] 
         * @param {string} [query] 
         * @param {'active' | 'deleted'} [status] 
         * @param {'BasicEHS' | 'SafetyInspection' | 'SHA' | 'SJA' | 'KU' | 'FinalInspection'} [ehsType] 
         * @param {'All' | 'RequiresUserAction' | 'CompletedForUser'} [filter] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEhsItemsByResource: async (companyId: string, resourceId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title', query?: string, status?: 'active' | 'deleted', ehsType?: 'BasicEHS' | 'SafetyInspection' | 'SHA' | 'SJA' | 'KU' | 'FinalInspection', filter?: 'All' | 'RequiresUserAction' | 'CompletedForUser', authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllEhsItemsByResource', 'companyId', companyId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getAllEhsItemsByResource', 'resourceId', resourceId)
            const localVarPath = `/v2/company/{companyId}/resource/{resourceId}/ehs-item`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (ehsType !== undefined) {
                localVarQueryParameter['ehsType'] = ehsType;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all EHS items as list
         * @param {string} companyId 
         * @param {string} resourceId 
         * @param {'active' | 'deleted'} [status] 
         * @param {'BasicEHS' | 'SafetyInspection' | 'SHA' | 'SJA' | 'KU' | 'FinalInspection'} [ehsType] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEhsItemsByResourceList: async (companyId: string, resourceId: string, status?: 'active' | 'deleted', ehsType?: 'BasicEHS' | 'SafetyInspection' | 'SHA' | 'SJA' | 'KU' | 'FinalInspection', authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllEhsItemsByResourceList', 'companyId', companyId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getAllEhsItemsByResourceList', 'resourceId', resourceId)
            const localVarPath = `/v2/company/{companyId}/resource/{resourceId}/ehs-item/list`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (ehsType !== undefined) {
                localVarQueryParameter['ehsType'] = ehsType;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all public EHS items by resource ID
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPublicEhsItemsByResourceId: async (resourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getAllPublicEhsItemsByResourceId', 'resourceId', resourceId)
            const localVarPath = `/v2/open/ehs-item/resource/{resourceId}`
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get unsigned EHS items for user
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUnsignedEhsItemsForUser: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllUnsignedEhsItemsForUser', 'companyId', companyId)
            const localVarPath = `/v2/company/{companyId}/ehs-item/user/unsigned`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get EHS templates by companyId
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyEhsTemplates: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyEhsTemplates', 'companyId', companyId)
            const localVarPath = `/v2/company/{companyId}/ehs-item/template`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get EHS item by ID
         * @param {string} companyId 
         * @param {string} ehsItemId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEhsItemById: async (companyId: string, ehsItemId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getEhsItemById', 'companyId', companyId)
            // verify required parameter 'ehsItemId' is not null or undefined
            assertParamExists('getEhsItemById', 'ehsItemId', ehsItemId)
            const localVarPath = `/v2/company/{companyId}/ehs-item/{ehsItemId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"ehsItemId"}}`, encodeURIComponent(String(ehsItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get EHS template
         * @param {string} companyId 
         * @param {string} ehsTemplateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEhsTemplateById: async (companyId: string, ehsTemplateId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getEhsTemplateById', 'companyId', companyId)
            // verify required parameter 'ehsTemplateId' is not null or undefined
            assertParamExists('getEhsTemplateById', 'ehsTemplateId', ehsTemplateId)
            const localVarPath = `/v2/company/{companyId}/ehs-item/template/{ehsTemplateId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"ehsTemplateId"}}`, encodeURIComponent(String(ehsTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get public EHS item by ID
         * @param {string} ehsItemId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicEhsItemById: async (ehsItemId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ehsItemId' is not null or undefined
            assertParamExists('getPublicEhsItemById', 'ehsItemId', ehsItemId)
            const localVarPath = `/v2/open/ehs-item/{ehsItemId}`
                .replace(`{${"ehsItemId"}}`, encodeURIComponent(String(ehsItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get number of signable EHS items on resource for user
         * @param {string} companyId 
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnsignedEhsItemsForUser: async (companyId: string, resourceId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getUnsignedEhsItemsForUser', 'companyId', companyId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getUnsignedEhsItemsForUser', 'resourceId', resourceId)
            const localVarPath = `/v2/company/{companyId}/resource/{resourceId}/ehs-item/count`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update EHS item info
         * @param {string} companyId 
         * @param {string} ehsItemId 
         * @param {boolean} completed 
         * @param {Array<ChecklistItemPutRequest>} checklistItemPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEHSItemInfo: async (companyId: string, ehsItemId: string, completed: boolean, checklistItemPutRequest: Array<ChecklistItemPutRequest>, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateEHSItemInfo', 'companyId', companyId)
            // verify required parameter 'ehsItemId' is not null or undefined
            assertParamExists('updateEHSItemInfo', 'ehsItemId', ehsItemId)
            // verify required parameter 'completed' is not null or undefined
            assertParamExists('updateEHSItemInfo', 'completed', completed)
            // verify required parameter 'checklistItemPutRequest' is not null or undefined
            assertParamExists('updateEHSItemInfo', 'checklistItemPutRequest', checklistItemPutRequest)
            const localVarPath = `/v2/company/{companyId}/ehs-item/{ehsItemId}/info`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"ehsItemId"}}`, encodeURIComponent(String(ehsItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (completed !== undefined) {
                localVarQueryParameter['completed'] = completed;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checklistItemPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update EHS item
         * @param {string} companyId 
         * @param {string} ehsItemId 
         * @param {EhsItemPutRequest} ehsItemPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEhsItem: async (companyId: string, ehsItemId: string, ehsItemPutRequest: EhsItemPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateEhsItem', 'companyId', companyId)
            // verify required parameter 'ehsItemId' is not null or undefined
            assertParamExists('updateEhsItem', 'ehsItemId', ehsItemId)
            // verify required parameter 'ehsItemPutRequest' is not null or undefined
            assertParamExists('updateEhsItem', 'ehsItemPutRequest', ehsItemPutRequest)
            const localVarPath = `/v2/company/{companyId}/ehs-item/{ehsItemId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"ehsItemId"}}`, encodeURIComponent(String(ehsItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ehsItemPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update EHS template
         * @param {string} companyId 
         * @param {string} ehsTemplateId 
         * @param {EhsTemplatePutRequest} ehsTemplatePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEhsTemplate: async (companyId: string, ehsTemplateId: string, ehsTemplatePutRequest: EhsTemplatePutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateEhsTemplate', 'companyId', companyId)
            // verify required parameter 'ehsTemplateId' is not null or undefined
            assertParamExists('updateEhsTemplate', 'ehsTemplateId', ehsTemplateId)
            // verify required parameter 'ehsTemplatePutRequest' is not null or undefined
            assertParamExists('updateEhsTemplate', 'ehsTemplatePutRequest', ehsTemplatePutRequest)
            const localVarPath = `/v2/company/{companyId}/ehs-item/template/{ehsTemplateId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"ehsTemplateId"}}`, encodeURIComponent(String(ehsTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ehsTemplatePutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EhsV2Api - functional programming interface
 * @export
 */
export const EhsV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EhsV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new EHS item
         * @param {string} companyId 
         * @param {EhsItemPostRequest} ehsItemPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEhsItem(companyId: string, ehsItemPostRequest: EhsItemPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EhsItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEhsItem(companyId, ehsItemPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create EHS template
         * @param {string} companyId 
         * @param {EhsTemplatePostRequest} ehsTemplatePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEhsTemplate(companyId: string, ehsTemplatePostRequest: EhsTemplatePostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EhsTemplateDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEhsTemplate(companyId, ehsTemplatePostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete EHS item
         * @param {string} companyId 
         * @param {string} ehsItemId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEhsItem(companyId: string, ehsItemId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEhsItem(companyId, ehsItemId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete EHS template
         * @param {string} companyId 
         * @param {string} ehsTemplateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEhsTemplate(companyId: string, ehsTemplateId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEhsTemplate(companyId, ehsTemplateId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all EHS items
         * @param {string} companyId 
         * @param {string} resourceId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title'} [sortField] 
         * @param {string} [query] 
         * @param {'active' | 'deleted'} [status] 
         * @param {'BasicEHS' | 'SafetyInspection' | 'SHA' | 'SJA' | 'KU' | 'FinalInspection'} [ehsType] 
         * @param {'All' | 'RequiresUserAction' | 'CompletedForUser'} [filter] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEhsItemsByResource(companyId: string, resourceId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title', query?: string, status?: 'active' | 'deleted', ehsType?: 'BasicEHS' | 'SafetyInspection' | 'SHA' | 'SJA' | 'KU' | 'FinalInspection', filter?: 'All' | 'RequiresUserAction' | 'CompletedForUser', authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseEhsItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEhsItemsByResource(companyId, resourceId, page, sortDirection, sortField, query, status, ehsType, filter, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all EHS items as list
         * @param {string} companyId 
         * @param {string} resourceId 
         * @param {'active' | 'deleted'} [status] 
         * @param {'BasicEHS' | 'SafetyInspection' | 'SHA' | 'SJA' | 'KU' | 'FinalInspection'} [ehsType] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEhsItemsByResourceList(companyId: string, resourceId: string, status?: 'active' | 'deleted', ehsType?: 'BasicEHS' | 'SafetyInspection' | 'SHA' | 'SJA' | 'KU' | 'FinalInspection', authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EhsItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEhsItemsByResourceList(companyId, resourceId, status, ehsType, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all public EHS items by resource ID
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPublicEhsItemsByResourceId(resourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EhsItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPublicEhsItemsByResourceId(resourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get unsigned EHS items for user
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUnsignedEhsItemsForUser(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EhsItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUnsignedEhsItemsForUser(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get EHS templates by companyId
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyEhsTemplates(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EhsTemplateDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyEhsTemplates(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get EHS item by ID
         * @param {string} companyId 
         * @param {string} ehsItemId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEhsItemById(companyId: string, ehsItemId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EhsItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEhsItemById(companyId, ehsItemId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get EHS template
         * @param {string} companyId 
         * @param {string} ehsTemplateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEhsTemplateById(companyId: string, ehsTemplateId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EhsTemplateDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEhsTemplateById(companyId, ehsTemplateId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get public EHS item by ID
         * @param {string} ehsItemId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicEhsItemById(ehsItemId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EhsItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicEhsItemById(ehsItemId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get number of signable EHS items on resource for user
         * @param {string} companyId 
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnsignedEhsItemsForUser(companyId: string, resourceId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EhsRequireSigning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnsignedEhsItemsForUser(companyId, resourceId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update EHS item info
         * @param {string} companyId 
         * @param {string} ehsItemId 
         * @param {boolean} completed 
         * @param {Array<ChecklistItemPutRequest>} checklistItemPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEHSItemInfo(companyId: string, ehsItemId: string, completed: boolean, checklistItemPutRequest: Array<ChecklistItemPutRequest>, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEHSItemInfo(companyId, ehsItemId, completed, checklistItemPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update EHS item
         * @param {string} companyId 
         * @param {string} ehsItemId 
         * @param {EhsItemPutRequest} ehsItemPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEhsItem(companyId: string, ehsItemId: string, ehsItemPutRequest: EhsItemPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EhsItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEhsItem(companyId, ehsItemId, ehsItemPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update EHS template
         * @param {string} companyId 
         * @param {string} ehsTemplateId 
         * @param {EhsTemplatePutRequest} ehsTemplatePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEhsTemplate(companyId: string, ehsTemplateId: string, ehsTemplatePutRequest: EhsTemplatePutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EhsTemplateDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEhsTemplate(companyId, ehsTemplateId, ehsTemplatePutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EhsV2Api - factory interface
 * @export
 */
export const EhsV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EhsV2ApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new EHS item
         * @param {string} companyId 
         * @param {EhsItemPostRequest} ehsItemPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEhsItem(companyId: string, ehsItemPostRequest: EhsItemPostRequest, authorization?: string, options?: any): AxiosPromise<EhsItem> {
            return localVarFp.createEhsItem(companyId, ehsItemPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create EHS template
         * @param {string} companyId 
         * @param {EhsTemplatePostRequest} ehsTemplatePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEhsTemplate(companyId: string, ehsTemplatePostRequest: EhsTemplatePostRequest, authorization?: string, options?: any): AxiosPromise<EhsTemplateDTO> {
            return localVarFp.createEhsTemplate(companyId, ehsTemplatePostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete EHS item
         * @param {string} companyId 
         * @param {string} ehsItemId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEhsItem(companyId: string, ehsItemId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteEhsItem(companyId, ehsItemId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete EHS template
         * @param {string} companyId 
         * @param {string} ehsTemplateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEhsTemplate(companyId: string, ehsTemplateId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteEhsTemplate(companyId, ehsTemplateId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all EHS items
         * @param {string} companyId 
         * @param {string} resourceId 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Title'} [sortField] 
         * @param {string} [query] 
         * @param {'active' | 'deleted'} [status] 
         * @param {'BasicEHS' | 'SafetyInspection' | 'SHA' | 'SJA' | 'KU' | 'FinalInspection'} [ehsType] 
         * @param {'All' | 'RequiresUserAction' | 'CompletedForUser'} [filter] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEhsItemsByResource(companyId: string, resourceId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title', query?: string, status?: 'active' | 'deleted', ehsType?: 'BasicEHS' | 'SafetyInspection' | 'SHA' | 'SJA' | 'KU' | 'FinalInspection', filter?: 'All' | 'RequiresUserAction' | 'CompletedForUser', authorization?: string, options?: any): AxiosPromise<PaginatedResponseEhsItem> {
            return localVarFp.getAllEhsItemsByResource(companyId, resourceId, page, sortDirection, sortField, query, status, ehsType, filter, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all EHS items as list
         * @param {string} companyId 
         * @param {string} resourceId 
         * @param {'active' | 'deleted'} [status] 
         * @param {'BasicEHS' | 'SafetyInspection' | 'SHA' | 'SJA' | 'KU' | 'FinalInspection'} [ehsType] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEhsItemsByResourceList(companyId: string, resourceId: string, status?: 'active' | 'deleted', ehsType?: 'BasicEHS' | 'SafetyInspection' | 'SHA' | 'SJA' | 'KU' | 'FinalInspection', authorization?: string, options?: any): AxiosPromise<Array<EhsItem>> {
            return localVarFp.getAllEhsItemsByResourceList(companyId, resourceId, status, ehsType, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all public EHS items by resource ID
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPublicEhsItemsByResourceId(resourceId: string, options?: any): AxiosPromise<Array<EhsItem>> {
            return localVarFp.getAllPublicEhsItemsByResourceId(resourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get unsigned EHS items for user
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUnsignedEhsItemsForUser(companyId: string, authorization?: string, options?: any): AxiosPromise<Array<EhsItem>> {
            return localVarFp.getAllUnsignedEhsItemsForUser(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get EHS templates by companyId
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyEhsTemplates(companyId: string, authorization?: string, options?: any): AxiosPromise<Array<EhsTemplateDTO>> {
            return localVarFp.getCompanyEhsTemplates(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get EHS item by ID
         * @param {string} companyId 
         * @param {string} ehsItemId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEhsItemById(companyId: string, ehsItemId: string, authorization?: string, options?: any): AxiosPromise<EhsItem> {
            return localVarFp.getEhsItemById(companyId, ehsItemId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get EHS template
         * @param {string} companyId 
         * @param {string} ehsTemplateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEhsTemplateById(companyId: string, ehsTemplateId: string, authorization?: string, options?: any): AxiosPromise<EhsTemplateDTO> {
            return localVarFp.getEhsTemplateById(companyId, ehsTemplateId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get public EHS item by ID
         * @param {string} ehsItemId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicEhsItemById(ehsItemId: string, authorization?: string, options?: any): AxiosPromise<EhsItem> {
            return localVarFp.getPublicEhsItemById(ehsItemId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get number of signable EHS items on resource for user
         * @param {string} companyId 
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnsignedEhsItemsForUser(companyId: string, resourceId: string, authorization?: string, options?: any): AxiosPromise<EhsRequireSigning> {
            return localVarFp.getUnsignedEhsItemsForUser(companyId, resourceId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update EHS item info
         * @param {string} companyId 
         * @param {string} ehsItemId 
         * @param {boolean} completed 
         * @param {Array<ChecklistItemPutRequest>} checklistItemPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEHSItemInfo(companyId: string, ehsItemId: string, completed: boolean, checklistItemPutRequest: Array<ChecklistItemPutRequest>, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateEHSItemInfo(companyId, ehsItemId, completed, checklistItemPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update EHS item
         * @param {string} companyId 
         * @param {string} ehsItemId 
         * @param {EhsItemPutRequest} ehsItemPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEhsItem(companyId: string, ehsItemId: string, ehsItemPutRequest: EhsItemPutRequest, authorization?: string, options?: any): AxiosPromise<EhsItem> {
            return localVarFp.updateEhsItem(companyId, ehsItemId, ehsItemPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update EHS template
         * @param {string} companyId 
         * @param {string} ehsTemplateId 
         * @param {EhsTemplatePutRequest} ehsTemplatePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEhsTemplate(companyId: string, ehsTemplateId: string, ehsTemplatePutRequest: EhsTemplatePutRequest, authorization?: string, options?: any): AxiosPromise<EhsTemplateDTO> {
            return localVarFp.updateEhsTemplate(companyId, ehsTemplateId, ehsTemplatePutRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EhsV2Api - object-oriented interface
 * @export
 * @class EhsV2Api
 * @extends {BaseAPI}
 */
export class EhsV2Api extends BaseAPI {
    /**
     * 
     * @summary Create new EHS item
     * @param {string} companyId 
     * @param {EhsItemPostRequest} ehsItemPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EhsV2Api
     */
    public createEhsItem(companyId: string, ehsItemPostRequest: EhsItemPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return EhsV2ApiFp(this.configuration).createEhsItem(companyId, ehsItemPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create EHS template
     * @param {string} companyId 
     * @param {EhsTemplatePostRequest} ehsTemplatePostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EhsV2Api
     */
    public createEhsTemplate(companyId: string, ehsTemplatePostRequest: EhsTemplatePostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return EhsV2ApiFp(this.configuration).createEhsTemplate(companyId, ehsTemplatePostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete EHS item
     * @param {string} companyId 
     * @param {string} ehsItemId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EhsV2Api
     */
    public deleteEhsItem(companyId: string, ehsItemId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EhsV2ApiFp(this.configuration).deleteEhsItem(companyId, ehsItemId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete EHS template
     * @param {string} companyId 
     * @param {string} ehsTemplateId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EhsV2Api
     */
    public deleteEhsTemplate(companyId: string, ehsTemplateId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EhsV2ApiFp(this.configuration).deleteEhsTemplate(companyId, ehsTemplateId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all EHS items
     * @param {string} companyId 
     * @param {string} resourceId 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'Title'} [sortField] 
     * @param {string} [query] 
     * @param {'active' | 'deleted'} [status] 
     * @param {'BasicEHS' | 'SafetyInspection' | 'SHA' | 'SJA' | 'KU' | 'FinalInspection'} [ehsType] 
     * @param {'All' | 'RequiresUserAction' | 'CompletedForUser'} [filter] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EhsV2Api
     */
    public getAllEhsItemsByResource(companyId: string, resourceId: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Title', query?: string, status?: 'active' | 'deleted', ehsType?: 'BasicEHS' | 'SafetyInspection' | 'SHA' | 'SJA' | 'KU' | 'FinalInspection', filter?: 'All' | 'RequiresUserAction' | 'CompletedForUser', authorization?: string, options?: AxiosRequestConfig) {
        return EhsV2ApiFp(this.configuration).getAllEhsItemsByResource(companyId, resourceId, page, sortDirection, sortField, query, status, ehsType, filter, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all EHS items as list
     * @param {string} companyId 
     * @param {string} resourceId 
     * @param {'active' | 'deleted'} [status] 
     * @param {'BasicEHS' | 'SafetyInspection' | 'SHA' | 'SJA' | 'KU' | 'FinalInspection'} [ehsType] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EhsV2Api
     */
    public getAllEhsItemsByResourceList(companyId: string, resourceId: string, status?: 'active' | 'deleted', ehsType?: 'BasicEHS' | 'SafetyInspection' | 'SHA' | 'SJA' | 'KU' | 'FinalInspection', authorization?: string, options?: AxiosRequestConfig) {
        return EhsV2ApiFp(this.configuration).getAllEhsItemsByResourceList(companyId, resourceId, status, ehsType, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all public EHS items by resource ID
     * @param {string} resourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EhsV2Api
     */
    public getAllPublicEhsItemsByResourceId(resourceId: string, options?: AxiosRequestConfig) {
        return EhsV2ApiFp(this.configuration).getAllPublicEhsItemsByResourceId(resourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get unsigned EHS items for user
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EhsV2Api
     */
    public getAllUnsignedEhsItemsForUser(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EhsV2ApiFp(this.configuration).getAllUnsignedEhsItemsForUser(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get EHS templates by companyId
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EhsV2Api
     */
    public getCompanyEhsTemplates(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EhsV2ApiFp(this.configuration).getCompanyEhsTemplates(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get EHS item by ID
     * @param {string} companyId 
     * @param {string} ehsItemId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EhsV2Api
     */
    public getEhsItemById(companyId: string, ehsItemId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EhsV2ApiFp(this.configuration).getEhsItemById(companyId, ehsItemId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get EHS template
     * @param {string} companyId 
     * @param {string} ehsTemplateId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EhsV2Api
     */
    public getEhsTemplateById(companyId: string, ehsTemplateId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EhsV2ApiFp(this.configuration).getEhsTemplateById(companyId, ehsTemplateId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get public EHS item by ID
     * @param {string} ehsItemId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EhsV2Api
     */
    public getPublicEhsItemById(ehsItemId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EhsV2ApiFp(this.configuration).getPublicEhsItemById(ehsItemId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get number of signable EHS items on resource for user
     * @param {string} companyId 
     * @param {string} resourceId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EhsV2Api
     */
    public getUnsignedEhsItemsForUser(companyId: string, resourceId: string, authorization?: string, options?: AxiosRequestConfig) {
        return EhsV2ApiFp(this.configuration).getUnsignedEhsItemsForUser(companyId, resourceId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update EHS item info
     * @param {string} companyId 
     * @param {string} ehsItemId 
     * @param {boolean} completed 
     * @param {Array<ChecklistItemPutRequest>} checklistItemPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EhsV2Api
     */
    public updateEHSItemInfo(companyId: string, ehsItemId: string, completed: boolean, checklistItemPutRequest: Array<ChecklistItemPutRequest>, authorization?: string, options?: AxiosRequestConfig) {
        return EhsV2ApiFp(this.configuration).updateEHSItemInfo(companyId, ehsItemId, completed, checklistItemPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update EHS item
     * @param {string} companyId 
     * @param {string} ehsItemId 
     * @param {EhsItemPutRequest} ehsItemPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EhsV2Api
     */
    public updateEhsItem(companyId: string, ehsItemId: string, ehsItemPutRequest: EhsItemPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return EhsV2ApiFp(this.configuration).updateEhsItem(companyId, ehsItemId, ehsItemPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update EHS template
     * @param {string} companyId 
     * @param {string} ehsTemplateId 
     * @param {EhsTemplatePutRequest} ehsTemplatePutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EhsV2Api
     */
    public updateEhsTemplate(companyId: string, ehsTemplateId: string, ehsTemplatePutRequest: EhsTemplatePutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return EhsV2ApiFp(this.configuration).updateEhsTemplate(companyId, ehsTemplateId, ehsTemplatePutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
