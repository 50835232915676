/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { ProjectEconomy } from '../model';
// @ts-ignore
import { ProjectEconomyOverview } from '../model';
// @ts-ignore
import { ProjectEconomyPutRequest } from '../model';
/**
 * ProjectEconomyApi - axios parameter creator
 * @export
 */
export const ProjectEconomyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get economy details for project
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectEconomy: async (projectId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectEconomy', 'projectId', projectId)
            const localVarPath = `/project/{projectId}/economy`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get economy overview data for project
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectEconomyOverview: async (projectId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectEconomyOverview', 'projectId', projectId)
            const localVarPath = `/project/{projectId}/economy/overview`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update project economy
         * @param {string} projectId 
         * @param {ProjectEconomyPutRequest} projectEconomyPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectEconomy: async (projectId: string, projectEconomyPutRequest: ProjectEconomyPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateProjectEconomy', 'projectId', projectId)
            // verify required parameter 'projectEconomyPutRequest' is not null or undefined
            assertParamExists('updateProjectEconomy', 'projectEconomyPutRequest', projectEconomyPutRequest)
            const localVarPath = `/project/{projectId}/economy`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectEconomyPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectEconomyApi - functional programming interface
 * @export
 */
export const ProjectEconomyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectEconomyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get economy details for project
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectEconomy(projectId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectEconomy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectEconomy(projectId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get economy overview data for project
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectEconomyOverview(projectId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectEconomyOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectEconomyOverview(projectId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update project economy
         * @param {string} projectId 
         * @param {ProjectEconomyPutRequest} projectEconomyPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProjectEconomy(projectId: string, projectEconomyPutRequest: ProjectEconomyPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectEconomy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProjectEconomy(projectId, projectEconomyPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectEconomyApi - factory interface
 * @export
 */
export const ProjectEconomyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectEconomyApiFp(configuration)
    return {
        /**
         * 
         * @summary Get economy details for project
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectEconomy(projectId: string, authorization?: string, options?: any): AxiosPromise<ProjectEconomy> {
            return localVarFp.getProjectEconomy(projectId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get economy overview data for project
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectEconomyOverview(projectId: string, authorization?: string, options?: any): AxiosPromise<ProjectEconomyOverview> {
            return localVarFp.getProjectEconomyOverview(projectId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update project economy
         * @param {string} projectId 
         * @param {ProjectEconomyPutRequest} projectEconomyPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectEconomy(projectId: string, projectEconomyPutRequest: ProjectEconomyPutRequest, authorization?: string, options?: any): AxiosPromise<ProjectEconomy> {
            return localVarFp.updateProjectEconomy(projectId, projectEconomyPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectEconomyApi - object-oriented interface
 * @export
 * @class ProjectEconomyApi
 * @extends {BaseAPI}
 */
export class ProjectEconomyApi extends BaseAPI {
    /**
     * 
     * @summary Get economy details for project
     * @param {string} projectId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectEconomyApi
     */
    public getProjectEconomy(projectId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectEconomyApiFp(this.configuration).getProjectEconomy(projectId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get economy overview data for project
     * @param {string} projectId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectEconomyApi
     */
    public getProjectEconomyOverview(projectId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectEconomyApiFp(this.configuration).getProjectEconomyOverview(projectId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update project economy
     * @param {string} projectId 
     * @param {ProjectEconomyPutRequest} projectEconomyPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectEconomyApi
     */
    public updateProjectEconomy(projectId: string, projectEconomyPutRequest: ProjectEconomyPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectEconomyApiFp(this.configuration).updateProjectEconomy(projectId, projectEconomyPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
