import { AxiosError } from "axios";
import { CustomErrorResponseErrorCodeEnum as TimeKeepingErrorCode } from "../.generated/timekeepingModule";
import { CustomErrorResponseErrorCodeEnum as ApiErrorCode } from "../.generated/api";
import { t } from "i18next";

export type ApiError = {
  status?: number;
  serverError?: string;
  serverMessage?: string;
  message: string;
  code?: string;
  errorCode?: TimeKeepingErrorCode | ApiErrorCode;
};
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const handleAxiosError = (e: AxiosError<any>): Promise<ApiError> => {
  const data = e.response?.data;
  const errorCode = e.response?.data.errorCode;
  const message = errorCode
    ? getErrorCodeMessage(errorCode)
    : getLocalizedMessage(e);

  const code = e.response?.data.code;
  const status = e.response?.status;

  const error: ApiError = {
    status: status,
    message: message,
    code: code,
    errorCode: errorCode,
    serverError: data?.error,
    serverMessage: data?.message
  };
  return Promise.reject(error);
};

function getErrorCodeMessage(code: ApiErrorCode | TimeKeepingErrorCode) {
  switch (code) {
    case ApiErrorCode.CustomerConnectionExists:
      return t(
        "Kunden er lagt til på ett eller flere prosjekter, og kan derfor ikke slettes"
      );
    case ApiErrorCode.InvitationAlreadyExists:
      return t("Det finnes allerede en invitasjon for denne e-postadressen");
    case ApiErrorCode.LastProjectManager:
      return t(
        "Kan ikke fjerne brukeren da det minst må være en prosjektleder på prosjektet"
      );
    case ApiErrorCode.LastSystemAdmin:
      return t(
        "Kan ikke fjerne brukeren da det minst må være en systemadministrator i bedriften"
      );
    case ApiErrorCode.UserConnectionAlreadyExists:
      return t("Denne brukeren er allerede ansatt i en annen bedrift");
    case ApiErrorCode.IsDefaultProjectStatus:
      return t("Kan ikke slette status som er satt som standard");
    case ApiErrorCode.DuplicateProjectStatus:
      return t("Det finnes allerede en status med dette navnet");
    case ApiErrorCode.ChecklistRequired:
      return t(
        "Alle påkrevde sjekklister må være utført før oppgaven kan settes som fullført"
      );
    case "DuplicateProjectStatus":
      return t("Det finnes allerede et prosjekt med dette prosjektnummeret");
    case "DuplicateActivityNameOrNumber":
      return t(
        "Det finnes allerede en aktivitet med dette navnet eller nummeret"
      );
    case "DuplicateProjectNumber":
      return t("Det finnes allerede et prosjekt med dette nummeret");
    case "DuplicateEmployeeNumber":
      return t("Det finnes allerede en ansatt med dette nummeret");
    default:
      return t("En feil oppstod");
  }
}

function getLocalizedMessage(e: AxiosError): string {
  switch (e.response?.status) {
    case 401:
      if (e.config?.method === "get") {
        return t("Du har ikke tilgang til å se dette innholdet");
      } else {
        return t("Du har ikke tilgang til å utføre denne handlingen");
      }
    case 404:
      return t("Den forespurte ressursen finnes ikke");
    case 503:
      return t("Feil ved tilkobling til tjeneste");
    default:
      return t("En feil oppstod");
  }
}
