import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const timeUserApprovalSchema = z.object({
  startDate: z.string().optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/time/approval/user/$userId"
)({
  validateSearch: timeUserApprovalSchema
});
