import { createFileRoute } from "@tanstack/react-router";
import { useGetProjectPhasesByProjectId } from "../../../../../../service/api/PhasesApi";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/phases/"
)({
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: ({ context: { queryClient }, params: { projectId } }) => {
    return queryClient.fetchQuery({
      ...useGetProjectPhasesByProjectId.getOptions({ projectId })
    });
  }
});
