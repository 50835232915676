/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { InternalRoutine } from '../model';
// @ts-ignore
import { InternalRoutinePostRequest } from '../model';
// @ts-ignore
import { InternalRoutinePutRequest } from '../model';
/**
 * InternalRoutineControllerApi - axios parameter creator
 * @export
 */
export const InternalRoutineControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create internal routine
         * @param {string} companyId 
         * @param {InternalRoutinePostRequest} internalRoutinePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInternalRoutine: async (companyId: string, internalRoutinePostRequest: InternalRoutinePostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createInternalRoutine', 'companyId', companyId)
            // verify required parameter 'internalRoutinePostRequest' is not null or undefined
            assertParamExists('createInternalRoutine', 'internalRoutinePostRequest', internalRoutinePostRequest)
            const localVarPath = `/v1/company/{companyId}/internal-routine`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalRoutinePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete internal routine
         * @param {string} companyId 
         * @param {string} internalRoutineId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInternalRoutine: async (companyId: string, internalRoutineId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteInternalRoutine', 'companyId', companyId)
            // verify required parameter 'internalRoutineId' is not null or undefined
            assertParamExists('deleteInternalRoutine', 'internalRoutineId', internalRoutineId)
            const localVarPath = `/v1/company/{companyId}/internal-routine/{internalRoutineId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"internalRoutineId"}}`, encodeURIComponent(String(internalRoutineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all internal routines for company
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyInternalRoutines: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllCompanyInternalRoutines', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/internal-routine`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all internal routines for user
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInternalRoutinesForUser: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllInternalRoutinesForUser', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/internal-routine/user`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all unsigned internal routines for user
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUnsignedInternalRoutinesForUser: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllUnsignedInternalRoutinesForUser', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/internal-routine/user/unsigned`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get internal routine by specific id
         * @param {string} companyId 
         * @param {string} internalRoutineId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalRoutineById: async (companyId: string, internalRoutineId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getInternalRoutineById', 'companyId', companyId)
            // verify required parameter 'internalRoutineId' is not null or undefined
            assertParamExists('getInternalRoutineById', 'internalRoutineId', internalRoutineId)
            const localVarPath = `/v1/company/{companyId}/internal-routine/{internalRoutineId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"internalRoutineId"}}`, encodeURIComponent(String(internalRoutineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update internal routine
         * @param {string} companyId 
         * @param {string} internalRoutineId 
         * @param {InternalRoutinePutRequest} internalRoutinePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInternalRoutine: async (companyId: string, internalRoutineId: string, internalRoutinePutRequest: InternalRoutinePutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateInternalRoutine', 'companyId', companyId)
            // verify required parameter 'internalRoutineId' is not null or undefined
            assertParamExists('updateInternalRoutine', 'internalRoutineId', internalRoutineId)
            // verify required parameter 'internalRoutinePutRequest' is not null or undefined
            assertParamExists('updateInternalRoutine', 'internalRoutinePutRequest', internalRoutinePutRequest)
            const localVarPath = `/v1/company/{companyId}/internal-routine/{internalRoutineId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"internalRoutineId"}}`, encodeURIComponent(String(internalRoutineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalRoutinePutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalRoutineControllerApi - functional programming interface
 * @export
 */
export const InternalRoutineControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalRoutineControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create internal routine
         * @param {string} companyId 
         * @param {InternalRoutinePostRequest} internalRoutinePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInternalRoutine(companyId: string, internalRoutinePostRequest: InternalRoutinePostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalRoutine>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInternalRoutine(companyId, internalRoutinePostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete internal routine
         * @param {string} companyId 
         * @param {string} internalRoutineId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInternalRoutine(companyId: string, internalRoutineId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInternalRoutine(companyId, internalRoutineId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all internal routines for company
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCompanyInternalRoutines(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<InternalRoutine>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCompanyInternalRoutines(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all internal routines for user
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllInternalRoutinesForUser(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<InternalRoutine>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllInternalRoutinesForUser(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all unsigned internal routines for user
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUnsignedInternalRoutinesForUser(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InternalRoutine>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUnsignedInternalRoutinesForUser(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get internal routine by specific id
         * @param {string} companyId 
         * @param {string} internalRoutineId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInternalRoutineById(companyId: string, internalRoutineId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalRoutine>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInternalRoutineById(companyId, internalRoutineId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update internal routine
         * @param {string} companyId 
         * @param {string} internalRoutineId 
         * @param {InternalRoutinePutRequest} internalRoutinePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInternalRoutine(companyId: string, internalRoutineId: string, internalRoutinePutRequest: InternalRoutinePutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalRoutine>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInternalRoutine(companyId, internalRoutineId, internalRoutinePutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternalRoutineControllerApi - factory interface
 * @export
 */
export const InternalRoutineControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalRoutineControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create internal routine
         * @param {string} companyId 
         * @param {InternalRoutinePostRequest} internalRoutinePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInternalRoutine(companyId: string, internalRoutinePostRequest: InternalRoutinePostRequest, authorization?: string, options?: any): AxiosPromise<InternalRoutine> {
            return localVarFp.createInternalRoutine(companyId, internalRoutinePostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete internal routine
         * @param {string} companyId 
         * @param {string} internalRoutineId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInternalRoutine(companyId: string, internalRoutineId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteInternalRoutine(companyId, internalRoutineId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all internal routines for company
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyInternalRoutines(companyId: string, authorization?: string, options?: any): AxiosPromise<Set<InternalRoutine>> {
            return localVarFp.getAllCompanyInternalRoutines(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all internal routines for user
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInternalRoutinesForUser(companyId: string, authorization?: string, options?: any): AxiosPromise<Set<InternalRoutine>> {
            return localVarFp.getAllInternalRoutinesForUser(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all unsigned internal routines for user
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUnsignedInternalRoutinesForUser(companyId: string, authorization?: string, options?: any): AxiosPromise<Array<InternalRoutine>> {
            return localVarFp.getAllUnsignedInternalRoutinesForUser(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get internal routine by specific id
         * @param {string} companyId 
         * @param {string} internalRoutineId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalRoutineById(companyId: string, internalRoutineId: string, authorization?: string, options?: any): AxiosPromise<InternalRoutine> {
            return localVarFp.getInternalRoutineById(companyId, internalRoutineId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update internal routine
         * @param {string} companyId 
         * @param {string} internalRoutineId 
         * @param {InternalRoutinePutRequest} internalRoutinePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInternalRoutine(companyId: string, internalRoutineId: string, internalRoutinePutRequest: InternalRoutinePutRequest, authorization?: string, options?: any): AxiosPromise<InternalRoutine> {
            return localVarFp.updateInternalRoutine(companyId, internalRoutineId, internalRoutinePutRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalRoutineControllerApi - object-oriented interface
 * @export
 * @class InternalRoutineControllerApi
 * @extends {BaseAPI}
 */
export class InternalRoutineControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create internal routine
     * @param {string} companyId 
     * @param {InternalRoutinePostRequest} internalRoutinePostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutineControllerApi
     */
    public createInternalRoutine(companyId: string, internalRoutinePostRequest: InternalRoutinePostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return InternalRoutineControllerApiFp(this.configuration).createInternalRoutine(companyId, internalRoutinePostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete internal routine
     * @param {string} companyId 
     * @param {string} internalRoutineId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutineControllerApi
     */
    public deleteInternalRoutine(companyId: string, internalRoutineId: string, authorization?: string, options?: AxiosRequestConfig) {
        return InternalRoutineControllerApiFp(this.configuration).deleteInternalRoutine(companyId, internalRoutineId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all internal routines for company
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutineControllerApi
     */
    public getAllCompanyInternalRoutines(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return InternalRoutineControllerApiFp(this.configuration).getAllCompanyInternalRoutines(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all internal routines for user
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutineControllerApi
     */
    public getAllInternalRoutinesForUser(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return InternalRoutineControllerApiFp(this.configuration).getAllInternalRoutinesForUser(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all unsigned internal routines for user
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutineControllerApi
     */
    public getAllUnsignedInternalRoutinesForUser(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return InternalRoutineControllerApiFp(this.configuration).getAllUnsignedInternalRoutinesForUser(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get internal routine by specific id
     * @param {string} companyId 
     * @param {string} internalRoutineId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutineControllerApi
     */
    public getInternalRoutineById(companyId: string, internalRoutineId: string, authorization?: string, options?: AxiosRequestConfig) {
        return InternalRoutineControllerApiFp(this.configuration).getInternalRoutineById(companyId, internalRoutineId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update internal routine
     * @param {string} companyId 
     * @param {string} internalRoutineId 
     * @param {InternalRoutinePutRequest} internalRoutinePutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutineControllerApi
     */
    public updateInternalRoutine(companyId: string, internalRoutineId: string, internalRoutinePutRequest: InternalRoutinePutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return InternalRoutineControllerApiFp(this.configuration).updateInternalRoutine(companyId, internalRoutineId, internalRoutinePutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
