/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ProjectPostRequest
 */
export interface ProjectPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectPostRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPostRequest
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPostRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPostRequest
     */
    'parentProject'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPostRequest
     */
    'fromDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPostRequest
     */
    'toDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPostRequest
     */
    'externalReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPostRequest
     */
    'activityCreationStrategy': ProjectPostRequestActivityCreationStrategyEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectPostRequest
     */
    'salesOpportunityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPostRequest
     */
    'alreadyInvoiced'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPostRequest
     */
    'projectSeriesId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPostRequest
     */
    'estimatedHours'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPostRequest
     */
    'invoiceInfo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectPostRequest
     */
    'isHourly'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectPostRequest
     */
    'contractValue'?: string;
}

export const ProjectPostRequestActivityCreationStrategyEnum = {
    Hours: 'HOURS',
    Kilometers: 'KILOMETERS',
    HoursAndKilometers: 'HOURS_AND_KILOMETERS',
    None: 'NONE'
} as const;

export type ProjectPostRequestActivityCreationStrategyEnum = typeof ProjectPostRequestActivityCreationStrategyEnum[keyof typeof ProjectPostRequestActivityCreationStrategyEnum];


