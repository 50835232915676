import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { salesDetailsTabs } from "../../../../../router/Routes";
import { useGetSalesOpportunityById } from "../../../../../service/customerModule/SalesOpportunityApi";

const salesDetailsTabSchema = z.object({
  tab: salesDetailsTabs.catch("overview").optional(),
  selectedOffer: z.string().optional(),
  selectedInspection: z.string().optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/sales/$salesOpportunityId/"
)({
  validateSearch: salesDetailsTabSchema,
  loader: ({
    context: { queryClient, companyId },
    params: { salesOpportunityId }
  }) => {
    return queryClient.fetchQuery({
      ...useGetSalesOpportunityById.getOptions({
        salesOpportunityId,
        companyId
      })
    });
  }
});
