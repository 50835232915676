import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { orderTabs } from "../../../../router/Routes";

const orderTabSchema = z.object({
  tab: orderTabs.catch("overview").optional(),
  deviationStatus: z.enum(["open", "resolved"]).optional()
});

export const Route = createFileRoute("/_protected/dashboard/orders/")({
  validateSearch: orderTabSchema
});
