/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { Location } from '../model';
// @ts-ignore
import { LocationPostRequest } from '../model';
// @ts-ignore
import { LocationPutRequest } from '../model';
/**
 * LocationApi - axios parameter creator
 * @export
 */
export const LocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Post location for a Project or Task resource
         * @param {LocationPostRequest} locationPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocation: async (locationPostRequest: LocationPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationPostRequest' is not null or undefined
            assertParamExists('createLocation', 'locationPostRequest', locationPostRequest)
            const localVarPath = `/v1/location`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete location for project
         * @param {string} projectId UUID of the location\&#39;s project
         * @param {string} locationId UUID of the location to delete
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocationForProject: async (projectId: string, locationId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteLocationForProject', 'projectId', projectId)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('deleteLocationForProject', 'locationId', locationId)
            const localVarPath = `/v1/project/{projectId}/location/{locationId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete location for task
         * @param {string} taskId UUID of the location\&#39;s task
         * @param {string} locationId UUID of the location to delete
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocationForTask: async (taskId: string, locationId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('deleteLocationForTask', 'taskId', taskId)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('deleteLocationForTask', 'locationId', locationId)
            const localVarPath = `/v1/task/{taskId}/location/{locationId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)))
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get location for resource
         * @param {string} resourceId Id of the resource the location is connected to
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] Location active status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationByResourceId: async (resourceId: string, authorization?: string, status?: 'active' | 'deleted', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getLocationByResourceId', 'resourceId', resourceId)
            const localVarPath = `/v1/location`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resourceId !== undefined) {
                localVarQueryParameter['resourceId'] = resourceId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update location
         * @param {LocationPutRequest} locationPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation: async (locationPutRequest: LocationPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationPutRequest' is not null or undefined
            assertParamExists('updateLocation', 'locationPutRequest', locationPutRequest)
            const localVarPath = `/v1/location`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationApi - functional programming interface
 * @export
 */
export const LocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Post location for a Project or Task resource
         * @param {LocationPostRequest} locationPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLocation(locationPostRequest: LocationPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLocation(locationPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete location for project
         * @param {string} projectId UUID of the location\&#39;s project
         * @param {string} locationId UUID of the location to delete
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLocationForProject(projectId: string, locationId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLocationForProject(projectId, locationId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete location for task
         * @param {string} taskId UUID of the location\&#39;s task
         * @param {string} locationId UUID of the location to delete
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLocationForTask(taskId: string, locationId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLocationForTask(taskId, locationId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get location for resource
         * @param {string} resourceId Id of the resource the location is connected to
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] Location active status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationByResourceId(resourceId: string, authorization?: string, status?: 'active' | 'deleted', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Location>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationByResourceId(resourceId, authorization, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update location
         * @param {LocationPutRequest} locationPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLocation(locationPutRequest: LocationPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLocation(locationPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocationApi - factory interface
 * @export
 */
export const LocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationApiFp(configuration)
    return {
        /**
         * 
         * @summary Post location for a Project or Task resource
         * @param {LocationPostRequest} locationPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocation(locationPostRequest: LocationPostRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.createLocation(locationPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete location for project
         * @param {string} projectId UUID of the location\&#39;s project
         * @param {string} locationId UUID of the location to delete
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocationForProject(projectId: string, locationId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteLocationForProject(projectId, locationId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete location for task
         * @param {string} taskId UUID of the location\&#39;s task
         * @param {string} locationId UUID of the location to delete
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocationForTask(taskId: string, locationId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteLocationForTask(taskId, locationId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get location for resource
         * @param {string} resourceId Id of the resource the location is connected to
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] Location active status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationByResourceId(resourceId: string, authorization?: string, status?: 'active' | 'deleted', options?: any): AxiosPromise<Location> {
            return localVarFp.getLocationByResourceId(resourceId, authorization, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update location
         * @param {LocationPutRequest} locationPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation(locationPutRequest: LocationPutRequest, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateLocation(locationPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationApi - object-oriented interface
 * @export
 * @class LocationApi
 * @extends {BaseAPI}
 */
export class LocationApi extends BaseAPI {
    /**
     * 
     * @summary Post location for a Project or Task resource
     * @param {LocationPostRequest} locationPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public createLocation(locationPostRequest: LocationPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).createLocation(locationPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete location for project
     * @param {string} projectId UUID of the location\&#39;s project
     * @param {string} locationId UUID of the location to delete
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public deleteLocationForProject(projectId: string, locationId: string, authorization?: string, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).deleteLocationForProject(projectId, locationId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete location for task
     * @param {string} taskId UUID of the location\&#39;s task
     * @param {string} locationId UUID of the location to delete
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public deleteLocationForTask(taskId: string, locationId: string, authorization?: string, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).deleteLocationForTask(taskId, locationId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get location for resource
     * @param {string} resourceId Id of the resource the location is connected to
     * @param {string} [authorization] 
     * @param {'active' | 'deleted'} [status] Location active status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public getLocationByResourceId(resourceId: string, authorization?: string, status?: 'active' | 'deleted', options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).getLocationByResourceId(resourceId, authorization, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update location
     * @param {LocationPutRequest} locationPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public updateLocation(locationPutRequest: LocationPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).updateLocation(locationPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
