import React from "react";
import { Table as RadixTable } from "@radix-ui/themes";
import classNames from "classnames";

export default function Table({ ...props }: RadixTable.RootProps) {
  return (
    <RadixTable.Root
      variant={"surface"}
      className={classNames(props.className, {
        "no-bottom-border-last-row [&_thead]:bg-radix-gray-a2":
          props.variant === "ghost"
      })}
      {...props}
    ></RadixTable.Root>
  );
}
