import React, { useEffect } from "react";
import t, { ToastBar, Toaster, useToasterStore } from "react-hot-toast";
import { Callout } from "@radix-ui/themes";
import RadixIcon from "../ds/RadixIcon";

export default function CustomToast() {
  const { toasts } = useToasterStore();

  useEffect(() => {
    toasts
      .filter((tt) => tt.visible)
      .filter((_, i) => i >= 1)
      .forEach((tt) => t.dismiss(tt.id));
  }, [toasts]);

  return (
    <Toaster position={"bottom-center"}>
      {(toast) => {
        const isSuccess = toast.type === "success";
        return (
          <ToastBar
            position={"bottom-center"}
            toast={toast}
            style={{
              backgroundColor: "var(--color-background)",
              margin: "0px",
              padding: "0px"
            }}
          >
            {() => (
              <Callout.Root color={isSuccess ? "green" : "yellow"}>
                <Callout.Icon>
                  <RadixIcon icon={isSuccess ? "check_circle" : "error"} />
                </Callout.Icon>
                <Callout.Text>{toast.message as string}</Callout.Text>
              </Callout.Root>
            )}
          </ToastBar>
        );
      }}
    </Toaster>
  );
}
