import React, { AnchorHTMLAttributes, forwardRef } from "react";
import ButtonNew from "../../../../../../../../components/ds/ButtonNew";
import { createLink, Link, useLocation } from "@tanstack/react-router";

const InnerNavButton = forwardRef<
  HTMLAnchorElement,
  AnchorHTMLAttributes<HTMLAnchorElement>
>((props, ref) => {
  const location = useLocation();

  return (
    <ButtonNew
      asChild
      className={"w-full h-[32px] m-0 box-border justify-start"}
      variant={location.href === props.href ? "solid" : "ghost"}
    >
      <Link ref={ref} {...props} />
    </ButtonNew>
  );
});

export const NavButton = createLink(InnerNavButton);
