import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const projectApprovalSceham = z.object({
  startDate: z.string().optional(),
  initialPeriod: z.enum(["week", "month"] as const).catch("week")
});

export const Route = createFileRoute(
  "/_protected/dashboard/time/approval/project/$projectId"
)({
  validateSearch: projectApprovalSceham
});
