import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { projectTabs } from "../../../../router/Routes";

const projectTabSchema = z.object({
  tab: projectTabs.catch("projectOverview").optional(),
  deviationStatus: z.enum(["open", "resolved"]).optional()
});

export const Route = createFileRoute("/_protected/dashboard/project/")({
  validateSearch: projectTabSchema
});
