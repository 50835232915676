import { createFileRoute } from "@tanstack/react-router";
import { useGetCustomerById } from "../../../../../service/customerModule/CustomerApiV3";

export const Route = createFileRoute(
  "/_protected/dashboard/customers/$customerId/edit"
)({
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: ({ context: { queryClient, companyId }, params: { customerId } }) => {
    return queryClient.fetchQuery({
      ...useGetCustomerById.getOptions({ customerId, companyId })
    });
  }
});
