import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const orderApprovalSchema = z.object({
  startDate: z.string().optional(),
  initialPeriod: z.enum(["week", "month"] as const).catch("week")
});

export const Route = createFileRoute(
  "/_protected/dashboard/time/approval/order/$orderId"
)({
  validateSearch: orderApprovalSchema
});
