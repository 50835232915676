import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const addProductsToProjectSchema = z.object({
  taskId: z.string().optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/products/add"
)({
  validateSearch: addProductsToProjectSchema
});
