import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const createProjectSchema = z.object({
  parentProjectId: z.string().optional(),
  customerId: z.string().optional(),
  startDate: z.string().optional()
});

export const Route = createFileRoute("/_protected/dashboard/project/new/")({
  validateSearch: createProjectSchema
});
