import { createFileRoute } from "@tanstack/react-router";
import { useGetCustomerById } from "../../../../../../service/customerModule/CustomerApiV3";

export const Route = createFileRoute(
  "/_protected/dashboard/customers/$customerId/order/new"
)({
  loader: async ({
    context: { queryClient, companyId },
    params: { customerId }
  }) => {
    return await queryClient.fetchQuery(
      useGetCustomerById.getOptions({ customerId, companyId })
    );
  }
});
