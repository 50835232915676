import { createFileRoute } from "@tanstack/react-router";
import { useGetCustomerPublicInfoById } from "../../../service/customerModule/CustomerApiV3";

export const Route = createFileRoute("/guest/$customerId/")({
  loader: ({ context: { queryClient }, params: { customerId } }) => {
    return queryClient.fetchQuery({
      ...useGetCustomerPublicInfoById.getOptions({ customerId })
    });
  }
});
