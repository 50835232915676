import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { inspectionType } from "../../../../../../router/Routes";
import { useGetInspectionById } from "../../../../../../service/customerModule/InspectionApi";
import { useGetCustomerById } from "../../../../../../service/customerModule/CustomerApiV3";

const updateInspectionSchema = z.object({
  inspectionType: inspectionType,
  salesOpportunityId: z.string().optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/sales/inspection/$inspectionId/update"
)({
  validateSearch: updateInspectionSchema,
  loader: async ({
    context: { queryClient, companyId },
    params: { inspectionId }
  }) => {
    const inspection = await queryClient.fetchQuery({
      ...useGetInspectionById.getOptions({ companyId, inspectionId })
    });

    const customer = await queryClient.ensureQueryData({
      ...useGetCustomerById.getOptions({
        companyId,
        customerId: inspection.customer.id
      })
    });

    return {
      inspection,
      customer
    };
  }
});
