import environment from "../../environment";
import axios from "axios";
import { errorInterceptor, requestInterceptor } from "../Interceptors";

export const apiInstance = axios.create({
  baseURL: environment.skwApiNewBaseUrl
});

apiInstance.interceptors.request.use(requestInterceptor);
apiInstance.interceptors.response.use((res) => res, errorInterceptor);
