import { createFileRoute } from "@tanstack/react-router";
import { ehsResourceType } from "../../../../../router/Routes";
import { z } from "zod";
import { useGetEhsTemplateById } from "../../../../../service/api/EhsApi";

const createEhsSchema = z.object({
  resourceType: ehsResourceType,
  resourceId: z.string()
});

export const Route = createFileRoute(
  "/_protected/dashboard/ehs/$templateId/new"
)({
  validateSearch: createEhsSchema,
  loader: async ({
    context: { queryClient, companyId },
    params: { templateId }
  }) => {
    return await queryClient.fetchQuery({
      ...useGetEhsTemplateById.getOptions({ companyId, templateId })
    });
  }
});
