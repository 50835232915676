import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { projectReportTabs } from "../../../../../router/Routes";

const projectReportSchema = z.object({
  fromDate: z.string(),
  toDate: z.string(),
  tab: projectReportTabs.catch("hours").optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/report"
)({
  validateSearch: projectReportSchema
});
