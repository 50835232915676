import {
  EmergencyContactPostRequest,
  UserCompanyPutRequest,
  UserV2Api
} from "../../.generated/api";
import environment from "../../environment";
import { apiInstance } from "./ApiHttpBase";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { createQuery } from "react-query-kit";

const userApi = new UserV2Api(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export const useGetUsersByCompanyId = createQuery({
  queryKey: ["useGetUsersByCompanyId"],
  fetcher: async (variables: { companyId: string }) => {
    return getUsersInCompany(variables.companyId);
  }
});

export const userV2Queries = (companyId: string) =>
  createQueryKeys("userV2", {
    getByCompanyId: () => ({
      queryKey: ["getByCompanyId", companyId],
      queryFn: () => getUsersInCompany(companyId)
    })
  });

export async function getUsersInCompany(
  companyId: string,
  onlyActive: boolean = true
) {
  const { data } = await userApi.getUsersInCompany(companyId, onlyActive);
  return data;
}

export async function updateUserCompanyInfo(
  companyId: string,
  userId: string,
  request: UserCompanyPutRequest
) {
  await userApi.updateUserCompanyInfo(companyId, userId, request);
}

export async function addUserEmergencyContact(
  companyId: string,
  userId: string,
  request: EmergencyContactPostRequest
) {
  await userApi.addUserEmergencyContact(companyId, userId, request);
}
