import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const createServiceContractSchema = z.object({
  facilityId: z.string().optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/service-contract/new"
)({
  validateSearch: createServiceContractSchema
});
