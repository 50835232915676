import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { timeTabs } from "../../../../router/Routes";

const timeSchema = z.object({
  tab: timeTabs.optional().catch("forApproval")
});

export const Route = createFileRoute("/_protected/dashboard/time/")({
  validateSearch: timeSchema
});
