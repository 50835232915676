import { createFileRoute, redirect } from "@tanstack/react-router";
import { getCurrentUser } from "../firebase";

export const Route = createFileRoute("/_unauthenticated")({
  beforeLoad: async () => {
    const user = await getCurrentUser();
    if (user) {
      throw redirect({
        to: "/dashboard/overview"
      });
    }
  }
});
