/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { PaginatedResponseServiceContract } from '../model';
// @ts-ignore
import { ServiceContract } from '../model';
// @ts-ignore
import { ServiceContractPostRequest } from '../model';
// @ts-ignore
import { ServiceContractPutRequest } from '../model';
/**
 * ServiceContractApi - axios parameter creator
 * @export
 */
export const ServiceContractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive specific service contract
         * @param {string} serviceContractId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveServiceContract: async (serviceContractId: string, companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceContractId' is not null or undefined
            assertParamExists('archiveServiceContract', 'serviceContractId', serviceContractId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('archiveServiceContract', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/serviceContract/{serviceContractId}/archive`
                .replace(`{${"serviceContractId"}}`, encodeURIComponent(String(serviceContractId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all service contracts on a company
         * @param {string} companyId 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Number' | 'Name'} [sortField] 
         * @param {boolean} [archived] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllServiceContractsOnCompany: async (companyId: string, query?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Number' | 'Name', archived?: boolean, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllServiceContractsOnCompany', 'companyId', companyId)
            const localVarPath = `/v1/serviceContract/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all service contracts on facility, paginated
         * @param {string} facilityId 
         * @param {string} companyId 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Number' | 'Name'} [sortField] 
         * @param {boolean} [archived] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllServiceContractsOnFacility: async (facilityId: string, companyId: string, query?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Number' | 'Name', archived?: boolean, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('getAllServiceContractsOnFacility', 'facilityId', facilityId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllServiceContractsOnFacility', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/serviceContract/facility/{facilityId}`
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get specific service contract
         * @param {string} serviceContractId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceContract: async (serviceContractId: string, companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceContractId' is not null or undefined
            assertParamExists('getServiceContract', 'serviceContractId', serviceContractId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getServiceContract', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/serviceContract/{serviceContractId}`
                .replace(`{${"serviceContractId"}}`, encodeURIComponent(String(serviceContractId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new service contract
         * @param {ServiceContractPostRequest} serviceContractPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postServiceContract: async (serviceContractPostRequest: ServiceContractPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceContractPostRequest' is not null or undefined
            assertParamExists('postServiceContract', 'serviceContractPostRequest', serviceContractPostRequest)
            const localVarPath = `/v1/serviceContract/post`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceContractPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restore specific service contract
         * @param {string} serviceContractId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreServiceContract: async (serviceContractId: string, companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceContractId' is not null or undefined
            assertParamExists('restoreServiceContract', 'serviceContractId', serviceContractId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('restoreServiceContract', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/serviceContract/{serviceContractId}/restore`
                .replace(`{${"serviceContractId"}}`, encodeURIComponent(String(serviceContractId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update specific service contract
         * @param {ServiceContractPutRequest} serviceContractPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceContract: async (serviceContractPutRequest: ServiceContractPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceContractPutRequest' is not null or undefined
            assertParamExists('updateServiceContract', 'serviceContractPutRequest', serviceContractPutRequest)
            const localVarPath = `/v1/serviceContract/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceContractPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceContractApi - functional programming interface
 * @export
 */
export const ServiceContractApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceContractApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive specific service contract
         * @param {string} serviceContractId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveServiceContract(serviceContractId: string, companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceContract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveServiceContract(serviceContractId, companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all service contracts on a company
         * @param {string} companyId 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Number' | 'Name'} [sortField] 
         * @param {boolean} [archived] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllServiceContractsOnCompany(companyId: string, query?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Number' | 'Name', archived?: boolean, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseServiceContract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllServiceContractsOnCompany(companyId, query, page, sortDirection, sortField, archived, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all service contracts on facility, paginated
         * @param {string} facilityId 
         * @param {string} companyId 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Number' | 'Name'} [sortField] 
         * @param {boolean} [archived] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllServiceContractsOnFacility(facilityId: string, companyId: string, query?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Number' | 'Name', archived?: boolean, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseServiceContract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllServiceContractsOnFacility(facilityId, companyId, query, page, sortDirection, sortField, archived, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get specific service contract
         * @param {string} serviceContractId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceContract(serviceContractId: string, companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceContract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceContract(serviceContractId, companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new service contract
         * @param {ServiceContractPostRequest} serviceContractPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postServiceContract(serviceContractPostRequest: ServiceContractPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceContract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postServiceContract(serviceContractPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Restore specific service contract
         * @param {string} serviceContractId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreServiceContract(serviceContractId: string, companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceContract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreServiceContract(serviceContractId, companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update specific service contract
         * @param {ServiceContractPutRequest} serviceContractPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateServiceContract(serviceContractPutRequest: ServiceContractPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceContract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateServiceContract(serviceContractPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ServiceContractApi - factory interface
 * @export
 */
export const ServiceContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceContractApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive specific service contract
         * @param {string} serviceContractId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveServiceContract(serviceContractId: string, companyId: string, authorization?: string, options?: any): AxiosPromise<ServiceContract> {
            return localVarFp.archiveServiceContract(serviceContractId, companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all service contracts on a company
         * @param {string} companyId 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Number' | 'Name'} [sortField] 
         * @param {boolean} [archived] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllServiceContractsOnCompany(companyId: string, query?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Number' | 'Name', archived?: boolean, authorization?: string, options?: any): AxiosPromise<PaginatedResponseServiceContract> {
            return localVarFp.getAllServiceContractsOnCompany(companyId, query, page, sortDirection, sortField, archived, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all service contracts on facility, paginated
         * @param {string} facilityId 
         * @param {string} companyId 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Number' | 'Name'} [sortField] 
         * @param {boolean} [archived] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllServiceContractsOnFacility(facilityId: string, companyId: string, query?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Number' | 'Name', archived?: boolean, authorization?: string, options?: any): AxiosPromise<PaginatedResponseServiceContract> {
            return localVarFp.getAllServiceContractsOnFacility(facilityId, companyId, query, page, sortDirection, sortField, archived, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get specific service contract
         * @param {string} serviceContractId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceContract(serviceContractId: string, companyId: string, authorization?: string, options?: any): AxiosPromise<ServiceContract> {
            return localVarFp.getServiceContract(serviceContractId, companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new service contract
         * @param {ServiceContractPostRequest} serviceContractPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postServiceContract(serviceContractPostRequest: ServiceContractPostRequest, authorization?: string, options?: any): AxiosPromise<ServiceContract> {
            return localVarFp.postServiceContract(serviceContractPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restore specific service contract
         * @param {string} serviceContractId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreServiceContract(serviceContractId: string, companyId: string, authorization?: string, options?: any): AxiosPromise<ServiceContract> {
            return localVarFp.restoreServiceContract(serviceContractId, companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update specific service contract
         * @param {ServiceContractPutRequest} serviceContractPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceContract(serviceContractPutRequest: ServiceContractPutRequest, authorization?: string, options?: any): AxiosPromise<ServiceContract> {
            return localVarFp.updateServiceContract(serviceContractPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServiceContractApi - object-oriented interface
 * @export
 * @class ServiceContractApi
 * @extends {BaseAPI}
 */
export class ServiceContractApi extends BaseAPI {
    /**
     * 
     * @summary Archive specific service contract
     * @param {string} serviceContractId 
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceContractApi
     */
    public archiveServiceContract(serviceContractId: string, companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ServiceContractApiFp(this.configuration).archiveServiceContract(serviceContractId, companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all service contracts on a company
     * @param {string} companyId 
     * @param {string} [query] 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'Number' | 'Name'} [sortField] 
     * @param {boolean} [archived] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceContractApi
     */
    public getAllServiceContractsOnCompany(companyId: string, query?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Number' | 'Name', archived?: boolean, authorization?: string, options?: AxiosRequestConfig) {
        return ServiceContractApiFp(this.configuration).getAllServiceContractsOnCompany(companyId, query, page, sortDirection, sortField, archived, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all service contracts on facility, paginated
     * @param {string} facilityId 
     * @param {string} companyId 
     * @param {string} [query] 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'Number' | 'Name'} [sortField] 
     * @param {boolean} [archived] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceContractApi
     */
    public getAllServiceContractsOnFacility(facilityId: string, companyId: string, query?: string, page?: number, sortDirection?: 'ASC' | 'DESC', sortField?: 'Number' | 'Name', archived?: boolean, authorization?: string, options?: AxiosRequestConfig) {
        return ServiceContractApiFp(this.configuration).getAllServiceContractsOnFacility(facilityId, companyId, query, page, sortDirection, sortField, archived, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get specific service contract
     * @param {string} serviceContractId 
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceContractApi
     */
    public getServiceContract(serviceContractId: string, companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ServiceContractApiFp(this.configuration).getServiceContract(serviceContractId, companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new service contract
     * @param {ServiceContractPostRequest} serviceContractPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceContractApi
     */
    public postServiceContract(serviceContractPostRequest: ServiceContractPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ServiceContractApiFp(this.configuration).postServiceContract(serviceContractPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restore specific service contract
     * @param {string} serviceContractId 
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceContractApi
     */
    public restoreServiceContract(serviceContractId: string, companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ServiceContractApiFp(this.configuration).restoreServiceContract(serviceContractId, companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update specific service contract
     * @param {ServiceContractPutRequest} serviceContractPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceContractApi
     */
    public updateServiceContract(serviceContractPutRequest: ServiceContractPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ServiceContractApiFp(this.configuration).updateServiceContract(serviceContractPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
