import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  CustomerPostRequest,
  CustomerPutRequest,
  CustomerTypeEnum,
  CustomerV3Api
} from "../../.generated/customerModule";
import environment from "../../environment";
import { customerInstance } from "./CustomerHttpBase";
import { createQuery } from "react-query-kit";

type CustomerSortField =
  | "CustomerNumber"
  | "Type"
  | "CompanyName"
  | "OrgNumber"
  | "ExternalReference";

type SortDirection = "ASC" | "DESC";

type CustomerType = "Private" | "Corporate";

const customerV3Api = new CustomerV3Api(
  undefined,
  environment.customerBaseUrl,
  customerInstance
);

export const useGetCustomerById = createQuery({
  queryKey: ["getCustomerById"],
  fetcher: async (variables: { customerId: string; companyId: string }) => {
    const response = await customerV3Api.getCustomerByIdV3(
      variables.customerId,
      variables.companyId
    );
    return response.data;
  }
});

export const useGetCustomerByResourceId = createQuery({
  queryKey: ["getCustomerByResourceId"],
  fetcher: async (variables: { resourceId: string; companyId: string }) => {
    const response = await customerV3Api.getCustomerByResourceIdV3(
      variables.companyId,
      variables.resourceId
    );

    if (response.status === 200) {
      return response.data;
    }
  }
});

export const useGetCustomersByCompanyId = createQuery({
  queryKey: ["getCustomersByCompanyId"],
  fetcher: async (variables: {
    companyId: string;
    sortField?: CustomerSortField;
    query?: string;
    page?: number;
    sortDirection?: SortDirection;
    type?: CustomerType;
  }) => {
    const response = await customerV3Api.getCustomersOnCompanyV3(
      variables.companyId,
      variables.sortField ?? "CustomerNumber",
      undefined,
      variables.query,
      variables.page,
      variables.sortDirection,
      variables.type
    );
    return response.data;
  }
});

export const useGetCustomerIdByResourceId = createQuery({
  queryKey: ["getCustomerIdByResourceId"],
  fetcher: async (variables: { resourceId: string }) => {
    const response = await customerV3Api.getCustomerIdByResourceIdV3(
      variables.resourceId
    );
    return response.data;
  }
});

export const useGetCustomerPublicInfoById = createQuery({
  queryKey: ["getPublicCustomerById"],
  fetcher: async (variables: { customerId: string }) => {
    const response = await customerV3Api.getCustomerPublicInfoById(
      variables.customerId
    );
    return response.data;
  }
});

export const customerQueries = createQueryKeys("customer", {
  getById: (companyId: string, customerId: string) => ({
    queryKey: [companyId, customerId],
    queryFn: async () => {
      const response = await customerV3Api.getCustomerByIdV3(
        customerId,
        companyId
      );
      return response.data;
    }
  }),
  getByCompanyId: (
    companyId: string,
    sortField:
      | "CustomerNumber"
      | "Type"
      | "CompanyName"
      | "OrgNumber"
      | "ExternalReference",
    sortDirection?: "ASC" | "DESC",
    searchQuery?: string,
    page?: number,
    type?: CustomerTypeEnum
  ) => ({
    queryKey: [companyId, sortField, sortDirection, searchQuery, page, type],
    queryFn: async () => {
      const response = await customerV3Api.getCustomersOnCompanyV3(
        companyId,
        sortField,
        undefined,
        searchQuery,
        page,
        sortDirection,
        type
      );
      return response.data;
    }
  }),
  getByResourceId: (companyId: string, resourceId: string) => ({
    queryKey: [companyId, resourceId],
    queryFn: async () => {
      const response = await customerV3Api.getCustomerByResourceIdV3(
        companyId,
        resourceId
      );
      return response.data;
    }
  })
});

export async function updateCustomerV3(
  companyId: string,
  customerId: string,
  request: CustomerPutRequest
) {
  const response = await customerV3Api.updateCustomerV3(
    companyId,
    customerId,
    request
  );
  return response.data;
}

export async function updateCustomerPrimaryAddress(
  companyId: string,
  customerId: string,
  addressId: string
) {
  const response = await customerV3Api.updateCustomerPrimaryAddressV3(
    companyId,
    customerId,
    addressId
  );
  return response.data;
}

export async function updateCustomerPrimaryContact(
  companyId: string,
  customerId: string,
  contactId: string
) {
  const response = await customerV3Api.updateCustomerPrimaryContactV3(
    companyId,
    customerId,
    contactId
  );
  return response.data;
}

export async function createCustomerV3(
  companyId: string,
  request: CustomerPostRequest
) {
  const response = await customerV3Api.createCustomerV3(companyId, request);
  return response.data;
}

export async function deleteCustomerV3(companyId: string, customerId: string) {
  await customerV3Api.deleteCustomerV3(companyId, customerId);
}
