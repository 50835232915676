import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { productTabs } from "../../../../router/Routes";

const productTabSchema = z.object({
  tab: productTabs.catch("products").optional()
});

export const Route = createFileRoute("/_protected/dashboard/products/")({
  validateSearch: productTabSchema
});
