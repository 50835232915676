export const firebaseConfigDev = {
  apiKey: "AIzaSyCm48x1THkp_QdLmJi-j7vodetdSAjWEmg",
  authDomain: "acloud-development.firebaseapp.com",
  projectId: "acloud-development",
  storageBucket: "acloud-development.appspot.com",
  messagingSenderId: "47945294226",
  appId: "1:47945294226:web:e2b61a8c6c23586c16214b",
  measurementId: "G-2SQ5970239"
};

export const firebaseConfigStaging = {
  apiKey: "AIzaSyC3Mtz1kSU3Q3aPmkRWozZw_YFlr1tAPyM",
  authDomain: "skyworker-staging.firebaseapp.com",
  projectId: "skyworker-staging",
  storageBucket: "skyworker-staging.appspot.com",
  messagingSenderId: "940054511942",
  appId: "1:940054511942:web:b8d3731c202dc5ab3ca547",
  measurementId: "G-R5EVW33V14"
};

export const firebaseConfigProd = {
  apiKey: "AIzaSyDOe7fn1x4-_gj1lFihYeCF-L9GkfJh0yk",
  authDomain: "skyworker-prod.firebaseapp.com",
  projectId: "skyworker-prod",
  storageBucket: "skyworker-prod.appspot.com",
  messagingSenderId: "271298839971",
  appId: "1:271298839971:web:1da96e555150a386c28616",
  measurementId: "G-95C9QTKKMZ"
};
