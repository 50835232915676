import environment from "../../environment";
import { createQuery } from "react-query-kit";
import { apiInstance } from "./ApiHttpBase";
import {
  PlannedInvoiceApi,
  PlannedInvoicePostRequest
} from "../../.generated/api";

const plannedInvoiceApi = new PlannedInvoiceApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export const useGetPlannedInvoicesByProjectId = createQuery({
  queryKey: ["getPlannedInvoicesByProjectId"],
  fetcher: async (variables: { projectId: string }) => {
    const response = await plannedInvoiceApi.getPlannedInvoicesOnProject(
      variables.projectId
    );
    return response.data;
  }
});

export async function createPlannedInvoice(
  projectId: string,
  request: PlannedInvoicePostRequest
) {
  const response = await plannedInvoiceApi.createPlannedInvoice(
    projectId,
    request
  );
  return response.data;
}

export async function deletePlannedInvoice(
  projectId: string,
  invoiceId: string
) {
  await plannedInvoiceApi.deletePlannedInvoice(projectId, invoiceId);
}
