import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { resourceTabs } from "../../../../router/Routes";

const employeeSchema = z.object({
  tab: resourceTabs.catch("users").optional()
});

export const Route = createFileRoute("/_protected/dashboard/employees/")({
  validateSearch: employeeSchema
});
