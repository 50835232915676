import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { orderDetailsGuestTabs } from "../../../router/Routes";
import { useGetPublicOrderById } from "../../../service/api/OrderApi";

const orderDetailsGuestTabSchema = z.object({
  tab: orderDetailsGuestTabs.default("documentation").optional(),
  checklistId: z.string().optional(),
  deviationId: z.string().optional()
});

export const Route = createFileRoute("/guest/order/$orderId")({
  validateSearch: orderDetailsGuestTabSchema,
  loader: ({ context: { queryClient }, params: { orderId } }) => {
    return queryClient.fetchQuery({
      ...useGetPublicOrderById.getOptions({ orderId })
    });
  }
});
