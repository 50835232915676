/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { Invoice } from '../model';
// @ts-ignore
import { InvoicePostRequest } from '../model';
// @ts-ignore
import { PaginatedResponseInvoice } from '../model';
/**
 * InvoiceApi - axios parameter creator
 * @export
 */
export const InvoiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new invoice
         * @param {string} companyId 
         * @param {InvoicePostRequest} invoicePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoice: async (companyId: string, invoicePostRequest: InvoicePostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createInvoice', 'companyId', companyId)
            // verify required parameter 'invoicePostRequest' is not null or undefined
            assertParamExists('createInvoice', 'invoicePostRequest', invoicePostRequest)
            const localVarPath = `/v1/company/{companyId}/invoice`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoicePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete invoice
         * @param {string} companyId 
         * @param {string} invoiceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvoice: async (companyId: string, invoiceId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteInvoice', 'companyId', companyId)
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('deleteInvoice', 'invoiceId', invoiceId)
            const localVarPath = `/v1/company/{companyId}/invoice/{invoiceId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get invoice by ID
         * @param {string} companyId 
         * @param {string} invoiceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceById: async (companyId: string, invoiceId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getInvoiceById', 'companyId', companyId)
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('getInvoiceById', 'invoiceId', invoiceId)
            const localVarPath = `/v1/company/{companyId}/invoice/{invoiceId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get invoices by order ID
         * @param {string} companyId 
         * @param {string} orderId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesByOrderId: async (companyId: string, orderId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getInvoicesByOrderId', 'companyId', companyId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getInvoicesByOrderId', 'orderId', orderId)
            const localVarPath = `/v1/company/{companyId}/order/{orderId}/invoice`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get invoices by project ID
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {'InvoiceNumber' | 'Title' | 'DeliveryDate' | 'DueDate'} sortField 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [query] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesByProjectId: async (companyId: string, projectId: string, sortField: 'InvoiceNumber' | 'Title' | 'DeliveryDate' | 'DueDate', page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getInvoicesByProjectId', 'companyId', companyId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getInvoicesByProjectId', 'projectId', projectId)
            // verify required parameter 'sortField' is not null or undefined
            assertParamExists('getInvoicesByProjectId', 'sortField', sortField)
            const localVarPath = `/v1/company/{companyId}/project/{projectId}/invoice`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get invoices for company
         * @param {string} companyId 
         * @param {'InvoiceNumber' | 'Title' | 'DeliveryDate' | 'DueDate'} sortField 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [query] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesForCompany: async (companyId: string, sortField: 'InvoiceNumber' | 'Title' | 'DeliveryDate' | 'DueDate', page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getInvoicesForCompany', 'companyId', companyId)
            // verify required parameter 'sortField' is not null or undefined
            assertParamExists('getInvoicesForCompany', 'sortField', sortField)
            const localVarPath = `/v1/company/{companyId}/invoice`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceApi - functional programming interface
 * @export
 */
export const InvoiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new invoice
         * @param {string} companyId 
         * @param {InvoicePostRequest} invoicePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvoice(companyId: string, invoicePostRequest: InvoicePostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvoice(companyId, invoicePostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete invoice
         * @param {string} companyId 
         * @param {string} invoiceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInvoice(companyId: string, invoiceId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInvoice(companyId, invoiceId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get invoice by ID
         * @param {string} companyId 
         * @param {string} invoiceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoiceById(companyId: string, invoiceId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoiceById(companyId, invoiceId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get invoices by order ID
         * @param {string} companyId 
         * @param {string} orderId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoicesByOrderId(companyId: string, orderId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Invoice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoicesByOrderId(companyId, orderId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get invoices by project ID
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {'InvoiceNumber' | 'Title' | 'DeliveryDate' | 'DueDate'} sortField 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [query] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoicesByProjectId(companyId: string, projectId: string, sortField: 'InvoiceNumber' | 'Title' | 'DeliveryDate' | 'DueDate', page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoicesByProjectId(companyId, projectId, sortField, page, sortDirection, query, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get invoices for company
         * @param {string} companyId 
         * @param {'InvoiceNumber' | 'Title' | 'DeliveryDate' | 'DueDate'} sortField 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [query] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoicesForCompany(companyId: string, sortField: 'InvoiceNumber' | 'Title' | 'DeliveryDate' | 'DueDate', page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoicesForCompany(companyId, sortField, page, sortDirection, query, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoiceApi - factory interface
 * @export
 */
export const InvoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new invoice
         * @param {string} companyId 
         * @param {InvoicePostRequest} invoicePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoice(companyId: string, invoicePostRequest: InvoicePostRequest, authorization?: string, options?: any): AxiosPromise<Invoice> {
            return localVarFp.createInvoice(companyId, invoicePostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete invoice
         * @param {string} companyId 
         * @param {string} invoiceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvoice(companyId: string, invoiceId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteInvoice(companyId, invoiceId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get invoice by ID
         * @param {string} companyId 
         * @param {string} invoiceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceById(companyId: string, invoiceId: string, authorization?: string, options?: any): AxiosPromise<Invoice> {
            return localVarFp.getInvoiceById(companyId, invoiceId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get invoices by order ID
         * @param {string} companyId 
         * @param {string} orderId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesByOrderId(companyId: string, orderId: string, authorization?: string, options?: any): AxiosPromise<Array<Invoice>> {
            return localVarFp.getInvoicesByOrderId(companyId, orderId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get invoices by project ID
         * @param {string} companyId 
         * @param {string} projectId 
         * @param {'InvoiceNumber' | 'Title' | 'DeliveryDate' | 'DueDate'} sortField 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [query] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesByProjectId(companyId: string, projectId: string, sortField: 'InvoiceNumber' | 'Title' | 'DeliveryDate' | 'DueDate', page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, authorization?: string, options?: any): AxiosPromise<PaginatedResponseInvoice> {
            return localVarFp.getInvoicesByProjectId(companyId, projectId, sortField, page, sortDirection, query, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get invoices for company
         * @param {string} companyId 
         * @param {'InvoiceNumber' | 'Title' | 'DeliveryDate' | 'DueDate'} sortField 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [query] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesForCompany(companyId: string, sortField: 'InvoiceNumber' | 'Title' | 'DeliveryDate' | 'DueDate', page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, authorization?: string, options?: any): AxiosPromise<PaginatedResponseInvoice> {
            return localVarFp.getInvoicesForCompany(companyId, sortField, page, sortDirection, query, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceApi - object-oriented interface
 * @export
 * @class InvoiceApi
 * @extends {BaseAPI}
 */
export class InvoiceApi extends BaseAPI {
    /**
     * 
     * @summary Create new invoice
     * @param {string} companyId 
     * @param {InvoicePostRequest} invoicePostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public createInvoice(companyId: string, invoicePostRequest: InvoicePostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).createInvoice(companyId, invoicePostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete invoice
     * @param {string} companyId 
     * @param {string} invoiceId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public deleteInvoice(companyId: string, invoiceId: string, authorization?: string, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).deleteInvoice(companyId, invoiceId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get invoice by ID
     * @param {string} companyId 
     * @param {string} invoiceId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public getInvoiceById(companyId: string, invoiceId: string, authorization?: string, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).getInvoiceById(companyId, invoiceId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get invoices by order ID
     * @param {string} companyId 
     * @param {string} orderId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public getInvoicesByOrderId(companyId: string, orderId: string, authorization?: string, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).getInvoicesByOrderId(companyId, orderId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get invoices by project ID
     * @param {string} companyId 
     * @param {string} projectId 
     * @param {'InvoiceNumber' | 'Title' | 'DeliveryDate' | 'DueDate'} sortField 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {string} [query] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public getInvoicesByProjectId(companyId: string, projectId: string, sortField: 'InvoiceNumber' | 'Title' | 'DeliveryDate' | 'DueDate', page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, authorization?: string, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).getInvoicesByProjectId(companyId, projectId, sortField, page, sortDirection, query, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get invoices for company
     * @param {string} companyId 
     * @param {'InvoiceNumber' | 'Title' | 'DeliveryDate' | 'DueDate'} sortField 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {string} [query] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public getInvoicesForCompany(companyId: string, sortField: 'InvoiceNumber' | 'Title' | 'DeliveryDate' | 'DueDate', page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, authorization?: string, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).getInvoicesForCompany(companyId, sortField, page, sortDirection, query, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
