import React, { AnchorHTMLAttributes, forwardRef } from "react";
import { TabNav } from "@radix-ui/themes";
import { createLink, Link, useLocation } from "@tanstack/react-router";

const InnerTabLink = forwardRef<
  HTMLAnchorElement,
  AnchorHTMLAttributes<HTMLAnchorElement>
>((props, ref) => {
  const location = useLocation();

  return (
    <TabNav.Link
      ref={ref}
      asChild
      active={location.href.includes(props.href ?? "")}
    >
      <Link replace={true} {...props}>
        {props.children}
      </Link>
    </TabNav.Link>
  );
});

export const TabLink = createLink(InnerTabLink);
