import {
  ServiceContractApi,
  ServiceContractPostRequest,
  ServiceContractPutRequest
} from "../../.generated/api";
import environment from "../../environment";
import { apiInstance } from "./ApiHttpBase";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { createQuery } from "react-query-kit";

const serviceContractApi = new ServiceContractApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export const useGetServiceContactById = createQuery({
  queryKey: ["getServiceContactById"],
  fetcher: async (variables: {
    companyId: string;
    serviceContractId: string;
  }) => {
    const response = await serviceContractApi.getServiceContract(
      variables.serviceContractId,
      variables.companyId
    );
    return response.data;
  }
});

export const useGetServiceContractsByFacilityId = createQuery({
  queryKey: ["getServiceContractsByFacilityId"],
  fetcher: async (variables: { companyId: string; facilityId: string }) => {
    const response = await serviceContractApi.getAllServiceContractsOnFacility(
      variables.facilityId,
      variables.companyId
    );
    return response.data;
  }
});

export const serviceContractQueries = createQueryKeys("serviceContact", {
  getByFacilityId: (
    companyId: string,
    facilityId: string,
    query?: string,
    page?: number,
    archived = false
  ) => ({
    queryKey: ["getByFacilityId", companyId, facilityId, query, archived],
    queryFn: async () => {
      const response =
        await serviceContractApi.getAllServiceContractsOnFacility(
          facilityId,
          companyId,
          query,
          page,
          "DESC",
          "Number",
          archived
        );
      return response.data;
    }
  }),
  getByCompanyId: (
    companyId: string,
    sortField: "Number" | "Name",
    sortDirection: "ASC" | "DESC",
    query?: string,
    page?: number,
    archived = false
  ) => ({
    queryKey: [
      "getAllByCompanyId",
      companyId,
      sortDirection,
      sortField,
      query,
      page,
      archived
    ],
    queryFn: async () => {
      const response = await serviceContractApi.getAllServiceContractsOnCompany(
        companyId,
        query,
        page,
        sortDirection,
        sortField,
        archived
      );
      return response.data;
    }
  })
});

export async function createServiceContract(
  request: ServiceContractPostRequest
) {
  const response = await serviceContractApi.postServiceContract(request);
  return response.data;
}

export async function updateServiceContract(
  request: ServiceContractPutRequest
) {
  const response = await serviceContractApi.updateServiceContract(request);
  return response.data;
}

export async function archiveServiceContract(
  serviceContractId: string,
  companyId: string
) {
  await serviceContractApi.archiveServiceContract(serviceContractId, companyId);
}

export async function restoreServiceContract(
  serviceContractId: string,
  companyId: string
) {
  await serviceContractApi.restoreServiceContract(serviceContractId, companyId);
}
