/* tslint:disable */
/* eslint-disable */
/**
 * SkyworkerProducts API
 * API Documentation
 *
 * The version of the OpenAPI document: 1.0
 * Contact: bartegjengen@aboveit.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DeleteInventory200Response } from '../model';
// @ts-ignore
import { GetAllCompanyProducts200Response } from '../model';
// @ts-ignore
import { GetProductByID200Response } from '../model';
// @ts-ignore
import { GetProductsByEANNumber200Response } from '../model';
// @ts-ignore
import { ProductPriceRequest } from '../model';
// @ts-ignore
import { ProductRequest } from '../model';
// @ts-ignore
import { ResponseDTO } from '../model';
/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new product.
         * @summary Create product
         * @param {string} companyId Company ID
         * @param {ProductRequest} reqBody json format
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: async (companyId: string, reqBody: ProductRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createProduct', 'companyId', companyId)
            // verify required parameter 'reqBody' is not null or undefined
            assertParamExists('createProduct', 'reqBody', reqBody)
            const localVarPath = `/company/{company_id}/products`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the inventory relation of a product, based on the product_id.
         * @summary Delete inventory
         * @param {string} companyId Company ID
         * @param {string} productId Product ID
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInventory: async (companyId: string, productId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteInventory', 'companyId', companyId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('deleteInventory', 'productId', productId)
            const localVarPath = `/company/{company_id}/inventory/{product_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a product based on product_id. (Delete means setting archived to true)
         * @summary Delete/Archive product
         * @param {string} companyId Company ID
         * @param {string} productId Product ID
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct: async (companyId: string, productId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteProduct', 'companyId', companyId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('deleteProduct', 'productId', productId)
            const localVarPath = `/company/{company_id}/products/{product_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve an array of products belonging to a company.
         * @summary Get all products
         * @param {string} companyId Company ID
         * @param {number} [page] Page number
         * @param {number} [limit] Number of items per page. Set limit &#x3D; -1 to retrieve all products.
         * @param {string} [sort] Sorting: column:asc/desc
         * @param {string} [search] Search: search_string
         * @param {boolean} [archived] Retrieve archived products, not mandatory
         * @param {string} [categoryIds] Array of category uuids. Example: category_ids&#x3D;val1,val2,val3
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyProducts: async (companyId: string, page?: number, limit?: number, sort?: string, search?: string, archived?: boolean, categoryIds?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllCompanyProducts', 'companyId', companyId)
            const localVarPath = `/company/{company_id}/products`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }

            if (categoryIds !== undefined) {
                localVarQueryParameter['category_ids'] = categoryIds;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get one single product. Returns an instance of responses.Product.
         * @summary Get product by ID
         * @param {string} companyId Company ID
         * @param {string} productId Product ID
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductByID: async (companyId: string, productId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getProductByID', 'companyId', companyId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductByID', 'productId', productId)
            const localVarPath = `/company/{company_id}/products/{product_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get products by EAN Number. Returns an array of responses.Product.
         * @summary Get products by EAN number
         * @param {string} companyId Company ID
         * @param {string} eanNumber EAN Number
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsByEANNumber: async (companyId: string, eanNumber: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getProductsByEANNumber', 'companyId', companyId)
            // verify required parameter 'eanNumber' is not null or undefined
            assertParamExists('getProductsByEANNumber', 'eanNumber', eanNumber)
            const localVarPath = `/company/{company_id}/products/ean_number/{ean_number}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"ean_number"}}`, encodeURIComponent(String(eanNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unarchives a product based on product_id. (Unarchive means setting archived from true to false)
         * @summary Unarchive product
         * @param {string} companyId Company ID
         * @param {string} productId Product ID
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveProduct: async (companyId: string, productId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('unarchiveProduct', 'companyId', companyId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('unarchiveProduct', 'productId', productId)
            const localVarPath = `/company/{company_id}/products/{product_id}/unarchive`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a product
         * @param {string} companyId Company ID
         * @param {string} productId Product ID
         * @param {ProductRequest} reqBody json format
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct: async (companyId: string, productId: string, reqBody: ProductRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateProduct', 'companyId', companyId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('updateProduct', 'productId', productId)
            // verify required parameter 'reqBody' is not null or undefined
            assertParamExists('updateProduct', 'reqBody', reqBody)
            const localVarPath = `/company/{company_id}/products/{product_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a product
         * @param {string} companyId Company ID
         * @param {ProductPriceRequest} reqBody json format
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductPrices: async (companyId: string, reqBody: ProductPriceRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateProductPrices', 'companyId', companyId)
            // verify required parameter 'reqBody' is not null or undefined
            assertParamExists('updateProductPrices', 'reqBody', reqBody)
            const localVarPath = `/company/{company_id}/products/price/update`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reqBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new product.
         * @summary Create product
         * @param {string} companyId Company ID
         * @param {ProductRequest} reqBody json format
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProduct(companyId: string, reqBody: ProductRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteInventory200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProduct(companyId, reqBody, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the inventory relation of a product, based on the product_id.
         * @summary Delete inventory
         * @param {string} companyId Company ID
         * @param {string} productId Product ID
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInventory(companyId: string, productId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteInventory200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInventory(companyId, productId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a product based on product_id. (Delete means setting archived to true)
         * @summary Delete/Archive product
         * @param {string} companyId Company ID
         * @param {string} productId Product ID
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProduct(companyId: string, productId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteInventory200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProduct(companyId, productId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve an array of products belonging to a company.
         * @summary Get all products
         * @param {string} companyId Company ID
         * @param {number} [page] Page number
         * @param {number} [limit] Number of items per page. Set limit &#x3D; -1 to retrieve all products.
         * @param {string} [sort] Sorting: column:asc/desc
         * @param {string} [search] Search: search_string
         * @param {boolean} [archived] Retrieve archived products, not mandatory
         * @param {string} [categoryIds] Array of category uuids. Example: category_ids&#x3D;val1,val2,val3
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCompanyProducts(companyId: string, page?: number, limit?: number, sort?: string, search?: string, archived?: boolean, categoryIds?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllCompanyProducts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCompanyProducts(companyId, page, limit, sort, search, archived, categoryIds, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get one single product. Returns an instance of responses.Product.
         * @summary Get product by ID
         * @param {string} companyId Company ID
         * @param {string} productId Product ID
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductByID(companyId: string, productId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductByID200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductByID(companyId, productId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get products by EAN Number. Returns an array of responses.Product.
         * @summary Get products by EAN number
         * @param {string} companyId Company ID
         * @param {string} eanNumber EAN Number
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsByEANNumber(companyId: string, eanNumber: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductsByEANNumber200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsByEANNumber(companyId, eanNumber, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Unarchives a product based on product_id. (Unarchive means setting archived from true to false)
         * @summary Unarchive product
         * @param {string} companyId Company ID
         * @param {string} productId Product ID
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unarchiveProduct(companyId: string, productId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteInventory200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unarchiveProduct(companyId, productId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a product
         * @param {string} companyId Company ID
         * @param {string} productId Product ID
         * @param {ProductRequest} reqBody json format
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProduct(companyId: string, productId: string, reqBody: ProductRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteInventory200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProduct(companyId, productId, reqBody, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a product
         * @param {string} companyId Company ID
         * @param {ProductPriceRequest} reqBody json format
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProductPrices(companyId: string, reqBody: ProductPriceRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteInventory200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProductPrices(companyId, reqBody, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * Create a new product.
         * @summary Create product
         * @param {string} companyId Company ID
         * @param {ProductRequest} reqBody json format
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(companyId: string, reqBody: ProductRequest, authorization?: string, options?: any): AxiosPromise<DeleteInventory200Response> {
            return localVarFp.createProduct(companyId, reqBody, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the inventory relation of a product, based on the product_id.
         * @summary Delete inventory
         * @param {string} companyId Company ID
         * @param {string} productId Product ID
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInventory(companyId: string, productId: string, authorization?: string, options?: any): AxiosPromise<DeleteInventory200Response> {
            return localVarFp.deleteInventory(companyId, productId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a product based on product_id. (Delete means setting archived to true)
         * @summary Delete/Archive product
         * @param {string} companyId Company ID
         * @param {string} productId Product ID
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct(companyId: string, productId: string, authorization?: string, options?: any): AxiosPromise<DeleteInventory200Response> {
            return localVarFp.deleteProduct(companyId, productId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve an array of products belonging to a company.
         * @summary Get all products
         * @param {string} companyId Company ID
         * @param {number} [page] Page number
         * @param {number} [limit] Number of items per page. Set limit &#x3D; -1 to retrieve all products.
         * @param {string} [sort] Sorting: column:asc/desc
         * @param {string} [search] Search: search_string
         * @param {boolean} [archived] Retrieve archived products, not mandatory
         * @param {string} [categoryIds] Array of category uuids. Example: category_ids&#x3D;val1,val2,val3
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyProducts(companyId: string, page?: number, limit?: number, sort?: string, search?: string, archived?: boolean, categoryIds?: string, authorization?: string, options?: any): AxiosPromise<GetAllCompanyProducts200Response> {
            return localVarFp.getAllCompanyProducts(companyId, page, limit, sort, search, archived, categoryIds, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Get one single product. Returns an instance of responses.Product.
         * @summary Get product by ID
         * @param {string} companyId Company ID
         * @param {string} productId Product ID
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductByID(companyId: string, productId: string, authorization?: string, options?: any): AxiosPromise<GetProductByID200Response> {
            return localVarFp.getProductByID(companyId, productId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Get products by EAN Number. Returns an array of responses.Product.
         * @summary Get products by EAN number
         * @param {string} companyId Company ID
         * @param {string} eanNumber EAN Number
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsByEANNumber(companyId: string, eanNumber: string, authorization?: string, options?: any): AxiosPromise<GetProductsByEANNumber200Response> {
            return localVarFp.getProductsByEANNumber(companyId, eanNumber, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Unarchives a product based on product_id. (Unarchive means setting archived from true to false)
         * @summary Unarchive product
         * @param {string} companyId Company ID
         * @param {string} productId Product ID
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveProduct(companyId: string, productId: string, authorization?: string, options?: any): AxiosPromise<DeleteInventory200Response> {
            return localVarFp.unarchiveProduct(companyId, productId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a product
         * @param {string} companyId Company ID
         * @param {string} productId Product ID
         * @param {ProductRequest} reqBody json format
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct(companyId: string, productId: string, reqBody: ProductRequest, authorization?: string, options?: any): AxiosPromise<DeleteInventory200Response> {
            return localVarFp.updateProduct(companyId, productId, reqBody, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a product
         * @param {string} companyId Company ID
         * @param {ProductPriceRequest} reqBody json format
         * @param {string} [authorization] Bearer token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductPrices(companyId: string, reqBody: ProductPriceRequest, authorization?: string, options?: any): AxiosPromise<DeleteInventory200Response> {
            return localVarFp.updateProductPrices(companyId, reqBody, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * Create a new product.
     * @summary Create product
     * @param {string} companyId Company ID
     * @param {ProductRequest} reqBody json format
     * @param {string} [authorization] Bearer token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public createProduct(companyId: string, reqBody: ProductRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).createProduct(companyId, reqBody, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the inventory relation of a product, based on the product_id.
     * @summary Delete inventory
     * @param {string} companyId Company ID
     * @param {string} productId Product ID
     * @param {string} [authorization] Bearer token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public deleteInventory(companyId: string, productId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).deleteInventory(companyId, productId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a product based on product_id. (Delete means setting archived to true)
     * @summary Delete/Archive product
     * @param {string} companyId Company ID
     * @param {string} productId Product ID
     * @param {string} [authorization] Bearer token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public deleteProduct(companyId: string, productId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).deleteProduct(companyId, productId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve an array of products belonging to a company.
     * @summary Get all products
     * @param {string} companyId Company ID
     * @param {number} [page] Page number
     * @param {number} [limit] Number of items per page. Set limit &#x3D; -1 to retrieve all products.
     * @param {string} [sort] Sorting: column:asc/desc
     * @param {string} [search] Search: search_string
     * @param {boolean} [archived] Retrieve archived products, not mandatory
     * @param {string} [categoryIds] Array of category uuids. Example: category_ids&#x3D;val1,val2,val3
     * @param {string} [authorization] Bearer token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getAllCompanyProducts(companyId: string, page?: number, limit?: number, sort?: string, search?: string, archived?: boolean, categoryIds?: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getAllCompanyProducts(companyId, page, limit, sort, search, archived, categoryIds, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get one single product. Returns an instance of responses.Product.
     * @summary Get product by ID
     * @param {string} companyId Company ID
     * @param {string} productId Product ID
     * @param {string} [authorization] Bearer token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductByID(companyId: string, productId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProductByID(companyId, productId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get products by EAN Number. Returns an array of responses.Product.
     * @summary Get products by EAN number
     * @param {string} companyId Company ID
     * @param {string} eanNumber EAN Number
     * @param {string} [authorization] Bearer token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductsByEANNumber(companyId: string, eanNumber: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProductsByEANNumber(companyId, eanNumber, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unarchives a product based on product_id. (Unarchive means setting archived from true to false)
     * @summary Unarchive product
     * @param {string} companyId Company ID
     * @param {string} productId Product ID
     * @param {string} [authorization] Bearer token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public unarchiveProduct(companyId: string, productId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).unarchiveProduct(companyId, productId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a product
     * @param {string} companyId Company ID
     * @param {string} productId Product ID
     * @param {ProductRequest} reqBody json format
     * @param {string} [authorization] Bearer token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public updateProduct(companyId: string, productId: string, reqBody: ProductRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).updateProduct(companyId, productId, reqBody, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a product
     * @param {string} companyId Company ID
     * @param {ProductPriceRequest} reqBody json format
     * @param {string} [authorization] Bearer token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public updateProductPrices(companyId: string, reqBody: ProductPriceRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).updateProductPrices(companyId, reqBody, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
