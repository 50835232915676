/* tslint:disable */
/* eslint-disable */
/**
 * Skyworker Timekeeping
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Activity } from '../model';
// @ts-ignore
import { ActivityFavoritePutRequest } from '../model';
// @ts-ignore
import { ActivityPostRequest } from '../model';
// @ts-ignore
import { ActivityPutRequest } from '../model';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { UserActivity } from '../model';
/**
 * ActivitiesApi - axios parameter creator
 * @export
 */
export const ActivitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new activity
         * @param {string} companyId 
         * @param {ActivityPostRequest} activityPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivity: async (companyId: string, activityPostRequest: ActivityPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createActivity', 'companyId', companyId)
            // verify required parameter 'activityPostRequest' is not null or undefined
            assertParamExists('createActivity', 'activityPostRequest', activityPostRequest)
            const localVarPath = `/v2/{companyId}/activity`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete activity
         * @param {string} companyId 
         * @param {string} activityId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivity: async (companyId: string, activityId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteActivity', 'companyId', companyId)
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('deleteActivity', 'activityId', activityId)
            const localVarPath = `/v2/{companyId}/activity/{activityId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get activities for company
         * @param {string} companyId 
         * @param {'All' | 'Project' | 'Company' | 'Order'} [type] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivities: async (companyId: string, type?: 'All' | 'Project' | 'Company' | 'Order', authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getActivities', 'companyId', companyId)
            const localVarPath = `/v2/{companyId}/activity`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all activities by resource ID (order)
         * @param {string} companyId 
         * @param {string} resourceId 
         * @param {'Company' | 'Project' | 'Task' | 'Order'} resourceType 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivitiesByResourceId: async (companyId: string, resourceId: string, resourceType: 'Company' | 'Project' | 'Task' | 'Order', authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getActivitiesByResourceId', 'companyId', companyId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getActivitiesByResourceId', 'resourceId', resourceId)
            // verify required parameter 'resourceType' is not null or undefined
            assertParamExists('getActivitiesByResourceId', 'resourceType', resourceType)
            const localVarPath = `/v2/company/{companyId}/{resourceType}/{resourceId}/activities`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)))
                .replace(`{${"resourceType"}}`, encodeURIComponent(String(resourceType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get activities for user
         * @param {string} companyId 
         * @param {string} userId 
         * @param {string} [resourceId] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivitiesOnUser: async (companyId: string, userId: string, resourceId?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getActivitiesOnUser', 'companyId', companyId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getActivitiesOnUser', 'userId', userId)
            const localVarPath = `/v2/{companyId}/activity/user/{userId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (resourceId !== undefined) {
                localVarQueryParameter['resourceId'] = resourceId;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get activity by resource ID (task or order)
         * @param {string} companyId 
         * @param {string} resourceId 
         * @param {'Company' | 'Project' | 'Task' | 'Order'} resourceType 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityByResourceId: async (companyId: string, resourceId: string, resourceType: 'Company' | 'Project' | 'Task' | 'Order', authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getActivityByResourceId', 'companyId', companyId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getActivityByResourceId', 'resourceId', resourceId)
            // verify required parameter 'resourceType' is not null or undefined
            assertParamExists('getActivityByResourceId', 'resourceType', resourceType)
            const localVarPath = `/v2/company/{companyId}/{resourceType}/{resourceId}/activity`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)))
                .replace(`{${"resourceType"}}`, encodeURIComponent(String(resourceType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get activities for user
         * @param {string} companyId 
         * @param {string} [resourceId] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserActivities: async (companyId: string, resourceId?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getUserActivities', 'companyId', companyId)
            const localVarPath = `/v2/{companyId}/activity/user`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (resourceId !== undefined) {
                localVarQueryParameter['resourceId'] = resourceId;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update activity
         * @param {string} companyId 
         * @param {string} activityId 
         * @param {ActivityPutRequest} activityPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivity: async (companyId: string, activityId: string, activityPutRequest: ActivityPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateActivity', 'companyId', companyId)
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('updateActivity', 'activityId', activityId)
            // verify required parameter 'activityPutRequest' is not null or undefined
            assertParamExists('updateActivity', 'activityPutRequest', activityPutRequest)
            const localVarPath = `/v2/{companyId}/activity/{activityId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update favorite status on user activity
         * @param {ActivityFavoritePutRequest} activityFavoritePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFavoriteStatus: async (activityFavoritePutRequest: ActivityFavoritePutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityFavoritePutRequest' is not null or undefined
            assertParamExists('updateFavoriteStatus', 'activityFavoritePutRequest', activityFavoritePutRequest)
            const localVarPath = `/v2/activity/favorite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityFavoritePutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivitiesApi - functional programming interface
 * @export
 */
export const ActivitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new activity
         * @param {string} companyId 
         * @param {ActivityPostRequest} activityPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createActivity(companyId: string, activityPostRequest: ActivityPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createActivity(companyId, activityPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete activity
         * @param {string} companyId 
         * @param {string} activityId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteActivity(companyId: string, activityId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteActivity(companyId, activityId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get activities for company
         * @param {string} companyId 
         * @param {'All' | 'Project' | 'Company' | 'Order'} [type] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivities(companyId: string, type?: 'All' | 'Project' | 'Company' | 'Order', authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Activity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivities(companyId, type, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all activities by resource ID (order)
         * @param {string} companyId 
         * @param {string} resourceId 
         * @param {'Company' | 'Project' | 'Task' | 'Order'} resourceType 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivitiesByResourceId(companyId: string, resourceId: string, resourceType: 'Company' | 'Project' | 'Task' | 'Order', authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserActivity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivitiesByResourceId(companyId, resourceId, resourceType, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get activities for user
         * @param {string} companyId 
         * @param {string} userId 
         * @param {string} [resourceId] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivitiesOnUser(companyId: string, userId: string, resourceId?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserActivity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivitiesOnUser(companyId, userId, resourceId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get activity by resource ID (task or order)
         * @param {string} companyId 
         * @param {string} resourceId 
         * @param {'Company' | 'Project' | 'Task' | 'Order'} resourceType 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityByResourceId(companyId: string, resourceId: string, resourceType: 'Company' | 'Project' | 'Task' | 'Order', authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserActivity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityByResourceId(companyId, resourceId, resourceType, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get activities for user
         * @param {string} companyId 
         * @param {string} [resourceId] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserActivities(companyId: string, resourceId?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserActivity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserActivities(companyId, resourceId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update activity
         * @param {string} companyId 
         * @param {string} activityId 
         * @param {ActivityPutRequest} activityPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActivity(companyId: string, activityId: string, activityPutRequest: ActivityPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActivity(companyId, activityId, activityPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update favorite status on user activity
         * @param {ActivityFavoritePutRequest} activityFavoritePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFavoriteStatus(activityFavoritePutRequest: ActivityFavoritePutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserActivity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFavoriteStatus(activityFavoritePutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivitiesApi - factory interface
 * @export
 */
export const ActivitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivitiesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new activity
         * @param {string} companyId 
         * @param {ActivityPostRequest} activityPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivity(companyId: string, activityPostRequest: ActivityPostRequest, authorization?: string, options?: any): AxiosPromise<Activity> {
            return localVarFp.createActivity(companyId, activityPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete activity
         * @param {string} companyId 
         * @param {string} activityId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivity(companyId: string, activityId: string, authorization?: string, options?: any): AxiosPromise<Activity> {
            return localVarFp.deleteActivity(companyId, activityId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get activities for company
         * @param {string} companyId 
         * @param {'All' | 'Project' | 'Company' | 'Order'} [type] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivities(companyId: string, type?: 'All' | 'Project' | 'Company' | 'Order', authorization?: string, options?: any): AxiosPromise<Array<Activity>> {
            return localVarFp.getActivities(companyId, type, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all activities by resource ID (order)
         * @param {string} companyId 
         * @param {string} resourceId 
         * @param {'Company' | 'Project' | 'Task' | 'Order'} resourceType 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivitiesByResourceId(companyId: string, resourceId: string, resourceType: 'Company' | 'Project' | 'Task' | 'Order', authorization?: string, options?: any): AxiosPromise<Array<UserActivity>> {
            return localVarFp.getActivitiesByResourceId(companyId, resourceId, resourceType, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get activities for user
         * @param {string} companyId 
         * @param {string} userId 
         * @param {string} [resourceId] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivitiesOnUser(companyId: string, userId: string, resourceId?: string, authorization?: string, options?: any): AxiosPromise<Array<UserActivity>> {
            return localVarFp.getActivitiesOnUser(companyId, userId, resourceId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get activity by resource ID (task or order)
         * @param {string} companyId 
         * @param {string} resourceId 
         * @param {'Company' | 'Project' | 'Task' | 'Order'} resourceType 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityByResourceId(companyId: string, resourceId: string, resourceType: 'Company' | 'Project' | 'Task' | 'Order', authorization?: string, options?: any): AxiosPromise<UserActivity> {
            return localVarFp.getActivityByResourceId(companyId, resourceId, resourceType, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get activities for user
         * @param {string} companyId 
         * @param {string} [resourceId] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserActivities(companyId: string, resourceId?: string, authorization?: string, options?: any): AxiosPromise<Array<UserActivity>> {
            return localVarFp.getUserActivities(companyId, resourceId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update activity
         * @param {string} companyId 
         * @param {string} activityId 
         * @param {ActivityPutRequest} activityPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivity(companyId: string, activityId: string, activityPutRequest: ActivityPutRequest, authorization?: string, options?: any): AxiosPromise<Activity> {
            return localVarFp.updateActivity(companyId, activityId, activityPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update favorite status on user activity
         * @param {ActivityFavoritePutRequest} activityFavoritePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFavoriteStatus(activityFavoritePutRequest: ActivityFavoritePutRequest, authorization?: string, options?: any): AxiosPromise<UserActivity> {
            return localVarFp.updateFavoriteStatus(activityFavoritePutRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivitiesApi - object-oriented interface
 * @export
 * @class ActivitiesApi
 * @extends {BaseAPI}
 */
export class ActivitiesApi extends BaseAPI {
    /**
     * 
     * @summary Create new activity
     * @param {string} companyId 
     * @param {ActivityPostRequest} activityPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public createActivity(companyId: string, activityPostRequest: ActivityPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).createActivity(companyId, activityPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete activity
     * @param {string} companyId 
     * @param {string} activityId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public deleteActivity(companyId: string, activityId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).deleteActivity(companyId, activityId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get activities for company
     * @param {string} companyId 
     * @param {'All' | 'Project' | 'Company' | 'Order'} [type] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public getActivities(companyId: string, type?: 'All' | 'Project' | 'Company' | 'Order', authorization?: string, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).getActivities(companyId, type, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all activities by resource ID (order)
     * @param {string} companyId 
     * @param {string} resourceId 
     * @param {'Company' | 'Project' | 'Task' | 'Order'} resourceType 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public getActivitiesByResourceId(companyId: string, resourceId: string, resourceType: 'Company' | 'Project' | 'Task' | 'Order', authorization?: string, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).getActivitiesByResourceId(companyId, resourceId, resourceType, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get activities for user
     * @param {string} companyId 
     * @param {string} userId 
     * @param {string} [resourceId] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public getActivitiesOnUser(companyId: string, userId: string, resourceId?: string, authorization?: string, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).getActivitiesOnUser(companyId, userId, resourceId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get activity by resource ID (task or order)
     * @param {string} companyId 
     * @param {string} resourceId 
     * @param {'Company' | 'Project' | 'Task' | 'Order'} resourceType 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public getActivityByResourceId(companyId: string, resourceId: string, resourceType: 'Company' | 'Project' | 'Task' | 'Order', authorization?: string, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).getActivityByResourceId(companyId, resourceId, resourceType, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get activities for user
     * @param {string} companyId 
     * @param {string} [resourceId] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public getUserActivities(companyId: string, resourceId?: string, authorization?: string, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).getUserActivities(companyId, resourceId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update activity
     * @param {string} companyId 
     * @param {string} activityId 
     * @param {ActivityPutRequest} activityPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public updateActivity(companyId: string, activityId: string, activityPutRequest: ActivityPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).updateActivity(companyId, activityId, activityPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update favorite status on user activity
     * @param {ActivityFavoritePutRequest} activityFavoritePutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public updateFavoriteStatus(activityFavoritePutRequest: ActivityFavoritePutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).updateFavoriteStatus(activityFavoritePutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
