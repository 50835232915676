import {
  CopyProjectRequest,
  ProjectApi,
  ProjectPostRequest,
  ProjectPutRequest
} from "../../.generated/api";
import environment from "../../environment";
import { apiInstance } from "./ApiHttpBase";
import { createQuery } from "react-query-kit";

const projectApi = new ProjectApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export async function getAllCompanyProjects(
  companyId: string,
  status: "active" | "deleted" | undefined
) {
  const response = await projectApi.getAllCompanyProjects(
    companyId,
    undefined,
    status
  );
  return response.data;
}

export const useGetAllCompanyProjects = createQuery({
  queryKey: ["getAllCompanyProjects"],
  fetcher: async (variables: {
    companyId: string;
    status?: "active" | "deleted";
  }) => {
    const response = await projectApi.getAllCompanyProjects(
      variables.companyId,
      undefined,
      variables.status
    );

    return response.data;
  }
});

export async function getProjectDetails(
  projectId: string,
  childStatus: "active" | "deleted",
  taskStatus: "active" | "deleted"
) {
  const { data } = await projectApi.getProjectDetails(
    projectId,
    undefined,
    childStatus,
    taskStatus
  );

  return data;
}

export async function addProject(body: ProjectPostRequest) {
  const {
    data: { projectId }
  } = await projectApi.postProject(body);

  return projectId;
}

export async function updateProject(
  projectId: string,
  body: ProjectPutRequest
) {
  await projectApi.putProject(projectId, body);
}

export async function copyProject(
  projectId: string,
  request: CopyProjectRequest
) {
  const { data } = await projectApi.copyProject(projectId, request);

  return data;
}

export async function deleteProject(projectId: string) {
  await projectApi.deleteProject(projectId);
}

export async function restoreProject(projectId: string) {
  await projectApi.restoreProject(projectId);
}
