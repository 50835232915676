import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const createOrderSchema = z.object({
  facilityId: z.string().nullable().optional(),
  serviceContractId: z.string().nullable().optional(),
  initialStartDate: z.string().nullable().optional()
});

export const Route = createFileRoute("/_protected/dashboard/orders/new/")({
  validateSearch: createOrderSchema
});
