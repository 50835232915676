/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CustomErrorResponse
 */
export interface CustomErrorResponse {
    /**
     * 
     * @type {number}
     * @memberof CustomErrorResponse
     */
    'timestamp': number;
    /**
     * 
     * @type {number}
     * @memberof CustomErrorResponse
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof CustomErrorResponse
     */
    'error': string;
    /**
     * 
     * @type {string}
     * @memberof CustomErrorResponse
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof CustomErrorResponse
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomErrorResponse
     */
    'errorCode'?: CustomErrorResponseErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomErrorResponse
     */
    'path': string;
}

export const CustomErrorResponseErrorCodeEnum = {
    LastProjectManager: 'LastProjectManager',
    LastSystemAdmin: 'LastSystemAdmin',
    CustomerConnectionExists: 'CustomerConnectionExists',
    UserConnectionAlreadyExists: 'UserConnectionAlreadyExists',
    InvitationAlreadyExists: 'InvitationAlreadyExists',
    IsDefaultProjectStatus: 'IsDefaultProjectStatus',
    DuplicateProjectStatus: 'DuplicateProjectStatus',
    DuplicateProjectNumber: 'DuplicateProjectNumber',
    ChecklistRequired: 'ChecklistRequired',
    DuplicateEmployeeNumber: 'DuplicateEmployeeNumber',
    OrderStatusInUse: 'OrderStatusInUse',
    IsDefaultOrderStatus: 'IsDefaultOrderStatus',
    TooManyRecurringOrders: 'TooManyRecurringOrders',
    TooManyRecurringTasks: 'TooManyRecurringTasks',
    DuplicateSeriesInterval: 'DuplicateSeriesInterval',
    EquipmentNotAvailable: 'EquipmentNotAvailable',
    EquipmentAvailable: 'EquipmentAvailable',
    EquipmentCategoryNameTaken: 'EquipmentCategoryNameTaken',
    EquipmentCategoryInUse: 'EquipmentCategoryInUse',
    IsDefaultEquipmentCategory: 'IsDefaultEquipmentCategory',
    ChecklistNotCompleted: 'ChecklistNotCompleted'
} as const;

export type CustomErrorResponseErrorCodeEnum = typeof CustomErrorResponseErrorCodeEnum[keyof typeof CustomErrorResponseErrorCodeEnum];


