import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { facilityDetailsTabs } from "../../../../../router/Routes";

const facilityDetailsSchema = z.object({
  tab: facilityDetailsTabs.catch("overview").optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/facility/$facilityId/details"
)({
  validateSearch: facilityDetailsSchema
});
