import React, { forwardRef } from "react";
import {
  Button as RadixButton,
  ButtonProps as RadixButtonProps,
  Slot,
  Slottable
} from "@radix-ui/themes";
import RadixIcon from "./RadixIcon";
import classnames from "classnames";

type ButtonProps = RadixButtonProps & {
  icon?: string;
  trailingIcon?: string;
  noNegativeMargin?: boolean;
};

const ButtonNew = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { icon, trailingIcon, type = "button", noNegativeMargin, ...props },
    ref
  ) => {
    const applyNegativeMargin = noNegativeMargin && props.variant === "ghost";
    let child = (
      <>
        {icon && <RadixIcon icon={icon} size={props.size} />}
        {props.children}
        {trailingIcon && <RadixIcon icon={trailingIcon} size={props.size} />}
      </>
    );

    if (props.asChild) {
      child = (
        <Slot>
          {icon && <RadixIcon icon={icon} size={props.size} />}
          <Slottable>{props.children}</Slottable>
          {trailingIcon && <RadixIcon icon={trailingIcon} size={props.size} />}
        </Slot>
      );
    }

    return (
      <RadixButton
        {...props}
        ref={ref}
        type={type}
        className={classnames(props.className, {
          "m-0": applyNegativeMargin,
          "h-6": applyNegativeMargin && props.size === "2",
          "h-7": applyNegativeMargin && props.size === "3"
        })}
      >
        {child}
      </RadixButton>
    );
  }
);

export default ButtonNew;
