import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { settingsTabs } from "../../../../router/Routes";

const settingSchema = z.object({
  tab: settingsTabs.catch("company").optional()
});

export const Route = createFileRoute("/_protected/dashboard/settings/")({
  validateSearch: settingSchema
});
