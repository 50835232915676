/* tslint:disable */
/* eslint-disable */
/**
 * Skyworker Customer
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PaginatedResponseSalesOpportunity } from '../model';
// @ts-ignore
import { SalesOpportunity } from '../model';
// @ts-ignore
import { SalesOpportunityPostRequest } from '../model';
// @ts-ignore
import { SalesOpportunityPutRequest } from '../model';
/**
 * SalesOpportunityApi - axios parameter creator
 * @export
 */
export const SalesOpportunityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete/archive sales opportunity
         * @param {string} companyId 
         * @param {string} id 
         * @param {boolean} [markAsWon] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveSalesOpportunity: async (companyId: string, id: string, markAsWon?: boolean, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('archiveSalesOpportunity', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('archiveSalesOpportunity', 'id', id)
            const localVarPath = `/v1/company/{companyId}/salesOpportunity/{id}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (markAsWon !== undefined) {
                localVarQueryParameter['markAsWon'] = markAsWon;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new sales opportunity
         * @param {string} companyId 
         * @param {SalesOpportunityPostRequest} salesOpportunityPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesOpportunity: async (companyId: string, salesOpportunityPostRequest: SalesOpportunityPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createSalesOpportunity', 'companyId', companyId)
            // verify required parameter 'salesOpportunityPostRequest' is not null or undefined
            assertParamExists('createSalesOpportunity', 'salesOpportunityPostRequest', salesOpportunityPostRequest)
            const localVarPath = `/v1/company/{companyId}/salesOpportunity`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(salesOpportunityPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sales opportunities for company (paginated)
         * @param {string} companyId 
         * @param {'Title' | 'Status' | 'Customer' | 'ExpectedCloseDate' | 'EstimatedValue' | 'Priority' | 'Owner' | 'Price' | 'DeliveryType'} sortField 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [query] 
         * @param {boolean} [archived] 
         * @param {Array<'IdentifiedSale' | 'InProgress' | 'OfferSent' | 'Won' | 'Lost'>} [statusFilter] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedSalesOpportunityByCompanyId: async (companyId: string, sortField: 'Title' | 'Status' | 'Customer' | 'ExpectedCloseDate' | 'EstimatedValue' | 'Priority' | 'Owner' | 'Price' | 'DeliveryType', page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, archived?: boolean, statusFilter?: Array<'IdentifiedSale' | 'InProgress' | 'OfferSent' | 'Won' | 'Lost'>, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getPaginatedSalesOpportunityByCompanyId', 'companyId', companyId)
            // verify required parameter 'sortField' is not null or undefined
            assertParamExists('getPaginatedSalesOpportunityByCompanyId', 'sortField', sortField)
            const localVarPath = `/v1/company/{companyId}/salesOpportunity/paginated`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }

            if (statusFilter) {
                localVarQueryParameter['statusFilter'] = statusFilter;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sales opportunities for company
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesOpportunityByCompanyId: async (companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getSalesOpportunityByCompanyId', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/salesOpportunity`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sales opportunity by company and id
         * @param {string} companyId 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesOpportunityByCompanyIdAndId: async (companyId: string, id: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getSalesOpportunityByCompanyIdAndId', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSalesOpportunityByCompanyIdAndId', 'id', id)
            const localVarPath = `/v1/company/{companyId}/salesOpportunity/{id}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sales opportunities for customer
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesOpportunityByCustomerId: async (companyId: string, customerId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getSalesOpportunityByCustomerId', 'companyId', companyId)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getSalesOpportunityByCustomerId', 'customerId', customerId)
            const localVarPath = `/v1/company/{companyId}/customer/{customerId}/salesOpportunity`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update sales opportunity
         * @param {string} companyId 
         * @param {string} id 
         * @param {SalesOpportunityPutRequest} salesOpportunityPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSalesOpportunity: async (companyId: string, id: string, salesOpportunityPutRequest: SalesOpportunityPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateSalesOpportunity', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSalesOpportunity', 'id', id)
            // verify required parameter 'salesOpportunityPutRequest' is not null or undefined
            assertParamExists('updateSalesOpportunity', 'salesOpportunityPutRequest', salesOpportunityPutRequest)
            const localVarPath = `/v1/company/{companyId}/salesOpportunity/{id}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(salesOpportunityPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SalesOpportunityApi - functional programming interface
 * @export
 */
export const SalesOpportunityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SalesOpportunityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete/archive sales opportunity
         * @param {string} companyId 
         * @param {string} id 
         * @param {boolean} [markAsWon] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveSalesOpportunity(companyId: string, id: string, markAsWon?: boolean, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesOpportunity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveSalesOpportunity(companyId, id, markAsWon, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new sales opportunity
         * @param {string} companyId 
         * @param {SalesOpportunityPostRequest} salesOpportunityPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSalesOpportunity(companyId: string, salesOpportunityPostRequest: SalesOpportunityPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesOpportunity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSalesOpportunity(companyId, salesOpportunityPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get sales opportunities for company (paginated)
         * @param {string} companyId 
         * @param {'Title' | 'Status' | 'Customer' | 'ExpectedCloseDate' | 'EstimatedValue' | 'Priority' | 'Owner' | 'Price' | 'DeliveryType'} sortField 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [query] 
         * @param {boolean} [archived] 
         * @param {Array<'IdentifiedSale' | 'InProgress' | 'OfferSent' | 'Won' | 'Lost'>} [statusFilter] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedSalesOpportunityByCompanyId(companyId: string, sortField: 'Title' | 'Status' | 'Customer' | 'ExpectedCloseDate' | 'EstimatedValue' | 'Priority' | 'Owner' | 'Price' | 'DeliveryType', page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, archived?: boolean, statusFilter?: Array<'IdentifiedSale' | 'InProgress' | 'OfferSent' | 'Won' | 'Lost'>, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseSalesOpportunity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedSalesOpportunityByCompanyId(companyId, sortField, page, sortDirection, query, archived, statusFilter, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get sales opportunities for company
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalesOpportunityByCompanyId(companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SalesOpportunity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalesOpportunityByCompanyId(companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get sales opportunity by company and id
         * @param {string} companyId 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalesOpportunityByCompanyIdAndId(companyId: string, id: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesOpportunity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalesOpportunityByCompanyIdAndId(companyId, id, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get sales opportunities for customer
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalesOpportunityByCustomerId(companyId: string, customerId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SalesOpportunity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalesOpportunityByCustomerId(companyId, customerId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update sales opportunity
         * @param {string} companyId 
         * @param {string} id 
         * @param {SalesOpportunityPutRequest} salesOpportunityPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSalesOpportunity(companyId: string, id: string, salesOpportunityPutRequest: SalesOpportunityPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesOpportunity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSalesOpportunity(companyId, id, salesOpportunityPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SalesOpportunityApi - factory interface
 * @export
 */
export const SalesOpportunityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SalesOpportunityApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete/archive sales opportunity
         * @param {string} companyId 
         * @param {string} id 
         * @param {boolean} [markAsWon] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveSalesOpportunity(companyId: string, id: string, markAsWon?: boolean, authorization?: string, options?: any): AxiosPromise<SalesOpportunity> {
            return localVarFp.archiveSalesOpportunity(companyId, id, markAsWon, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new sales opportunity
         * @param {string} companyId 
         * @param {SalesOpportunityPostRequest} salesOpportunityPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesOpportunity(companyId: string, salesOpportunityPostRequest: SalesOpportunityPostRequest, authorization?: string, options?: any): AxiosPromise<SalesOpportunity> {
            return localVarFp.createSalesOpportunity(companyId, salesOpportunityPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sales opportunities for company (paginated)
         * @param {string} companyId 
         * @param {'Title' | 'Status' | 'Customer' | 'ExpectedCloseDate' | 'EstimatedValue' | 'Priority' | 'Owner' | 'Price' | 'DeliveryType'} sortField 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [query] 
         * @param {boolean} [archived] 
         * @param {Array<'IdentifiedSale' | 'InProgress' | 'OfferSent' | 'Won' | 'Lost'>} [statusFilter] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedSalesOpportunityByCompanyId(companyId: string, sortField: 'Title' | 'Status' | 'Customer' | 'ExpectedCloseDate' | 'EstimatedValue' | 'Priority' | 'Owner' | 'Price' | 'DeliveryType', page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, archived?: boolean, statusFilter?: Array<'IdentifiedSale' | 'InProgress' | 'OfferSent' | 'Won' | 'Lost'>, authorization?: string, options?: any): AxiosPromise<PaginatedResponseSalesOpportunity> {
            return localVarFp.getPaginatedSalesOpportunityByCompanyId(companyId, sortField, page, sortDirection, query, archived, statusFilter, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sales opportunities for company
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesOpportunityByCompanyId(companyId: string, authorization?: string, options?: any): AxiosPromise<Array<SalesOpportunity>> {
            return localVarFp.getSalesOpportunityByCompanyId(companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sales opportunity by company and id
         * @param {string} companyId 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesOpportunityByCompanyIdAndId(companyId: string, id: string, authorization?: string, options?: any): AxiosPromise<SalesOpportunity> {
            return localVarFp.getSalesOpportunityByCompanyIdAndId(companyId, id, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sales opportunities for customer
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesOpportunityByCustomerId(companyId: string, customerId: string, authorization?: string, options?: any): AxiosPromise<Array<SalesOpportunity>> {
            return localVarFp.getSalesOpportunityByCustomerId(companyId, customerId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update sales opportunity
         * @param {string} companyId 
         * @param {string} id 
         * @param {SalesOpportunityPutRequest} salesOpportunityPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSalesOpportunity(companyId: string, id: string, salesOpportunityPutRequest: SalesOpportunityPutRequest, authorization?: string, options?: any): AxiosPromise<SalesOpportunity> {
            return localVarFp.updateSalesOpportunity(companyId, id, salesOpportunityPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SalesOpportunityApi - object-oriented interface
 * @export
 * @class SalesOpportunityApi
 * @extends {BaseAPI}
 */
export class SalesOpportunityApi extends BaseAPI {
    /**
     * 
     * @summary Delete/archive sales opportunity
     * @param {string} companyId 
     * @param {string} id 
     * @param {boolean} [markAsWon] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOpportunityApi
     */
    public archiveSalesOpportunity(companyId: string, id: string, markAsWon?: boolean, authorization?: string, options?: AxiosRequestConfig) {
        return SalesOpportunityApiFp(this.configuration).archiveSalesOpportunity(companyId, id, markAsWon, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new sales opportunity
     * @param {string} companyId 
     * @param {SalesOpportunityPostRequest} salesOpportunityPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOpportunityApi
     */
    public createSalesOpportunity(companyId: string, salesOpportunityPostRequest: SalesOpportunityPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return SalesOpportunityApiFp(this.configuration).createSalesOpportunity(companyId, salesOpportunityPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sales opportunities for company (paginated)
     * @param {string} companyId 
     * @param {'Title' | 'Status' | 'Customer' | 'ExpectedCloseDate' | 'EstimatedValue' | 'Priority' | 'Owner' | 'Price' | 'DeliveryType'} sortField 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {string} [query] 
     * @param {boolean} [archived] 
     * @param {Array<'IdentifiedSale' | 'InProgress' | 'OfferSent' | 'Won' | 'Lost'>} [statusFilter] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOpportunityApi
     */
    public getPaginatedSalesOpportunityByCompanyId(companyId: string, sortField: 'Title' | 'Status' | 'Customer' | 'ExpectedCloseDate' | 'EstimatedValue' | 'Priority' | 'Owner' | 'Price' | 'DeliveryType', page?: number, sortDirection?: 'ASC' | 'DESC', query?: string, archived?: boolean, statusFilter?: Array<'IdentifiedSale' | 'InProgress' | 'OfferSent' | 'Won' | 'Lost'>, authorization?: string, options?: AxiosRequestConfig) {
        return SalesOpportunityApiFp(this.configuration).getPaginatedSalesOpportunityByCompanyId(companyId, sortField, page, sortDirection, query, archived, statusFilter, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sales opportunities for company
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOpportunityApi
     */
    public getSalesOpportunityByCompanyId(companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return SalesOpportunityApiFp(this.configuration).getSalesOpportunityByCompanyId(companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sales opportunity by company and id
     * @param {string} companyId 
     * @param {string} id 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOpportunityApi
     */
    public getSalesOpportunityByCompanyIdAndId(companyId: string, id: string, authorization?: string, options?: AxiosRequestConfig) {
        return SalesOpportunityApiFp(this.configuration).getSalesOpportunityByCompanyIdAndId(companyId, id, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sales opportunities for customer
     * @param {string} companyId 
     * @param {string} customerId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOpportunityApi
     */
    public getSalesOpportunityByCustomerId(companyId: string, customerId: string, authorization?: string, options?: AxiosRequestConfig) {
        return SalesOpportunityApiFp(this.configuration).getSalesOpportunityByCustomerId(companyId, customerId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update sales opportunity
     * @param {string} companyId 
     * @param {string} id 
     * @param {SalesOpportunityPutRequest} salesOpportunityPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOpportunityApi
     */
    public updateSalesOpportunity(companyId: string, id: string, salesOpportunityPutRequest: SalesOpportunityPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return SalesOpportunityApiFp(this.configuration).updateSalesOpportunity(companyId, id, salesOpportunityPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
