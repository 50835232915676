import {
  createFileRoute,
  Outlet,
  useLoaderData,
  useNavigate
} from "@tanstack/react-router";
import { useGetProjectById } from "../../../../../service/api/ProjectApiV2";
import Badge from "../../../../../components/ds/Badge";
import NewPageTitle from "../../../../../components/common/NewPageTitle";
import React from "react";
import TabNav from "../../../../../components/TabNav";
import { useTranslation } from "react-i18next";
import { TabLink } from "../../../../../components/TabLink";

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/_layout"
)({
  loader: async ({ context: { queryClient }, params: { projectId } }) => {
    return await queryClient.fetchQuery(
      useGetProjectById.getOptions({ projectId })
    );
  },
  component: ProjectDetailsLayout
});

function ProjectDetailsLayout() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const project = useLoaderData({
    from: "/_protected/dashboard/project/$projectId/_layout"
  });
  const projectId = project.id;

  return (
    <>
      <NewPageTitle
        title={project.title}
        trailing={<Badge>#{project.prefixedProjectNumber}</Badge>}
        onBackClick={async () => {
          if (project.parentProject) {
            await navigate({
              to: "/dashboard/project/$projectId/overview",
              params: { projectId: project.parentProject }
            });
          } else {
            await navigate({
              to: "/dashboard/project",
              search: {
                tab: "projectOverview"
              }
            });
          }
        }}
      />
      <TabNav>
        <TabLink
          to={"/dashboard/project/$projectId/overview"}
          params={{ projectId }}
        >
          {t("overview")}
        </TabLink>
        <TabLink
          to={"/dashboard/project/$projectId/progress-plan"}
          params={{ projectId }}
        >
          {t("progressPlan")}
        </TabLink>
        <TabLink
          to={"/dashboard/project/$projectId/task"}
          params={{ projectId }}
        >
          {t("tasks")}
        </TabLink>
        <TabLink
          to={"/dashboard/project/$projectId/documentation"}
          params={{ projectId }}
        >
          {t("documentation")}
        </TabLink>
        <TabLink
          to={"/dashboard/project/$projectId/checklist"}
          params={{ projectId }}
        >
          {t("checklists")}
        </TabLink>
        <TabLink
          to={"/dashboard/project/$projectId/ehs"}
          params={{ projectId }}
        >
          {t("ehs")}
        </TabLink>
        <TabLink
          to={"/dashboard/project/$projectId/deviation"}
          params={{ projectId }}
        >
          {t("deviations")}
        </TabLink>
        <TabLink
          to={"/dashboard/project/$projectId/sub-project"}
          params={{ projectId }}
        >
          {t("subProjects")}
        </TabLink>
        <TabLink
          to={"/dashboard/project/$projectId/product"}
          params={{ projectId }}
        >
          {t("products")}
        </TabLink>
        <TabLink
          to={"/dashboard/project/$projectId/economy"}
          params={{ projectId }}
        >
          {t("projectEconomy")}
        </TabLink>
      </TabNav>
      <Outlet />
    </>
  );
}
