import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "@radix-ui/themes";
import ButtonNew from "../../components/ds/ButtonNew";

type ErrorPageProps = {
  error: unknown;
};

export default function ErrorPage({ error }: ErrorPageProps) {
  const { t } = useTranslation();
  return (
    <div className={"fixed top-0 bottom-0 w-full"}>
      <div
        className={"flex flex-col justify-center items-center h-full gap-4 "}
      >
        <Text as={"p"} size={"7"}>
          {t("anErrorOccurred")}
        </Text>
        <ButtonNew icon={"restart_alt"} onClick={() => location.reload()}>
          {t("reloadPage")}
        </ButtonNew>
        <Text as={"p"} className={"max-w-[240px]"}>
          {error?.toString()}
        </Text>
      </div>
    </div>
  );
}
