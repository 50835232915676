export function groupBy<T, U extends string>(arr: T[], fn: (item: T) => U) {
  return arr.reduce<Record<string, T[]>>((prev, curr) => {
    const groupKey = fn(curr);
    const group = prev[groupKey] || [];
    group.push(curr);
    return { ...prev, [groupKey]: group };
  }, {});
}

export function searchIncludes(query: string, value: string) {
  return value.toLowerCase().includes(query.toLowerCase());
}

export function formatNickname(name: string) {
  const [firstName, lastName] = name.split(" ");

  if (firstName && !lastName) return firstName[0];
  if (firstName && lastName) return firstName[0] + lastName[0];
  return "-";
}
