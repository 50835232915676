import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { activityTabs } from "../../../../router/Routes";

const activityTabSchema = z.object({
  tab: activityTabs.catch("activities")
});

export const Route = createFileRoute("/_protected/dashboard/activities/")({
  validateSearch: activityTabSchema
});
