import { createFileRoute } from "@tanstack/react-router";
import { useGetOfferById } from "../../../../../../../service/customerModule/OfferApi";

export const Route = createFileRoute(
  "/_protected/dashboard/sales/$salesOpportunityId/offer-file/$offerId/update"
)({
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: async ({
    context: { queryClient, companyId },
    params: { offerId }
  }) => {
    return await queryClient.fetchQuery({
      ...useGetOfferById.getOptions({ companyId, offerId })
    });
  }
});
