import { createFileRoute } from "@tanstack/react-router";
import { useGetOrderById } from "../../../../../service/api/OrderApi";
import { useGetCustomerByResourceId } from "../../../../../service/customerModule/CustomerApiV3";

export const Route = createFileRoute(
  "/_protected/dashboard/orders/$orderId/update"
)({
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: async ({
    context: { queryClient, companyId },
    params: { orderId }
  }) => {
    const order = await queryClient.fetchQuery(
      useGetOrderById.getOptions({ companyId, orderId })
    );

    const customer = await queryClient.fetchQuery(
      useGetCustomerByResourceId.getOptions({
        companyId,
        resourceId: order.id
      })
    );

    return {
      order,
      customer
    };
  }
});
