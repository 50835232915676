import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { customerDetailsTabs } from "../../../../../router/Routes";
import { useGetCustomerById } from "../../../../../service/customerModule/CustomerApiV3";

const customerDetailsSchema = z.object({
  tab: customerDetailsTabs.catch("overview").optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/customers/$customerId/"
)({
  validateSearch: customerDetailsSchema,
  loader: ({ context: { queryClient, companyId }, params: { customerId } }) => {
    return queryClient.fetchQuery({
      ...useGetCustomerById.getOptions({ customerId, companyId })
    });
  }
});
