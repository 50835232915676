import {
  TaskPostRequestV2,
  TaskPutRequest,
  TaskStatusRequestV2,
  TaskV2Api
} from "../../.generated/api";
import environment from "../../environment";
import { apiInstance } from "./ApiHttpBase";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { SortDirection } from "../../hooks/useSort";
import { createQuery } from "react-query-kit";

const taskApi = new TaskV2Api(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);
export const taskQueries = createQueryKeys("task", {
  getByProjectIdPaginated: (
    projectId: string,
    sortField:
      | "Created"
      | "Title"
      | "ProjectTitle"
      | "FromDateTime"
      | "ToDateTime"
      | "Status"
      | "PhaseTitle",
    sortDirection?: SortDirection,
    page?: number,
    query?: string,
    projectPhaseId?: string
  ) => ({
    queryKey: [
      projectId,
      sortField,
      sortDirection,
      page,
      query,
      projectPhaseId
    ],
    queryFn: async () => {
      const response = await taskApi.getPaginatedTasksByProjectId(
        projectId,
        sortField,
        projectPhaseId,
        page,
        sortDirection,
        query
      );
      return response.data;
    }
  }),
  getByCompanyId: (
    companyId: string,
    sortField:
      | "Created"
      | "Title"
      | "ProjectTitle"
      | "FromDateTime"
      | "ToDateTime"
      | "Status"
      | "PhaseTitle",
    sortDirection?: SortDirection,
    page?: number,
    query?: string,
    projectId?: string
  ) => ({
    queryKey: [companyId, sortDirection, sortField, page, query, projectId],
    queryFn: async () => {
      const response = await taskApi.getByCompanyId(
        companyId,
        sortField,
        page,
        sortDirection,
        query,
        projectId
      );
      return response.data;
    }
  }),
  getByProjectId: (projectId: string) => ({
    queryKey: [projectId],
    queryFn: async () => getTasksByProjectId(projectId)
  })
});

export const useGetTaskById = createQuery({
  queryKey: ["getTaskById"],
  fetcher: (variables: { taskId: string }) => getTaskById(variables.taskId)
});

export async function getTaskById(taskId: string) {
  const response = await taskApi.getTaskDetailsV2(taskId);
  return response.data;
}

export async function getTasksByProjectId(projectId: string) {
  const response = await taskApi.getTasksByProjectId(projectId);
  return response.data;
}

export async function createTask(request: TaskPostRequestV2) {
  const response = await taskApi.createTask(request);
  return response.data;
}

export async function updateTask(taskId: string, request: TaskPutRequest) {
  const response = await taskApi.updateTask(taskId, request);
  return response.data;
}

export async function updateTaskStatus(
  taskId: string,
  request: TaskStatusRequestV2
) {
  const response = await taskApi.updateTaskProgressStatusV2(taskId, request);
  return response.data;
}

export async function updateTaskPhase(taskId: string, phaseId: string) {
  const response = await taskApi.updateTaskPhaseV2(taskId, {
    phaseId: phaseId
  });
  return response.data;
}

export async function deleteTask(taskId: string) {
  await taskApi.deleteTaskV2(taskId);
}
