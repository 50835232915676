import { InputWrapperProps } from "./InputWrapperNew";

const inputWrapperPropsKeys: Required<InputWrapperProps> = {
  id: "",
  label: "",
  errorMessage: "",
  description: "",
  noBottomPadding: false,
  fullWidth: false,
  required: false,
  wrapperClassName: ""
};

export function pickInputWrapperProps<T extends InputWrapperProps>(
  props: T
): InputWrapperProps {
  const picked = {} as InputWrapperProps;

  Object.keys(props).forEach((key) => {
    if (key in inputWrapperPropsKeys) {
      //TODO: Is this neccessary?
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      picked[key] = props[key];
      delete props[key as keyof T];
    }
  });

  return picked;
}
