/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface LocationPostRequest
 */
export interface LocationPostRequest {
    /**
     * 
     * @type {string}
     * @memberof LocationPostRequest
     */
    'streetAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationPostRequest
     */
    'postalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationPostRequest
     */
    'postalArea'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationPostRequest
     */
    'houseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationPostRequest
     */
    'floor'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationPostRequest
     */
    'apartmentNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationPostRequest
     */
    'room'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationPostRequest
     */
    'latitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationPostRequest
     */
    'longitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationPostRequest
     */
    'radius'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationPostRequest
     */
    'objectDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationPostRequest
     */
    'parentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationPostRequest
     */
    'ownerResourceId': string;
    /**
     * 
     * @type {string}
     * @memberof LocationPostRequest
     */
    'ownerResourceType': LocationPostRequestOwnerResourceTypeEnum;
}

export const LocationPostRequestOwnerResourceTypeEnum = {
    Task: 'task',
    Project: 'project',
    Company: 'company',
    Deviation: 'deviation',
    Checklist: 'checklist',
    ChecklistItem: 'checklistItem',
    Documentation: 'documentation',
    DocumentationConnection: 'documentationConnection',
    Location: 'location',
    Ehs: 'ehs',
    InternalRoutine: 'internalRoutine'
} as const;

export type LocationPostRequestOwnerResourceTypeEnum = typeof LocationPostRequestOwnerResourceTypeEnum[keyof typeof LocationPostRequestOwnerResourceTypeEnum];


