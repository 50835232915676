import { createFileRoute } from "@tanstack/react-router";
import { useGetUsersByCompanyId } from "../../../../../service/api/UserV2Api";

export const Route = createFileRoute(
  "/_protected/dashboard/resources/$employeeId/update"
)({
  preloadGcTime: 0,
  preloadStaleTime: 0,
  loader: ({ context: { queryClient, companyId } }) => {
    return queryClient.fetchQuery({
      ...useGetUsersByCompanyId.getOptions({ companyId })
    });
  }
});
