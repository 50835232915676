import {
  InternalRoutineControllerApi,
  InternalRoutinePostRequest,
  InternalRoutinePutRequest
} from "../../.generated/api";
import { apiInstance } from "./ApiHttpBase";
import environment from "../../environment";
import { createQuery } from "react-query-kit";

const internalRoutineApi = new InternalRoutineControllerApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export const useGetInternalRoutineById = createQuery({
  queryKey: ["getInternalRoutineById"],
  fetcher: async (variables: { companyId: string; routineId: string }) => {
    const response = await internalRoutineApi.getInternalRoutineById(
      variables.companyId,
      variables.routineId
    );
    return response.data;
  }
});

export const useGetInternalRoutinesByCompanyId = createQuery({
  queryKey: ["getInternalRoutinesByCompanyId"],
  fetcher: async (variables: { companyId: string }) => {
    const response = await internalRoutineApi.getAllCompanyInternalRoutines(
      variables.companyId
    );
    return Array.from(response.data);
  }
});

export async function deleteInternalRoutine(
  internalRoutineId: string,
  companyId: string
): Promise<void> {
  await internalRoutineApi.deleteInternalRoutine(companyId, internalRoutineId);
}

export async function createInternalRoutine(
  request: InternalRoutinePostRequest,
  companyId: string
) {
  const response = await internalRoutineApi.createInternalRoutine(
    companyId,
    request
  );
  return response.data;
}

export async function updateInternalRoutine(
  ehsItemId: string,
  request: InternalRoutinePutRequest,
  companyId: string
): Promise<void> {
  await internalRoutineApi.updateInternalRoutine(companyId, ehsItemId, request);
}
