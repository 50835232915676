import { createFileRoute } from "@tanstack/react-router";
import { useGetProductById } from "../../../../../service/ProductsModule/ProductsApi";

export const Route = createFileRoute(
  "/_protected/dashboard/products/$productId/update"
)({
  preloadStaleTime: 0,
  loader: ({ context: { queryClient, companyId }, params: { productId } }) => {
    return queryClient.fetchQuery({
      ...useGetProductById.getOptions({ companyId, productId })
    });
  }
});
