import React, { ReactNode } from "react";
import { Avatar, Text } from "@radix-ui/themes";
import IconButton from "../ds/buttons/IconButton";
import { useRouter } from "@tanstack/react-router";
import { PageTitleSize, pageTitleSizes } from "../ds/sizes";
import classNames from "classnames";
import GlobalSearchWidget from "../GlobalSearchWidget";
import useTheme from "../../hooks/useTheme";
import { useAppStore } from "../../state/app_store";
import { formatNickname } from "../../utils";

const SHOW_THEME_SWITCH =
  localStorage.getItem("feature--show-theme-switch") ?? false;

type PageTitleProps = {
  title: string;
  withBackButton?: boolean;
  onBackClick?: VoidFunction;
  size?: PageTitleSize;
  withNavigationGap?: boolean;
  trailing?: ReactNode;
};

export default function NewPageTitle({
  title,
  withBackButton,
  onBackClick,
  size = "regular",
  withNavigationGap = true,
  trailing
}: PageTitleProps) {
  const router = useRouter();

  const { theme, setTheme } = useTheme();
  const user = useAppStore((s) => s.user);

  return (
    <>
      <div style={{ height: pageTitleSizes[size] }} />
      <div
        className={classNames(
          "flex fixed top-0 right-0 left-0 bg-radix-panel-solid p-6 border-b border-b-radix-gray-6 z-[1]",
          {
            "left-[72px]": withNavigationGap
          }
        )}
      >
        <div className={"h-10 flex items-center"}>
          {(withBackButton || onBackClick) && (
            <IconButton
              onClick={() =>
                onBackClick ? onBackClick() : router.history.back()
              }
              icon={"keyboard_arrow_left"}
              className={"mr-4"}
              size={"3"}
              color={"gray"}
            />
          )}
          <Text size={"6"} weight={"bold"} className={"grow"}>
            {title}
          </Text>
          {trailing && <div className={"ml-4"}>{trailing}</div>}
        </div>

        <div className="flex-1"></div>

        <GlobalSearchWidget />

        {SHOW_THEME_SWITCH && (
          <>
            <IconButton icon={"notifications"} size={"3"} className={"ml-6"} />

            <IconButton
              icon={theme === "dark" ? "light_mode" : "dark_mode"}
              size={"3"}
              className={"ml-3"}
              onClick={() => {
                setTheme(theme === "dark" ? "light" : "dark");
              }}
            />

            <Avatar
              fallback={formatNickname(user.name ?? "-")}
              radius={"full"}
              variant="solid"
              size={"3"}
              className={"ml-3"}
            />
          </>
        )}
      </div>
    </>
  );
}
