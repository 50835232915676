import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const createFacilitySchema = z.object({
  customerId: z.string().optional()
});

export const Route = createFileRoute("/_protected/dashboard/facility/new")({
  validateSearch: createFacilitySchema
});
