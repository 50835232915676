import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { orderReportTabs } from "../../../../../router/Routes";
import { useGetOrderById } from "../../../../../service/api/OrderApi";

const orderReportSchema = z.object({
  tab: orderReportTabs.optional().catch("hours")
});

export const Route = createFileRoute(
  "/_protected/dashboard/orders/$orderId/report"
)({
  validateSearch: orderReportSchema,
  loader: async ({
    context: { queryClient, companyId },
    params: { orderId }
  }) => {
    return queryClient.fetchQuery({
      ...useGetOrderById.getOptions({ orderId, companyId })
    });
  }
});
