import React from "react";
import classNames from "classnames";
import { Responsive } from "@radix-ui/themes/dist/esm/props";

type RadixIconProps = {
  icon: string;
  className?: string;
  size?: Responsive<"1" | "2" | "3" | "4">;
};

export default function RadixIcon({
  size = "2",
  icon,
  className
}: RadixIconProps) {
  return (
    <span
      className={classNames("material-symbols-outlined", className, {
        "text-[15px]": size === "1",
        "text-[18px]": size === "2",
        "text-[22px]": size === "3",
        "text-[24px]": size === "4"
      })}
    >
      {icon}
    </span>
  );
}
