import { createFileRoute } from "@tanstack/react-router";
import { useGetEhsTemplateById } from "../../../../../../service/api/EhsApi";
import { z } from "zod";

const ehsTemplateSchema = z.object({
  templateId: z.string().optional(),
  isFromProject: z.boolean().optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/ehs/template/$templateId/update"
)({
  validateSearch: ehsTemplateSchema,
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: async ({
    context: { queryClient, companyId },
    params: { templateId }
  }) => {
    return await queryClient.fetchQuery({
      ...useGetEhsTemplateById.getOptions({ companyId, templateId })
    });
  }
});
