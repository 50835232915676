import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { inspectionType } from "../../../../../../router/Routes";
import { useGetCustomerById } from "../../../../../../service/customerModule/CustomerApiV3";

const createInspectionSchema = z.object({
  inspectionType: inspectionType,
  salesOpportunityId: z.string().optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/sales/inspection/$customerId/new"
)({
  validateSearch: createInspectionSchema,
  loader: async ({
    context: { queryClient, companyId },
    params: { customerId }
  }) => {
    return await queryClient.ensureQueryData({
      ...useGetCustomerById.getOptions({ companyId, customerId })
    });
  }
});
