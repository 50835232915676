import { createFileRoute } from "@tanstack/react-router";
import { useGetInternalRoutineById } from "../../../../../service/api/InternalRoutineApi";
import { useGetRootFiles } from "../../../../../service/api/FileApi";

export const Route = createFileRoute(
  "/_protected/dashboard/internal-routines/$routineId/update"
)({
  loader: async ({
    context: { queryClient, companyId },
    params: { routineId }
  }) => {
    const routine = await queryClient.fetchQuery({
      ...useGetInternalRoutineById.getOptions({ routineId, companyId })
    });

    const files = await queryClient.fetchQuery({
      ...useGetRootFiles.getOptions({ resourceId: routineId })
    });

    return {
      routine,
      files
    };
  }
});
