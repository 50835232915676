import { createFileRoute } from "@tanstack/react-router";
import { useGetProductBundleById } from "../../../../../../service/ProductsModule/ProductBundleApi";

export const Route = createFileRoute(
  "/_protected/dashboard/products/bundles/$bundleId/update"
)({
  preloadGcTime: 0,
  preloadStaleTime: 0,
  loader: async ({
    context: { queryClient, companyId },
    params: { bundleId }
  }) => {
    return await queryClient.fetchQuery({
      ...useGetProductBundleById.getOptions({ companyId, bundleId })
    });
  }
});
