/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { PaginatedResponseRecurringOrderTemplate } from '../model';
// @ts-ignore
import { RecurringOrderTemplate } from '../model';
// @ts-ignore
import { RecurringOrderTemplatePostRequest } from '../model';
// @ts-ignore
import { RecurringOrderTemplatePutRequest } from '../model';
/**
 * RecurringOrderApi - axios parameter creator
 * @export
 */
export const RecurringOrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add user to order template
         * @param {string} companyId 
         * @param {string} recurringTemplateId 
         * @param {string} templateUserId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToTemplate: async (companyId: string, recurringTemplateId: string, templateUserId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('addUserToTemplate', 'companyId', companyId)
            // verify required parameter 'recurringTemplateId' is not null or undefined
            assertParamExists('addUserToTemplate', 'recurringTemplateId', recurringTemplateId)
            // verify required parameter 'templateUserId' is not null or undefined
            assertParamExists('addUserToTemplate', 'templateUserId', templateUserId)
            const localVarPath = `/v1/company/{companyId}/order/recurring/{recurringTemplateId}/user/{templateUserId}/post`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"recurringTemplateId"}}`, encodeURIComponent(String(recurringTemplateId)))
                .replace(`{${"templateUserId"}}`, encodeURIComponent(String(templateUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Archive template for recurring orders
         * @param {string} companyId 
         * @param {string} recurringTemplateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveRecurringOrderTemplate: async (companyId: string, recurringTemplateId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('archiveRecurringOrderTemplate', 'companyId', companyId)
            // verify required parameter 'recurringTemplateId' is not null or undefined
            assertParamExists('archiveRecurringOrderTemplate', 'recurringTemplateId', recurringTemplateId)
            const localVarPath = `/v1/company/{companyId}/order/recurring/template/{recurringTemplateId}/archive`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"recurringTemplateId"}}`, encodeURIComponent(String(recurringTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create template for recurring orders
         * @param {string} companyId 
         * @param {RecurringOrderTemplatePostRequest} recurringOrderTemplatePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecurringOrderTemplate: async (companyId: string, recurringOrderTemplatePostRequest: RecurringOrderTemplatePostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createRecurringOrderTemplate', 'companyId', companyId)
            // verify required parameter 'recurringOrderTemplatePostRequest' is not null or undefined
            assertParamExists('createRecurringOrderTemplate', 'recurringOrderTemplatePostRequest', recurringOrderTemplatePostRequest)
            const localVarPath = `/v1/company/{companyId}/order/template`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recurringOrderTemplatePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Endpoint for gcp cron job, to generate all new active recurring orders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRecurringOrders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/order/recurring/cronjob`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recurring order template
         * @param {string} companyId 
         * @param {string} recurringTemplateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecurringOrderTemplate: async (companyId: string, recurringTemplateId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getRecurringOrderTemplate', 'companyId', companyId)
            // verify required parameter 'recurringTemplateId' is not null or undefined
            assertParamExists('getRecurringOrderTemplate', 'recurringTemplateId', recurringTemplateId)
            const localVarPath = `/v1/company/{companyId}/order/template/{recurringTemplateId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"recurringTemplateId"}}`, encodeURIComponent(String(recurringTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recurring order templates for company
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {string} [search] 
         * @param {string} [customerId] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecurringOrderTemplatesForCompany: async (companyId: string, page?: number, search?: string, customerId?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getRecurringOrderTemplatesForCompany', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/order/template`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove user from order template
         * @param {string} companyId 
         * @param {string} recurringTemplateId 
         * @param {string} templateUserId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromTemplate: async (companyId: string, recurringTemplateId: string, templateUserId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('removeUserFromTemplate', 'companyId', companyId)
            // verify required parameter 'recurringTemplateId' is not null or undefined
            assertParamExists('removeUserFromTemplate', 'recurringTemplateId', recurringTemplateId)
            // verify required parameter 'templateUserId' is not null or undefined
            assertParamExists('removeUserFromTemplate', 'templateUserId', templateUserId)
            const localVarPath = `/v1/company/{companyId}/order/recurring/{recurringTemplateId}/user/{templateUserId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"recurringTemplateId"}}`, encodeURIComponent(String(recurringTemplateId)))
                .replace(`{${"templateUserId"}}`, encodeURIComponent(String(templateUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restore template for recurring orders
         * @param {string} companyId 
         * @param {string} recurringTemplateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreRecurringOrderTemplate: async (companyId: string, recurringTemplateId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('restoreRecurringOrderTemplate', 'companyId', companyId)
            // verify required parameter 'recurringTemplateId' is not null or undefined
            assertParamExists('restoreRecurringOrderTemplate', 'recurringTemplateId', recurringTemplateId)
            const localVarPath = `/v1/company/{companyId}/order/recurring/template/{recurringTemplateId}/restore`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"recurringTemplateId"}}`, encodeURIComponent(String(recurringTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update template for recurring orders
         * @param {RecurringOrderTemplatePutRequest} recurringOrderTemplatePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecurringOrderTemplate: async (recurringOrderTemplatePutRequest: RecurringOrderTemplatePutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recurringOrderTemplatePutRequest' is not null or undefined
            assertParamExists('updateRecurringOrderTemplate', 'recurringOrderTemplatePutRequest', recurringOrderTemplatePutRequest)
            const localVarPath = `/v1/order/recurring/template/put`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recurringOrderTemplatePutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecurringOrderApi - functional programming interface
 * @export
 */
export const RecurringOrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecurringOrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add user to order template
         * @param {string} companyId 
         * @param {string} recurringTemplateId 
         * @param {string} templateUserId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserToTemplate(companyId: string, recurringTemplateId: string, templateUserId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserToTemplate(companyId, recurringTemplateId, templateUserId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Archive template for recurring orders
         * @param {string} companyId 
         * @param {string} recurringTemplateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveRecurringOrderTemplate(companyId: string, recurringTemplateId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecurringOrderTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveRecurringOrderTemplate(companyId, recurringTemplateId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create template for recurring orders
         * @param {string} companyId 
         * @param {RecurringOrderTemplatePostRequest} recurringOrderTemplatePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRecurringOrderTemplate(companyId: string, recurringOrderTemplatePostRequest: RecurringOrderTemplatePostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecurringOrderTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRecurringOrderTemplate(companyId, recurringOrderTemplatePostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Endpoint for gcp cron job, to generate all new active recurring orders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateRecurringOrders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateRecurringOrders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get recurring order template
         * @param {string} companyId 
         * @param {string} recurringTemplateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecurringOrderTemplate(companyId: string, recurringTemplateId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecurringOrderTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecurringOrderTemplate(companyId, recurringTemplateId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get recurring order templates for company
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {string} [search] 
         * @param {string} [customerId] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecurringOrderTemplatesForCompany(companyId: string, page?: number, search?: string, customerId?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseRecurringOrderTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecurringOrderTemplatesForCompany(companyId, page, search, customerId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove user from order template
         * @param {string} companyId 
         * @param {string} recurringTemplateId 
         * @param {string} templateUserId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUserFromTemplate(companyId: string, recurringTemplateId: string, templateUserId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUserFromTemplate(companyId, recurringTemplateId, templateUserId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Restore template for recurring orders
         * @param {string} companyId 
         * @param {string} recurringTemplateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreRecurringOrderTemplate(companyId: string, recurringTemplateId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecurringOrderTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreRecurringOrderTemplate(companyId, recurringTemplateId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update template for recurring orders
         * @param {RecurringOrderTemplatePutRequest} recurringOrderTemplatePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRecurringOrderTemplate(recurringOrderTemplatePutRequest: RecurringOrderTemplatePutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecurringOrderTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRecurringOrderTemplate(recurringOrderTemplatePutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecurringOrderApi - factory interface
 * @export
 */
export const RecurringOrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecurringOrderApiFp(configuration)
    return {
        /**
         * 
         * @summary Add user to order template
         * @param {string} companyId 
         * @param {string} recurringTemplateId 
         * @param {string} templateUserId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToTemplate(companyId: string, recurringTemplateId: string, templateUserId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.addUserToTemplate(companyId, recurringTemplateId, templateUserId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Archive template for recurring orders
         * @param {string} companyId 
         * @param {string} recurringTemplateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveRecurringOrderTemplate(companyId: string, recurringTemplateId: string, authorization?: string, options?: any): AxiosPromise<RecurringOrderTemplate> {
            return localVarFp.archiveRecurringOrderTemplate(companyId, recurringTemplateId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create template for recurring orders
         * @param {string} companyId 
         * @param {RecurringOrderTemplatePostRequest} recurringOrderTemplatePostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecurringOrderTemplate(companyId: string, recurringOrderTemplatePostRequest: RecurringOrderTemplatePostRequest, authorization?: string, options?: any): AxiosPromise<RecurringOrderTemplate> {
            return localVarFp.createRecurringOrderTemplate(companyId, recurringOrderTemplatePostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Endpoint for gcp cron job, to generate all new active recurring orders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRecurringOrders(options?: any): AxiosPromise<object> {
            return localVarFp.generateRecurringOrders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get recurring order template
         * @param {string} companyId 
         * @param {string} recurringTemplateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecurringOrderTemplate(companyId: string, recurringTemplateId: string, authorization?: string, options?: any): AxiosPromise<RecurringOrderTemplate> {
            return localVarFp.getRecurringOrderTemplate(companyId, recurringTemplateId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get recurring order templates for company
         * @param {string} companyId 
         * @param {number} [page] 
         * @param {string} [search] 
         * @param {string} [customerId] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecurringOrderTemplatesForCompany(companyId: string, page?: number, search?: string, customerId?: string, authorization?: string, options?: any): AxiosPromise<PaginatedResponseRecurringOrderTemplate> {
            return localVarFp.getRecurringOrderTemplatesForCompany(companyId, page, search, customerId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove user from order template
         * @param {string} companyId 
         * @param {string} recurringTemplateId 
         * @param {string} templateUserId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromTemplate(companyId: string, recurringTemplateId: string, templateUserId: string, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.removeUserFromTemplate(companyId, recurringTemplateId, templateUserId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restore template for recurring orders
         * @param {string} companyId 
         * @param {string} recurringTemplateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreRecurringOrderTemplate(companyId: string, recurringTemplateId: string, authorization?: string, options?: any): AxiosPromise<RecurringOrderTemplate> {
            return localVarFp.restoreRecurringOrderTemplate(companyId, recurringTemplateId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update template for recurring orders
         * @param {RecurringOrderTemplatePutRequest} recurringOrderTemplatePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecurringOrderTemplate(recurringOrderTemplatePutRequest: RecurringOrderTemplatePutRequest, authorization?: string, options?: any): AxiosPromise<RecurringOrderTemplate> {
            return localVarFp.updateRecurringOrderTemplate(recurringOrderTemplatePutRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecurringOrderApi - object-oriented interface
 * @export
 * @class RecurringOrderApi
 * @extends {BaseAPI}
 */
export class RecurringOrderApi extends BaseAPI {
    /**
     * 
     * @summary Add user to order template
     * @param {string} companyId 
     * @param {string} recurringTemplateId 
     * @param {string} templateUserId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringOrderApi
     */
    public addUserToTemplate(companyId: string, recurringTemplateId: string, templateUserId: string, authorization?: string, options?: AxiosRequestConfig) {
        return RecurringOrderApiFp(this.configuration).addUserToTemplate(companyId, recurringTemplateId, templateUserId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Archive template for recurring orders
     * @param {string} companyId 
     * @param {string} recurringTemplateId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringOrderApi
     */
    public archiveRecurringOrderTemplate(companyId: string, recurringTemplateId: string, authorization?: string, options?: AxiosRequestConfig) {
        return RecurringOrderApiFp(this.configuration).archiveRecurringOrderTemplate(companyId, recurringTemplateId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create template for recurring orders
     * @param {string} companyId 
     * @param {RecurringOrderTemplatePostRequest} recurringOrderTemplatePostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringOrderApi
     */
    public createRecurringOrderTemplate(companyId: string, recurringOrderTemplatePostRequest: RecurringOrderTemplatePostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return RecurringOrderApiFp(this.configuration).createRecurringOrderTemplate(companyId, recurringOrderTemplatePostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Endpoint for gcp cron job, to generate all new active recurring orders
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringOrderApi
     */
    public generateRecurringOrders(options?: AxiosRequestConfig) {
        return RecurringOrderApiFp(this.configuration).generateRecurringOrders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get recurring order template
     * @param {string} companyId 
     * @param {string} recurringTemplateId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringOrderApi
     */
    public getRecurringOrderTemplate(companyId: string, recurringTemplateId: string, authorization?: string, options?: AxiosRequestConfig) {
        return RecurringOrderApiFp(this.configuration).getRecurringOrderTemplate(companyId, recurringTemplateId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get recurring order templates for company
     * @param {string} companyId 
     * @param {number} [page] 
     * @param {string} [search] 
     * @param {string} [customerId] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringOrderApi
     */
    public getRecurringOrderTemplatesForCompany(companyId: string, page?: number, search?: string, customerId?: string, authorization?: string, options?: AxiosRequestConfig) {
        return RecurringOrderApiFp(this.configuration).getRecurringOrderTemplatesForCompany(companyId, page, search, customerId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove user from order template
     * @param {string} companyId 
     * @param {string} recurringTemplateId 
     * @param {string} templateUserId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringOrderApi
     */
    public removeUserFromTemplate(companyId: string, recurringTemplateId: string, templateUserId: string, authorization?: string, options?: AxiosRequestConfig) {
        return RecurringOrderApiFp(this.configuration).removeUserFromTemplate(companyId, recurringTemplateId, templateUserId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restore template for recurring orders
     * @param {string} companyId 
     * @param {string} recurringTemplateId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringOrderApi
     */
    public restoreRecurringOrderTemplate(companyId: string, recurringTemplateId: string, authorization?: string, options?: AxiosRequestConfig) {
        return RecurringOrderApiFp(this.configuration).restoreRecurringOrderTemplate(companyId, recurringTemplateId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update template for recurring orders
     * @param {RecurringOrderTemplatePutRequest} recurringOrderTemplatePutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringOrderApi
     */
    public updateRecurringOrderTemplate(recurringOrderTemplatePutRequest: RecurringOrderTemplatePutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return RecurringOrderApiFp(this.configuration).updateRecurringOrderTemplate(recurringOrderTemplatePutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
