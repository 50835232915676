import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const checklistSchema = z.object({
  resourceId: z.string(),
  parentId: z.string().optional(),
  isFromProject: z.boolean(),
  resourceType: z.enum([
    "Project",
    "Task",
    "Order",
    "Equipment",
    "EhsInspection"
  ])
});

export const Route = createFileRoute("/_protected/dashboard/checklist/new")({
  validateSearch: checklistSchema
});
