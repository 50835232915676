import { persist } from "zustand/middleware";
import { CompanyCurrencyEnum, User } from "../.generated/api";
import { create } from "zustand";

export type ViewEnum =
  | "calendar"
  | "list"
  | "archive"
  | "facility"
  | "serviceContract";

export type CompanyInfo = {
  name: string;
  id: string;
  orgNumber: string;
  contactPerson: string;
  email?: string;
  phone?: string;
  freeTrialDaysRemaining: number;
  subscription?: "FREE_TRIAL" | "PAYED" | "EXPIRED";
  features: {
    sales: boolean;
    projects: boolean;
    orders: boolean;
    products: boolean;
    tasks: boolean;
    resourcePlanner: boolean;
    timekeeping: boolean;
  };
};

export type Theme = "dark" | "light";

type AppStore = {
  selectedCompany?: CompanyInfo;
  setSelectedCompany: (company: CompanyInfo) => void;
  theme: Theme;
  setTheme: (theme: Theme) => void;
  //companyFeatures?: CompanyFeatures;
  //setCompanyFeatures: (features: CompanyFeatures) => void;
  selectedProjectId?: string;
  setSelectedProjectId: (projectId: string) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  user: User;
  setUser: (user: User) => void;
  selectedProjectView: ViewEnum;
  setSelectedProjectView: (view: ViewEnum) => void;
  currency: CompanyCurrencyEnum;
  setCurrency: (currency: CompanyCurrencyEnum) => void;
  projectFilter: "all" | "mine";
  setProjectFilter: (filter: "all" | "mine") => void;
};

export const useAppStore = create<AppStore>()(
  persist(
    (set) => ({
      setSelectedCompany: (company) => {
        set(() => ({ selectedCompany: company }));
      },
      setCurrency: (currency) => {
        set(() => ({ currency }));
      },
      setSelectedProjectId: (selectedProjectId) =>
        set(() => ({ selectedProjectId })),
      setIsLoading: (isLoading) => set(() => ({ isLoading })),
      setUser: (user) => set(() => ({ user: user })),
      setSelectedProjectView: (view) =>
        set(() => ({ selectedProjectView: view })),
      setTheme: (theme) => set(() => ({ theme: theme })),
      setProjectFilter: (filter) => set(() => ({ projectFilter: filter })),
      projectFilter: "all",
      theme: "light",
      selectedProjectView: "list",
      isLoading: false,
      menuState: "open",
      currency: "NOK",
      user: {
        id: "",
        email: "",
        loginOption: "google",
        status: "active"
      }
    }),
    {
      name: "company",
      partialize: (s) => ({
        selectedCompany: s.selectedCompany,
        selectedProjectId: s.selectedProjectId,
        selectedProjectView: s.selectedProjectView,
        projectFilter: s.projectFilter,
        currency: s.currency,
        user: s.user
      })
    }
  )
);
