import {
  ActivitiesApi,
  ActivityFavoritePutRequest,
  ActivityPostRequest,
  ActivityPutRequest
} from "../../.generated/timekeepingModule";
import environment from "../../environment";
import { instance } from "./TimekeepingModuleHttpBase";
import { createQuery } from "react-query-kit";

const activityApi = new ActivitiesApi(
  undefined,
  environment.timekeepingBaseUrl,
  instance
);

export const useGetActivitiesByResourceId = createQuery({
  queryKey: ["getActivitiesByResourceId"],
  fetcher: async (variables: {
    companyId: string;
    resourceId: string;
    type: "Company" | "Project" | "Task" | "Order";
    valueType?: "Kilometers" | "Hours";
  }) => {
    const response = await activityApi.getActivitiesByResourceId(
      variables.companyId,
      variables.resourceId,
      variables.type,
      variables.valueType
    );
    return response.data;
  }
});

export const useGetUserActivities = createQuery({
  queryKey: ["getActivitiesByUserId"],
  fetcher: async (variables: { companyId: string; resourceId?: string }) => {
    const response = await activityApi.getUserActivities(
      variables.companyId,
      variables.resourceId
    );
    return response.data;
  }
});

export const useGetActivitiesOnUser = createQuery({
  queryKey: ["getActivitiesForUser"],
  fetcher: async (variables: {
    companyId: string;
    userId: string;
    resourceId?: string;
  }) => {
    const response = await activityApi.getActivitiesOnUser(
      variables.companyId,
      variables.userId,
      variables.resourceId
    );
    return response.data;
  }
});

export const useGetActivitiesByCompanyId = createQuery({
  queryKey: ["getActivitiesByCompanyId"],
  fetcher: async (variables: {
    companyId: string;
    type?: "Project" | "Company" | "All" | "Order";
  }) => {
    const response = await activityApi.getActivities(
      variables.companyId,
      variables.type
    );
    return response.data;
  }
});

export async function deleteActivity(companyId: string, activityId: string) {
  await activityApi.deleteActivity(companyId, activityId);
}

export async function createActivity(
  companyId: string,
  request: ActivityPostRequest
) {
  return activityApi.createActivity(companyId, request);
}

export async function updateActivity(
  companyId: string,
  activityId: string,
  request: ActivityPutRequest
) {
  const response = await activityApi.updateActivity(
    companyId,
    activityId,
    request
  );
  return response.data;
}

export async function updateFavoriteStatus(
  request: ActivityFavoritePutRequest
) {
  const response = await activityApi.updateFavoriteStatus(request);
  return response.data;
}
