import { createFileRoute } from "@tanstack/react-router";
import { useGetProjectPhasesByCompanyId } from "../../../../../service/api/PhasesApi";

export const Route = createFileRoute(
  "/_protected/dashboard/settings/project-phases/edit"
)({
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: ({ context: { queryClient, companyId } }) => {
    return queryClient.fetchQuery({
      ...useGetProjectPhasesByCompanyId.getOptions({ companyId })
    });
  }
});
