import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const createSalesRouteSchema = z.object({
  customerId: z.string()
});

export const Route = createFileRoute("/_protected/dashboard/sales/new/")({
  validateSearch: createSalesRouteSchema
});
