import { createFileRoute } from "@tanstack/react-router";
import { upsertDeviationSchema } from "../../../../../router/Routes";
import { useGetDeviationById } from "../../../../../service/api/DeviationApi";

export const Route = createFileRoute(
  "/_protected/dashboard/deviation/$deviationId/update"
)({
  validateSearch: upsertDeviationSchema,
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: async ({ context: { queryClient }, params: { deviationId } }) => {
    return queryClient.fetchQuery({
      ...useGetDeviationById.getOptions({ deviationId })
    });
  }
});
